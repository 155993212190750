/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFBenefitAmountResponse
 *
 * @class NFBenefitAmountResponse
 */
class NFBenefitAmountResponse {
  /**
   * Creates an instance of NFBenefitAmountResponse.
   * @param {*} json
   * @memberof NFBenefitAmountResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.maxAmountStr = json.maxAmountStr;

      // string
      this.minAmountStr = json.minAmountStr;
    }
  }
}

/**
 * Models the NewUALifeEventContext
 *
 * @class NewUALifeEventContext
 */
class NewUALifeEventContext {
  /**
   * Creates an instance of NewUALifeEventContext.
   * @param {*} json
   * @memberof NewUALifeEventContext
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventContextId = json.lifeEventContextId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.common = json.common;

      // string
      this.imageName = json.imageName;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.additionalInfo = json.additionalInfo;

      // string
      this.submissionText = json.submissionText;

      // string
      this.launchDescription = json.launchDescription;

      // boolean
      this.isCitizenConsentEnabled = json.isCitizenConsentEnabled;

      // boolean
      this.isCitizenConsentOneChoiceEnabled = json.isCitizenConsentOneChoiceEnabled;

      // string
      this.imageAltText = json.imageAltText;
    }
  }
}

/**
 * Models the NewUALifeEventFormIdResponse
 *
 * @class NewUALifeEventFormIdResponse
 */
class NewUALifeEventFormIdResponse {
  /**
   * Creates an instance of NewUALifeEventFormIdResponse.
   * @param {*} json
   * @memberof NewUALifeEventFormIdResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.formId = json.formId;
    }
  }
}

export default {
  NFBenefitAmountResponse,

  NewUALifeEventContext,

  NewUALifeEventFormIdResponse,
};
