/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_NF_COC_START_COC_SCRIPT,
  FETCH_NF_COC_APPLY_FOR_SNAP_RECERT,
  POST_NF_COC_FINISH_COC_RECERT,
  POST_NF_COC_SIGNANDSUBMIT_COC_RECERT,
  GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID,
  POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
  FETCH_NF_COC_LIST_SUBMITTED_RENEWALS,
} from './actionTypes';

import {
  postNfCocStartCocScriptUtil,
  fetchNfCocApplyForSnapRecertUtil,
  postNfCocFinishCocRecertUtil,
  postNfCocSignandsubmitCocRecertUtil,
  getNfCocCocRecertConfirmationDetailsByCocInstanceIDUtil,
  postNfMotivationSetupStartMotivationUtil,
  fetchNfCocListSubmittedRenewalsUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFSNAPRecert slice of the store.
 *
 * @namespace NFSNAPRecertActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/start_coc_script
   *
   * @param {NewNFCOCConfig} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static postNfCocStartCocScript = (dispatch, newNFCOCConfig, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfCocStartCocScriptUtil.bind(null, newNFCOCConfig),
      POST_NF_COC_START_COC_SCRIPT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfCocStartCocScript.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfCocStartCocScript = (dispatch, data) => {
    const json = {
      type: POST_NF_COC_START_COC_SCRIPT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfCocStartCocScript.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfCocStartCocScriptError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_COC_START_COC_SCRIPT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/apply_for_snap_recert
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static fetchNfCocApplyForSnapRecert = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCocApplyForSnapRecertUtil,
      FETCH_NF_COC_APPLY_FOR_SNAP_RECERT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCocApplyForSnapRecert.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetFetchNfCocApplyForSnapRecert = (dispatch, data) => {
    const json = {
      type: FETCH_NF_COC_APPLY_FOR_SNAP_RECERT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCocApplyForSnapRecert.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetFetchNfCocApplyForSnapRecertError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_COC_APPLY_FOR_SNAP_RECERT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/finish_coc_recert
   *
   * @param {NewNFCOCIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static postNfCocFinishCocRecert = (dispatch, newNFCOCIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfCocFinishCocRecertUtil.bind(null, newNFCOCIEGKey),
      POST_NF_COC_FINISH_COC_RECERT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfCocFinishCocRecert.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfCocFinishCocRecert = (dispatch, data) => {
    const json = {
      type: POST_NF_COC_FINISH_COC_RECERT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfCocFinishCocRecert.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfCocFinishCocRecertError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_COC_FINISH_COC_RECERT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/signandsubmit_coc_recert
   *
   * @param {NewNFCOCSignAndSubmit} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static postNfCocSignandsubmitCocRecert = (dispatch, newNFCOCSignAndSubmit, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfCocSignandsubmitCocRecertUtil.bind(null, newNFCOCSignAndSubmit),
      POST_NF_COC_SIGNANDSUBMIT_COC_RECERT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfCocSignandsubmitCocRecert.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfCocSignandsubmitCocRecert = (dispatch, data) => {
    const json = {
      type: POST_NF_COC_SIGNANDSUBMIT_COC_RECERT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfCocSignandsubmitCocRecert.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfCocSignandsubmitCocRecertError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_COC_SIGNANDSUBMIT_COC_RECERT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/coc_recert_confirmation_details/{cocInstanceID}
   *
   * @param {string} cocInstanceID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static getNfCocCocRecertConfirmationDetailsByCocInstanceID = (
    dispatch,
    cocInstanceID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfCocCocRecertConfirmationDetailsByCocInstanceIDUtil.bind(null, cocInstanceID),

      GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfCocCocRecertConfirmationDetailsByCocInstanceID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetGetNfCocCocRecertConfirmationDetailsByCocInstanceID = (dispatch, data) => {
    const json = {
      type: GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfCocCocRecertConfirmationDetailsByCocInstanceID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetGetNfCocCocRecertConfirmationDetailsByCocInstanceIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/setup_start_motivation
   *
   * @param {NewNFStartMotivationKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static postNfMotivationSetupStartMotivation = (dispatch, newNFStartMotivationKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationSetupStartMotivationUtil.bind(null, newNFStartMotivationKey),
      POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationSetupStartMotivation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfMotivationSetupStartMotivation = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_SETUP_START_MOTIVATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationSetupStartMotivation.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetPostNfMotivationSetupStartMotivationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_SETUP_START_MOTIVATION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/list_submitted_renewals
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFSNAPRecertActions
   */
  static fetchNfCocListSubmittedRenewals = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCocListSubmittedRenewalsUtil,
      FETCH_NF_COC_LIST_SUBMITTED_RENEWALS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCocListSubmittedRenewals.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetFetchNfCocListSubmittedRenewals = (dispatch, data) => {
    const json = {
      type: FETCH_NF_COC_LIST_SUBMITTED_RENEWALS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCocListSubmittedRenewals.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSNAPRecertActions
   */
  static resetFetchNfCocListSubmittedRenewalsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_COC_LIST_SUBMITTED_RENEWALS.failureAction);
  };
}
