/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { FETCH_NF_COC_APPLY_FOR_FNS_RECERT } from './actionTypes';

/**
 * Updates the details in the 'NFFNSRenewBenefits' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocApplyForFnsRecertReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_APPLY_FOR_FNS_RECERT);

/**
 * Updates the indicator of creation of details in the 'NFFNSRenewBenefits' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocApplyForFnsRecertReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_APPLY_FOR_FNS_RECERT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocApplyForFnsRecertErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_APPLY_FOR_FNS_RECERT);

const reducers = combineReducers({
  fetchNfCocApplyForFnsRecert: fetchNfCocApplyForFnsRecertReducer,

  isFetchingFetchNfCocApplyForFnsRecert: isFetchingFetchNfCocApplyForFnsRecertReducer,
  fetchNfCocApplyForFnsRecertError: fetchNfCocApplyForFnsRecertErrorReducer,
});

export default { reducers };
