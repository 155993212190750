/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { FETCH_NF_COC_SNAP_BENEFIT_AMOUNT, POST_LIFE_EVENTS_FORM } from './actionTypes';

/**
 * Updates the details in the 'NFFnsOrSnapRecertMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocSnapBenefitAmountReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_SNAP_BENEFIT_AMOUNT);

/**
 * Updates the indicator of creation of details in the 'NFFnsOrSnapRecertMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocSnapBenefitAmountReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_SNAP_BENEFIT_AMOUNT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocSnapBenefitAmountErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_SNAP_BENEFIT_AMOUNT);

/**
 * Updates the details in the 'NFFnsOrSnapRecertMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postLifeEventsFormReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_LIFE_EVENTS_FORM);

/**
 * Updates the indicator of creation of details in the 'NFFnsOrSnapRecertMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostLifeEventsFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_LIFE_EVENTS_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postLifeEventsFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_LIFE_EVENTS_FORM);

const reducers = combineReducers({
  fetchNfCocSnapBenefitAmount: fetchNfCocSnapBenefitAmountReducer,

  isFetchingFetchNfCocSnapBenefitAmount: isFetchingFetchNfCocSnapBenefitAmountReducer,
  fetchNfCocSnapBenefitAmountError: fetchNfCocSnapBenefitAmountErrorReducer,

  postLifeEventsForm: postLifeEventsFormReducer,

  isFetchingPostLifeEventsForm: isFetchingPostLifeEventsFormReducer,
  postLifeEventsFormError: postLifeEventsFormErrorReducer,
});

export default { reducers };
