/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_PRESUMPTIVE,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_MOTIVATION,
  FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE,
} from './actionTypes';

import {
  postMotivationSetupAndStartUtil,
  fetchNfMotivationPresumptiveUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishMotivationUtil,
  fetchNfHcrgetpresumptiveuserPresumptiveUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFPresumptiveMotivation slice of the store.
 *
 * @namespace NFPresumptiveMotivationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static postMotivationSetupAndStart = (dispatch, newUAMotivationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationSetupAndStartUtil.bind(null, newUAMotivationType),
      POST_MOTIVATION_SETUP_AND_START,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetPostMotivationSetupAndStart = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_SETUP_AND_START.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetPostMotivationSetupAndStartError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_SETUP_AND_START.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/presumptive
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static fetchNfMotivationPresumptive = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfMotivationPresumptiveUtil,
      FETCH_NF_MOTIVATION_PRESUMPTIVE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfMotivationPresumptive.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetFetchNfMotivationPresumptive = (dispatch, data) => {
    const json = {
      type: FETCH_NF_MOTIVATION_PRESUMPTIVE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfMotivationPresumptive.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetFetchNfMotivationPresumptiveError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_MOTIVATION_PRESUMPTIVE.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static getNfMotivationMotivationTypeByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationMotivationTypeByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/finish_motivation
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static postNfMotivationFinishMotivation = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationFinishMotivationUtil.bind(null, newUAMotivationIEGKey),
      POST_NF_MOTIVATION_FINISH_MOTIVATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationFinishMotivation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetPostNfMotivationFinishMotivation = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_FINISH_MOTIVATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationFinishMotivation.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetPostNfMotivationFinishMotivationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_FINISH_MOTIVATION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_hcrgetpresumptiveuser/presumptive
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static fetchNfHcrgetpresumptiveuserPresumptive = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfHcrgetpresumptiveuserPresumptiveUtil,
      FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfHcrgetpresumptiveuserPresumptive.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetFetchNfHcrgetpresumptiveuserPresumptive = (dispatch, data) => {
    const json = {
      type: FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfHcrgetpresumptiveuserPresumptive.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFPresumptiveMotivationActions
   */
  static resetFetchNfHcrgetpresumptiveuserPresumptiveError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE.failureAction);
  };
}
