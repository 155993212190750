/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { FETCH_NF_COC_SNAP_BENEFIT_AMOUNT, POST_LIFE_EVENTS_FORM } from './actionTypes';

import { fetchNfCocSnapBenefitAmountUtil, postLifeEventsFormUtil } from './utils';

/**
 * Contains the action creator functions that update the NFFnsOrSnapRecertMotivation slice of the store.
 *
 * @namespace NFFnsOrSnapRecertMotivationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/snap_benefit_amount
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFFnsOrSnapRecertMotivationActions
   */
  static fetchNfCocSnapBenefitAmount = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCocSnapBenefitAmountUtil,
      FETCH_NF_COC_SNAP_BENEFIT_AMOUNT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCocSnapBenefitAmount.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFFnsOrSnapRecertMotivationActions
   */
  static resetFetchNfCocSnapBenefitAmount = (dispatch, data) => {
    const json = {
      type: FETCH_NF_COC_SNAP_BENEFIT_AMOUNT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCocSnapBenefitAmount.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFFnsOrSnapRecertMotivationActions
   */
  static resetFetchNfCocSnapBenefitAmountError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_COC_SNAP_BENEFIT_AMOUNT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/life_events_form
   *
   * @param {NewUALifeEventContext} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFFnsOrSnapRecertMotivationActions
   */
  static postLifeEventsForm = (dispatch, newUALifeEventContext, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postLifeEventsFormUtil.bind(null, newUALifeEventContext),
      POST_LIFE_EVENTS_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postLifeEventsForm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFFnsOrSnapRecertMotivationActions
   */
  static resetPostLifeEventsForm = (dispatch, data) => {
    const json = {
      type: POST_LIFE_EVENTS_FORM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postLifeEventsForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFFnsOrSnapRecertMotivationActions
   */
  static resetPostLifeEventsFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_LIFE_EVENTS_FORM.failureAction);
  };
}
