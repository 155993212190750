/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { FETCH_NF_ENERGY_IS_ENERGY_ACTIVE } from './actionTypes';

/**
 * Updates the details in the 'NFEnergy' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfEnergyIsEnergyActiveReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_ENERGY_IS_ENERGY_ACTIVE);

/**
 * Updates the indicator of creation of details in the 'NFEnergy' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfEnergyIsEnergyActiveReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_ENERGY_IS_ENERGY_ACTIVE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfEnergyIsEnergyActiveErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_ENERGY_IS_ENERGY_ACTIVE);

const reducers = combineReducers({
  fetchNfEnergyIsEnergyActive: fetchNfEnergyIsEnergyActiveReducer,

  isFetchingFetchNfEnergyIsEnergyActive: isFetchingFetchNfEnergyIsEnergyActiveReducer,
  fetchNfEnergyIsEnergyActiveError: fetchNfEnergyIsEnergyActiveErrorReducer,
});

export default { reducers };
