/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE } from './actionTypes';

/**
 * Updates the details in the 'NFOrganizationVoterRegistration' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfCounterByNfCounterTypeCodeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE);

/**
 * Updates the indicator of creation of details in the 'NFOrganizationVoterRegistration' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfCounterByNfCounterTypeCodeReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfCounterByNfCounterTypeCodeErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE);

const reducers = combineReducers({
  postNfCounterByNfCounterTypeCode: postNfCounterByNfCounterTypeCodeReducer,

  isFetchingPostNfCounterByNfCounterTypeCode: isFetchingPostNfCounterByNfCounterTypeCodeReducer,
  postNfCounterByNfCounterTypeCodeError: postNfCounterByNfCounterTypeCodeErrorReducer,
});

export default { reducers };
