/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS,
  FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS,
  GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID,
  POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS,
  FETCH_NF_DOC_UPLOAD_IS_USER_LINKED,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES,
} from './actionTypes';

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDocumentUploadOutstandingVerificationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDocumentUploadOutstandingVerificationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDocumentUploadOutstandingVerificationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDocumentUploadRejectedDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDocumentUploadRejectedDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDocumentUploadRejectedDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS);

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadDeleteUploadedVerificationDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadDeleteUploadedVerificationDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadDeleteUploadedVerificationDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadVerificationDocumentReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadVerificationDocumentReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadVerificationDocumentErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadSubmitVerificationDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadSubmitVerificationDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadSubmitVerificationDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadSubmitNonLinkedStandaloneDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadMultipleVerificationDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadMultipleVerificationDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadMultipleVerificationDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDocUploadIsUserLinkedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DOC_UPLOAD_IS_USER_LINKED);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDocUploadIsUserLinkedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_DOC_UPLOAD_IS_USER_LINKED);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDocUploadIsUserLinkedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_DOC_UPLOAD_IS_USER_LINKED);

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadDsnapDocumentReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadDsnapDocumentReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadDsnapDocumentErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT);

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadMultipleDsnapDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadMultipleDsnapDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadMultipleDsnapDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadOnlineDocumentReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadOnlineDocumentReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadOnlineDocumentErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT);

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadMultipleOnlineDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadMultipleOnlineDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadMultipleOnlineDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDocumentUploadUploadedStandaloneDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDocumentUploadUploadedStandaloneDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDocumentUploadUploadedStandaloneDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadMultipleStandaloneDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadMultipleStandaloneDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadMultipleStandaloneDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadDeleteUploadedStandaloneDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadDeleteUploadedStandaloneDocumentsReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadDeleteUploadedStandaloneDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDocumentUploadUploadMultipleEnergyDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDocumentUploadUploadMultipleEnergyDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDocumentUploadUploadMultipleEnergyDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDocumentUploadUploadedEnergyDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS);

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDocumentUploadUploadedEnergyDocumentsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDocumentUploadUploadedEnergyDocumentsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS
  );

/**
 * Updates the details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDocumentUploadListNFExistingApplicationAndCasesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES
  );

/**
 * Updates the indicator of creation of details in the 'NFDocumentUpload' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDocumentUploadListNFExistingApplicationAndCasesReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDocumentUploadListNFExistingApplicationAndCasesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES
  );

const reducers = combineReducers({
  fetchNfDocumentUploadOutstandingVerifications: fetchNfDocumentUploadOutstandingVerificationsReducer,

  isFetchingFetchNfDocumentUploadOutstandingVerifications: isFetchingFetchNfDocumentUploadOutstandingVerificationsReducer,
  fetchNfDocumentUploadOutstandingVerificationsError: fetchNfDocumentUploadOutstandingVerificationsErrorReducer,

  fetchNfDocumentUploadRejectedDocuments: fetchNfDocumentUploadRejectedDocumentsReducer,

  isFetchingFetchNfDocumentUploadRejectedDocuments: isFetchingFetchNfDocumentUploadRejectedDocumentsReducer,
  fetchNfDocumentUploadRejectedDocumentsError: fetchNfDocumentUploadRejectedDocumentsErrorReducer,

  getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId: getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdReducer,

  isFetchingGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId: isFetchingGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdReducer,
  getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdError: getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdErrorReducer,

  postNfDocumentUploadDeleteUploadedVerificationDocuments: postNfDocumentUploadDeleteUploadedVerificationDocumentsReducer,

  isFetchingPostNfDocumentUploadDeleteUploadedVerificationDocuments: isFetchingPostNfDocumentUploadDeleteUploadedVerificationDocumentsReducer,
  postNfDocumentUploadDeleteUploadedVerificationDocumentsError: postNfDocumentUploadDeleteUploadedVerificationDocumentsErrorReducer,

  postNfDocumentUploadUploadVerificationDocument: postNfDocumentUploadUploadVerificationDocumentReducer,

  isFetchingPostNfDocumentUploadUploadVerificationDocument: isFetchingPostNfDocumentUploadUploadVerificationDocumentReducer,
  postNfDocumentUploadUploadVerificationDocumentError: postNfDocumentUploadUploadVerificationDocumentErrorReducer,

  postNfDocumentUploadSubmitVerificationDocuments: postNfDocumentUploadSubmitVerificationDocumentsReducer,

  isFetchingPostNfDocumentUploadSubmitVerificationDocuments: isFetchingPostNfDocumentUploadSubmitVerificationDocumentsReducer,
  postNfDocumentUploadSubmitVerificationDocumentsError: postNfDocumentUploadSubmitVerificationDocumentsErrorReducer,

  postNfDocumentUploadSubmitNonLinkedStandaloneDocuments: postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsReducer,

  isFetchingPostNfDocumentUploadSubmitNonLinkedStandaloneDocuments: isFetchingPostNfDocumentUploadSubmitNonLinkedStandaloneDocumentsReducer,
  postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsError: postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsErrorReducer,

  postNfDocumentUploadUploadMultipleVerificationDocuments: postNfDocumentUploadUploadMultipleVerificationDocumentsReducer,

  isFetchingPostNfDocumentUploadUploadMultipleVerificationDocuments: isFetchingPostNfDocumentUploadUploadMultipleVerificationDocumentsReducer,
  postNfDocumentUploadUploadMultipleVerificationDocumentsError: postNfDocumentUploadUploadMultipleVerificationDocumentsErrorReducer,

  fetchNfDocUploadIsUserLinked: fetchNfDocUploadIsUserLinkedReducer,

  isFetchingFetchNfDocUploadIsUserLinked: isFetchingFetchNfDocUploadIsUserLinkedReducer,
  fetchNfDocUploadIsUserLinkedError: fetchNfDocUploadIsUserLinkedErrorReducer,

  postNfDocumentUploadUploadDsnapDocument: postNfDocumentUploadUploadDsnapDocumentReducer,

  isFetchingPostNfDocumentUploadUploadDsnapDocument: isFetchingPostNfDocumentUploadUploadDsnapDocumentReducer,
  postNfDocumentUploadUploadDsnapDocumentError: postNfDocumentUploadUploadDsnapDocumentErrorReducer,

  postNfDocumentUploadUploadMultipleDsnapDocuments: postNfDocumentUploadUploadMultipleDsnapDocumentsReducer,

  isFetchingPostNfDocumentUploadUploadMultipleDsnapDocuments: isFetchingPostNfDocumentUploadUploadMultipleDsnapDocumentsReducer,
  postNfDocumentUploadUploadMultipleDsnapDocumentsError: postNfDocumentUploadUploadMultipleDsnapDocumentsErrorReducer,

  postNfDocumentUploadUploadOnlineDocument: postNfDocumentUploadUploadOnlineDocumentReducer,

  isFetchingPostNfDocumentUploadUploadOnlineDocument: isFetchingPostNfDocumentUploadUploadOnlineDocumentReducer,
  postNfDocumentUploadUploadOnlineDocumentError: postNfDocumentUploadUploadOnlineDocumentErrorReducer,

  postNfDocumentUploadUploadMultipleOnlineDocuments: postNfDocumentUploadUploadMultipleOnlineDocumentsReducer,

  isFetchingPostNfDocumentUploadUploadMultipleOnlineDocuments: isFetchingPostNfDocumentUploadUploadMultipleOnlineDocumentsReducer,
  postNfDocumentUploadUploadMultipleOnlineDocumentsError: postNfDocumentUploadUploadMultipleOnlineDocumentsErrorReducer,

  fetchNfDocumentUploadUploadedStandaloneDocuments: fetchNfDocumentUploadUploadedStandaloneDocumentsReducer,

  isFetchingFetchNfDocumentUploadUploadedStandaloneDocuments: isFetchingFetchNfDocumentUploadUploadedStandaloneDocumentsReducer,
  fetchNfDocumentUploadUploadedStandaloneDocumentsError: fetchNfDocumentUploadUploadedStandaloneDocumentsErrorReducer,

  postNfDocumentUploadUploadMultipleStandaloneDocuments: postNfDocumentUploadUploadMultipleStandaloneDocumentsReducer,

  isFetchingPostNfDocumentUploadUploadMultipleStandaloneDocuments: isFetchingPostNfDocumentUploadUploadMultipleStandaloneDocumentsReducer,
  postNfDocumentUploadUploadMultipleStandaloneDocumentsError: postNfDocumentUploadUploadMultipleStandaloneDocumentsErrorReducer,

  postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments: postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsReducer,

  isFetchingPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments: isFetchingPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsReducer,
  postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsError: postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsErrorReducer,

  postNfDocumentUploadDeleteUploadedStandaloneDocuments: postNfDocumentUploadDeleteUploadedStandaloneDocumentsReducer,

  isFetchingPostNfDocumentUploadDeleteUploadedStandaloneDocuments: isFetchingPostNfDocumentUploadDeleteUploadedStandaloneDocumentsReducer,
  postNfDocumentUploadDeleteUploadedStandaloneDocumentsError: postNfDocumentUploadDeleteUploadedStandaloneDocumentsErrorReducer,

  postNfDocumentUploadUploadMultipleEnergyDocuments: postNfDocumentUploadUploadMultipleEnergyDocumentsReducer,

  isFetchingPostNfDocumentUploadUploadMultipleEnergyDocuments: isFetchingPostNfDocumentUploadUploadMultipleEnergyDocumentsReducer,
  postNfDocumentUploadUploadMultipleEnergyDocumentsError: postNfDocumentUploadUploadMultipleEnergyDocumentsErrorReducer,

  fetchNfDocumentUploadUploadedEnergyDocuments: fetchNfDocumentUploadUploadedEnergyDocumentsReducer,

  isFetchingFetchNfDocumentUploadUploadedEnergyDocuments: isFetchingFetchNfDocumentUploadUploadedEnergyDocumentsReducer,
  fetchNfDocumentUploadUploadedEnergyDocumentsError: fetchNfDocumentUploadUploadedEnergyDocumentsErrorReducer,

  fetchNfDocumentUploadListNFExistingApplicationAndCases: fetchNfDocumentUploadListNFExistingApplicationAndCasesReducer,

  isFetchingFetchNfDocumentUploadListNFExistingApplicationAndCases: isFetchingFetchNfDocumentUploadListNFExistingApplicationAndCasesReducer,
  fetchNfDocumentUploadListNFExistingApplicationAndCasesError: fetchNfDocumentUploadListNFExistingApplicationAndCasesErrorReducer,
});

export default { reducers };
