/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING,
  POST_NF_MOTIVATION_FINISH_COVID_TESTING,
} from './actionTypes';

/**
 * Updates the details in the 'NFCovidTestingMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfMotivationApplyForCovidTestingReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING);

/**
 * Updates the indicator of creation of details in the 'NFCovidTestingMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfMotivationApplyForCovidTestingReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfMotivationApplyForCovidTestingErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING);

/**
 * Updates the details in the 'NFCovidTestingMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationFinishCovidTestingReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_FINISH_COVID_TESTING);

/**
 * Updates the indicator of creation of details in the 'NFCovidTestingMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationFinishCovidTestingReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_FINISH_COVID_TESTING);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationFinishCovidTestingErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_FINISH_COVID_TESTING);

const reducers = combineReducers({
  fetchNfMotivationApplyForCovidTesting: fetchNfMotivationApplyForCovidTestingReducer,

  isFetchingFetchNfMotivationApplyForCovidTesting: isFetchingFetchNfMotivationApplyForCovidTestingReducer,
  fetchNfMotivationApplyForCovidTestingError: fetchNfMotivationApplyForCovidTestingErrorReducer,

  postNfMotivationFinishCovidTesting: postNfMotivationFinishCovidTestingReducer,

  isFetchingPostNfMotivationFinishCovidTesting: isFetchingPostNfMotivationFinishCovidTestingReducer,
  postNfMotivationFinishCovidTestingError: postNfMotivationFinishCovidTestingErrorReducer,
});

export default { reducers };
