/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2019. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import {
  Routes as UARoutes,
  TitledRoute,
  AuthenticatedRoute
} from '@spm/universal-access-ui';
import Loadable from 'react-loadable';
import { Authentication } from '@spm/universal-access';
import { AppSpinner, LoadingPage } from '@spm/core-ui';
import Paths from '@spm/universal-access-ui/es/router/Paths';
import routeTitles from './routesMessages';
import paths from './paths';
import NFSAMLRedirect from './features/NFMFASession/components/NFSAMLRedirect';

/*  This routes.jsx file can be customized to add new components or
    Redirect the existing components to new routes:

   Sample:
     <Switch>
       <Route path="/ourPayments" component={OurPayments} />
       <Redirect from="/payments" to="/ourPayments" />
       <UARoutes />
     </Switch>
*/

/**
 * Default loading component to display while loading other components.
 */
const loadingComponent = () => <AppSpinner />;

/** Landing Page starts here */
const OrganisationHome = Loadable({
  loader: () => import('./features/NFOrganisation/NFOrganisationContainer'),
  loading: loadingComponent
});
/** Landing Page ends here */

const ApplicationOverview = Loadable({
  loader: () =>
    import('./features/NFApplicationOverview/NFApplicationOverviewContainer'),
  loading: loadingComponent
});
const SubmissionFormConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFForms/NFSubmissionForm/NFSubmissionFormConfirmationComponent'
    ),
  loading: LoadingPage
});

/** Home Page starts here */
const NFAccountHome = Loadable({
  loader: () => import('./features/NFAccountHome/NFAccountHomeContainer'),
  loading: loadingComponent
});
/** Home Page ends here */

/** Docupload start here */

const NFUploadFilePicker = Loadable({
  loader: () =>
    import('./features/NFDocumentToUpload/component/NFUploadFilePicker'),
  loading: loadingComponent
});

const NFUploadStandaloneFilePicker = Loadable({
  loader: () =>
    import('./features/NFDocumentToUpload/component/NFUploadStandaloneFilePicker'),
  loading: loadingComponent
});

const NFENUploadStandaloneFilePicker = Loadable({
  loader: () =>
    import('./features/NFDocumentToUpload/component/NFENUploadStandaloneFilePicker'),
  loading: loadingComponent
});

/** Docupload end here */

/** Docupload New UX start here */
const NFDocumentToUpload = Loadable({
  loader: () =>
    import(
      './features/NFDocumentToUpload/component/NFDocumentToUploadComponent'
    ),
  loading: loadingComponent
});
const NFDocumentToUploadQAndA = Loadable({
  loader: () =>
    import(
      './features/NFDocumentToUpload/component/NFDocumentToUploadQuestionAnsComponent'
    ),
  loading: loadingComponent
});
/** Docupload New UXend here */

/** eNotices start here */
const NFSecureInbox = Loadable({
  loader: () => import('./features/NFSecureInbox'),
  loading: loadingComponent
});
const NFSecureInboxQandA = Loadable({
  loader: () =>
    import('./features/NFSecureInbox/components/NFSecureInboxQandAComponent'),
  loading: loadingComponent
});
const NFeNotices = Loadable({
  loader: () => import('./features/NFeNotices'),
  loading: loadingComponent
});
const NFeNoticesQandA = Loadable({
  loader: () => import('./features/NFeNotices'),
  loading: loadingComponent
});
const NFTextAndSecureQandA = Loadable({
  loader: () => import('./features/NFSecureMessages/component/NFTextAndSecureMessagingQandAComponent'),
  loading: loadingComponent
});
const NFFNSorSNAPOnlineRecertFAQs = Loadable({
  loader: () => import('./features/NFFNSorSNAPFAQs/components/NFFNSorSNAPFAQsComponent'),
  loading: loadingComponent
});
const NFFNSorSNAPOnlineCOCFAQs = Loadable({
  loader: () => import('./features/NFFNSorSNAPOnlineCOCFAQs/components/NFFNSorSNAPOnlineCOCFAQsComponent'),
  loading: loadingComponent
});
const NFeNoticesListComm = Loadable({
  loader: () => import('./features/NFeNotices'),
  loading: loadingComponent
});
/** eNotices end here */

/** Appeals start here */
const NFAppealRequest = Loadable({
  loader: () => import('./features/NFAppealRequests'),
  loading: loadingComponent
});
const NFAppealForm = Loadable({
  loader: () => import('./features/NFForms/NFAppeals'),
  loading: loadingComponent
});
const NFAppealRequestsConfirmationComponent = Loadable({
  loader: () =>
    import(
      './features/NFAppealRequestsConfirmation/NFAppealRequestsConfirmationComponent'
    ),
  loading: loadingComponent
});
/** Appeals end here */

/** Unified application starts here */
const NFApplyForAssistanceOverview = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistance/components/overview/NFApplyForAssistanceOverviewContainer'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceForm = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistance/components/form/NFApplyForAssistanceFormComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistance/components/signAndSubmit/NFApplyForAssistanceSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistance/components/confirmation/NFApplyForAssistanceConfirmationComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceAnonymousOverview = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistanceAnonymous/components/overview/NFApplyForAssistanceAnonymousOverviewComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceAnonymousForm = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistanceAnonymous/components/form/NFApplyForAssistanceAnonymousFormComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceAnonymousExitWarning = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistanceAnonymous/components/exitWarning/NFApplyForAssistanceAnonymousExitWarningComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceAnonymousSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistanceAnonymous/components/signAndSubmit/NFApplyForAssistanceAnonymousSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const NFApplyForAssistanceAnonymousConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistanceAnonymous/components/confirmation/NFApplyForAssistanceAnonymousConfirmationComponent'
    ),
  loading: loadingComponent
});
/** Unified application ends here */

const NFDisasterAssistance = Loadable({
  loader: () => import('./features/NFDisaster/components/NFDisasterAssistance'),
  loading: loadingComponent
});

/** Account Settings  starts */
const NFAccountSettings = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const UpdatePrimaryContact = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const NFUpdateSecondaryContactComponent = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const NFAddSecondaryContactComponent = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const confirmPrimarySecurityCode = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const confirmSecondarySecurityCode = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const NFNCDHHSOptInComponent = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const NFNCDHHSUpdateComponent = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const NFSecureMessagesOptInComponent = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
const NFSecureMessagesUpdateComponent = Loadable({
  loader: () => import('./features/NFAccountSettings'),
  loading: loadingComponent
});
/** Account Settings  ends here */

/** Casewokerinfo starts here */
const NFCaseworkerInfoComponent = Loadable({
  loader: () => import('./features/NFCaseWorkerInfo/NFCaseworkerInfoContainer'),
  loading: loadingComponent
});

/** Casewokerinfo ends here */

/** My Information page change of circumstances starts here */
const NFMyInformationComponent = Loadable({
  loader: () => import('./features/NFMyInformation/NFMyInformationContainer'),
  loading: loadingComponent
});
const NFUpdateMyInformationComponent = Loadable({
  loader: () =>
    import(
      './features/NFMyInformation/components/NFUpdateMyInformationComponent'
    ),
  loading: loadingComponent
});
const NFFNSorSNAPCOCComponent = Loadable({
  loader: () =>
    import(
      './features/NFMyInformation/components/NFFNSorSNAPCOCComponent'
    ),
  loading: loadingComponent
});
const NFIntermediateCOCComponent = Loadable({
  loader: () =>
    import(
      './features/NFMyInformation/components/NFIntermediateCOCComponent'
    ),
  loading: loadingComponent
});
const NFCOCForm = Loadable({
  loader: () =>
    import(
      './features/NFChangeOfCircumstance/components/form/NFCOCFormComponent'
    ),
  loading: loadingComponent
});
const NFCOCSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFChangeOfCircumstance/components/signAndSubmit/NFCOCSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const NFCOCConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFChangeOfCircumstance/components/confirmation/NFCOCConfirmationComponent'
    ),
  loading: loadingComponent
});

/** My Information page change of circumstances ends here */

/** FNS/SNAP benefits page starts here */
const NFFnsOrSnapRecertComponent = Loadable({
  loader: () =>
    import(
      './features/NFFnsOrSnapRecert/components/NFFnsOrSnapRecertComponent'
    ),
  loading: loadingComponent
});
const nfRenewFnsOrSnapForm = Loadable({
  loader: () =>
    import(
      './features/NFFnsOrSnapRecert/components/form/NFFnsOrSnapRecertFormComponent'
    ),
  loading: loadingComponent
});
const nfRenewFnsOrSnapSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFFnsOrSnapRecert/components/signAndSubmit/NFFnsOrSnapSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const nfRenewFnsOrSnapConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFFnsOrSnapRecert/components/confirmation/NFFnsOrSnapConfirmationComponent'
    ),
  loading: loadingComponent
});

/** FNS/SNAP benefits page ends here */

/** SNAP COC starts here */
const NFSnapCOCForm = Loadable({
  loader: () =>
    import(
      './features/NFSnapCOC/components/form/NFSnapCOCFormComponent'
    ),
  loading: loadingComponent
});
const NFSnapCOCSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFSnapCOC/components/signAndSubmit/NFSnapCOCSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const NFSnapCOCConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFSnapCOC/components/confirmation/NFSnapCOCConfirmationComponent'
    ),
  loading: loadingComponent
});
/** SNAP COC ends here */

/** FNS COC starts here */
const NFFNSCOCForm = Loadable({
  loader: () =>
    import(
      './features/NFFNSCOC/components/form/NFFNSCOCFormComponent'
    ),
  loading: loadingComponent
});
const NFFNSCOCSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFFNSCOC/components/signAndSubmit/NFFNSCOCSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const NFFNSCOCConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFFNSCOC/components/confirmation/NFFNSCOCConfirmationComponent'
    ),
  loading: loadingComponent
});
/** FNS COC ends here */

/** standalone RIDP screen start  here */
const nfStandaloneRidpComponent = Loadable({
  loader: () => import('./features/NFStandaloneRIDP/NFStandaloneRIDPContainer'),
  loading: loadingComponent
});
const NFStandaloneRDIPForm = Loadable({
  loader: () =>
    import(
      './features/NFStandaloneRIDP/components/form/NFStandaloneRIDPFormComponent'
    ),
  loading: loadingComponent
});
/** standalone RIDP screen start  here */

/** RIDP QA starts  here */
const NFRIDPComponent = Loadable({
  loader: () => import('./features/NFRIDP'),
  loading: loadingComponent
});
/** RIDP QA ends  here */

/** RIDP QA from IEG starts  here */
const NFRIDPQAIEGComponent = Loadable({
  loader: () =>
    import('./features/NFRIDP/components/NFRIDPQuestionAnsForIEGComponent'),
  loading: loadingComponent
});
/** RIDP QA from IEG ends  here */

/** Presumptive starts here */
const NFPresumptiveForm = Loadable({
  loader: () =>
    import(
      './features/NFPresumptive/components/form/NFPresumptiveFormComponent'
    ),
  loading: loadingComponent
});
const NFPresumptiveConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFPresumptive/components/NFPresumptiveConfirmation/NFPresumptiveConfirmationComponent'
    ),
  loading: loadingComponent
});
/** Presumptive ends here */

/** Deemed New Born starts here */
const NFDeemedNewBornForm = Loadable({
  loader: () =>
    import(
      './features/NFDeemedNewBorn/components/form/NFDeemedNewBornFormComponent'
    ),
  loading: loadingComponent
});
const NFDeemedNewBornConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFDeemedNewBorn/components/NFDeemedNewBornConfirmation/NFDeemedNewBornConfirmationComponent'
    ),
  loading: loadingComponent
});
const NFDeemedNewBornSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFDeemedNewBorn/components/signAndSubmit/NFDeemedNewBornSignAndSubmitComponent'
    ),
  loading: loadingComponent
});

/** Deemed New Born ends here */

/** Learn More in Landing page starts here */
const NFLandingPageLearnMore = Loadable({
  loader: () =>
    import(
      './features/NFLandingPageLearnMore/components/NFLandingPageLearnMoreComponent'
    ),
  loading: loadingComponent
});
/** Learn More in Landing page ends here */

/** ePASS Disclaimer starts here */
const NFePASSDisclaimer = Loadable({
  loader: () =>
    import(
      './features/NFApplicationFooter/components/NFePASSDisclaimer/NFePASSDisclaimerComponent'
    ),
  loading: loadingComponent
});
/** ePASS Disclaimer ends here */

/** NFFNSInfoFactSheet starts here */
const NFFNSInfoFactSheet = Loadable({
  loader: () => import('./features/NFFNSInfoFactSheet'),
  loading: loadingComponent
});
/** NFFNSInfoFactSheet ends here */

/** NFApplyingOnBehalfOfSomeone component starts here */
const NFApplyingOnBehalfofSomeone = Loadable({
  loader: () =>
    import(
      './features/NFApplyingOnBehalfofSomeone/components/NFApplyingOnBehalfofSomeoneComponent'
    ),
  loading: loadingComponent
});
/** NFApplyingOnBehalfOfSomeone ends here */

/** NFLIEAP component starts here */
const NFLIEAP = Loadable({
  loader: () => import('./features/NFLIEAP/components/NFLIEAPComponent'),
  loading: loadingComponent
});
const NFApplyLIEAPAnonymousAccountOptions = Loadable({
  loader: () =>
    import('./features/NFLIEAP/components/NFApplyLIEAPAnonymousAccountOptions'),
  loading: loadingComponent
});
const NFApplyLIEAPForm = Loadable({
  loader: () =>
    import('./features/NFLIEAP/components/form/NFLIEAPFormContainer'),
  loading: loadingComponent
});
const NFApplyLIEAPConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFLIEAP/components/confirmation/NFLIEAPConfirmationComponent'
    ),
  loading: loadingComponent
});
const NFApplyLIEAPExitWarning = Loadable({
  loader: () =>
    import(
      './features/NFLIEAP/components/exitWarning/NFLIEAPExitWarningComponent'
    ),
  loading: loadingComponent
});
const NFApplyLIEAPAnonymousForm = Loadable({
  loader: () =>
    import('./features/NFLIEAP/components/form/NFLIEAPAnonymousFormComponent'),
  loading: loadingComponent
});
/** NFLIEAP ends here */

const NFLearnMore = Loadable({
  loader: () => import('./features/NFMFASession/components/NFLearnMore'),
  loading: loadingComponent
});
const NFUnableToLogin = Loadable({
  loader: () => import('./features/NFMFASession/components/NFUnableToLogin'),
  loading: loadingComponent
});
const NFRedirect = Loadable({
  loader: () => import('./features/NFMFASession/components/NFRedirect'),
  loading: loadingComponent
});
const NFMFASuccess = Loadable({
  loader: () => import('./features/NFMFASession/components/NFMFASuccess'),
  loading: loadingComponent
});
const NFResendCode = Loadable({
  loader: () => import('./features/NFMFASession/components/NFResendCode'),
  loading: loadingComponent
});
const NFCreateNCIDAccount = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFCreateNCIDAccount'),
  loading: loadingComponent
});
const NFSignUpHomePage = Loadable({
  loader: () =>
    import('./features/NFAccountHome/components/NFSignUpHomePage'),
  loading: loadingComponent
});
const NFAccountOptions = Loadable({
  loader: () => import('./features/NFMFASession/components/NFAccountOptions'),
  loading: loadingComponent
});
const NFApplyForAssistanceAnonymousAccountOptions = Loadable({
  loader: () =>
    import(
      './features/NFApplyForAssistanceAnonymous/components/NFApplyForAssistanceAnonymousAccountOptions'
    ),
  loading: loadingComponent
});
const NFLogin = Loadable({
  loader: () => import('./features/NFMFASession/components/NFLogin'),
  loading: loadingComponent
});
const NFLogout = Loadable({
  loader: () => import('./features/NFMFASession/components/NFLogout'),
  loading: loadingComponent
});
const NFMFAQuestionsComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFAQuestionsComponent'),
  loading: loadingComponent
});
const NFMFAAccountLockedComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFAAccountLockedComponent'),
  loading: loadingComponent
});
const NFMFAAccountDelinkedComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFAAccountDelinkedComponent'),
  loading: loadingComponent
});
const NFMFATemporarilyLockedComponent = Loadable({
  loader: () =>
    import(
      './features/NFMFASession/components/NFMFATemporarilyLockedComponent'
    ),
  loading: loadingComponent
});
const NFMFAConfirmCodeComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFAConfirmCodeComponent'),
  loading: loadingComponent
});
const NFMFAIncorrectLoginComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFAIncorrectLoginComponent'),
  loading: loadingComponent
});
const NFMFAErrorComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFAErrorComponent'),
  loading: loadingComponent
});
const NFMFARememberDeviceComponent = Loadable({
  loader: () =>
    import('./features/NFMFASession/components/NFMFARememberDeviceComponent'),
  loading: loadingComponent
});
const NFMFAAutoNewBornLockedComponent = Loadable({
  loader: () =>
    import(
      './features/NFMFASession/components/NFMFAAutoNewBornLockedComponent'
    ),
  loading: loadingComponent
});
/** Application Pending screen starts here */
const NFApplicationPendingComponent = Loadable({
  loader: () =>
    import(
      './features/NFApplicationPending/components/NFApplicationPendingComponent'
    ),
  loading: loadingComponent
});
/** Application Pending screen ends here */

/** YourApplications start */
const NFYourApplications = Loadable({
  loader: () => import('./features/NFYourApplications/YourBenefitsContainer'),
  loading: loadingComponent
});

/** YourApplications end */

/** Your Referrals start */
const NFYourReferrals = Loadable({
  loader: () => import('./features/NFYourReferrals/NFYourReferralsContainer'),
  loading: loadingComponent
});
/** Your Referrals end */

/** Archived Referrals start */
const NFArchivedReferrals = Loadable({
  loader: () =>
    import('./features/NFArchivedReferrals/NFArchivedReferralsContainer'),
  loading: loadingComponent
});
/** Archived Referrals end */

/** FAQ start */
const NFFAQComponent = Loadable({
  loader: () =>
    import(
      './features/NFApplicationFooter/components/NFFrequentlyAnsweredQuestions/NFFrequentlyAnsweredQuestionsComponent'
    ),
  loading: loadingComponent
});
/** FAQ end */

/** NFBrowserSupportVersions starts here */
const NFBrowserSupportVersions = Loadable({
  loader: () => import('./features/NFBrowserSupportVersions'),
  loading: loadingComponent
});
/** NFBrowserSupportVersions ends here */

const NFApplyForBenefits = Loadable({
  loader: () => import('./features/NFApplyForBenefits'),
  loading: LoadingPage
});

const NFApplyForCovidTestingOverview = Loadable({
  loader: () =>
    import(
      './features/NFApplyForCovidTesting/components/overview/NFApplyForCovidTestingOverviewComponent'
    ),
  loading: loadingComponent
});
const NFApplyForCovidTestingForm = Loadable({
  loader: () =>
    import(
      './features/NFApplyForCovidTesting/components/form/NFApplyForCovidTestingFormComponent'
    ),
  loading: loadingComponent
});
const NFApplyForCovidTestingExitWarning = Loadable({
  loader: () =>
    import(
      './features/NFApplyForCovidTesting/components/exitWarning/NFApplyForCovidTestingExitWarningComponent'
    ),
  loading: loadingComponent
});
const NFApplyForCovidTestingSignAndSubmit = Loadable({
  loader: () =>
    import(
      './features/NFApplyForCovidTesting/components/signAndSubmit/NFApplyForCovidTestingSignAndSubmitComponent'
    ),
  loading: loadingComponent
});
const NFApplyForCovidTestingConfirmation = Loadable({
  loader: () =>
    import(
      './features/NFApplyForCovidTesting/components/confirmation/NFApplyForCovidTestingConfirmationComponent'
    ),
  loading: loadingComponent
});
const NFSecureMessages = Loadable({
  loader: () =>
    import(
      './features/NFSecureMessages/NFSecureMessageContainer'
    ),
  loading: loadingComponent
});

const NFApplyForMedicaidExpansionOverview = Loadable({
  loader: () =>
    import(
      './features/NFApplyForMedicaidExpansion/components/overview/NFApplyForMedicaidExpansionOverviewComponent'
    ),
  loading: loadingComponent
});

export default (
  <>
    <Switch>
      <Route component={NFSAMLRedirect} exact path="/samlredirect" />
      <Redirect path={`${paths.SAMPLE_APPLICATION}`} to={paths.HOME} />
      <Redirect
        path={`${paths.SAMPLE_APPLICATION.FORM}/:formId`}
        to={paths.HOME}
      />
      <Redirect
        path={`${paths.SAMPLE_APPLICATION.CONFIRMATION}/:formId`}
        to={paths.HOME}
      />
      <Redirect path={paths.SAMPLE_APPLICATION.OVERVIEW} to={paths.HOME} />
      <Redirect path={paths.SAMPLE_APPLICATION.ROOT} to={paths.HOME} />
      <Redirect path={paths.PAYMENTS.ROOT} to={paths.HOME} />
      <Redirect path={paths.PAYMENTS.DETAILS} to={paths.HOME} />
      <Redirect path={paths.NOTICES.ROOT} to={paths.HOME} />
      <Redirect path={paths.NOTICES.DETAILS} to={paths.HOME} />
      <Redirect path={paths.LIFE_EVENTS.ROOT} to={paths.HOME} />
      <Redirect path={paths.LIFE_EVENTS.FORM} to={paths.HOME} />
      <Redirect path={paths.LIFE_EVENTS.RESUME} to={paths.HOME} />
      <Redirect path={paths.LIFE_EVENTS.HISTORY} to={paths.HOME} />
      <Redirect path={paths.LIFE_EVENTS.OVERVIEW} to={paths.HOME} />
      <Redirect path={paths.DOCUPLOAD} to={paths.HOME} />
      <Redirect path={paths.APPEALS.ROOT} to={paths.HOME} />
      <Redirect path={paths.APPEALS.FORM} to={paths.HOME} />
      <Redirect path={paths.APPEALS.RESUME} to={paths.HOME} />
      <Redirect path={paths.APPEALS.OVERVIEW} to={paths.HOME} />
      <Redirect path={paths.APPEALS.SUBMITTED} to={paths.HOME} />
      <Redirect path={Paths.PROFILE} to={paths.HOME} />
      <Redirect path={Paths.BENEFIT_SELECTION} to={paths.HOME} />
      <Redirect path={Paths.BENEFITS} to={paths.HOME} />
      <Redirect path={Paths.CONTACT_US} to={paths.HOME} />
      <Redirect path={Paths.FAQS} to={paths.HOME} />
      <Redirect path={Paths.SIGNUP} to={paths.HOME} />
      <Redirect path={Paths.FORGOT_PASSWORD} to={paths.HOME} />
      <Redirect path={Paths.PASSWORD_CHANGED} to={paths.HOME} />
      <Redirect path={Paths.ELIGIBILITY.ROOT} to={paths.HOME} />
      <Redirect path={Paths.ELIGIBILITY.BENEFIT_SELECTION} to={paths.HOME} />
      <Redirect path={Paths.ELIGIBILITY.FORM} to={paths.HOME} />
      <Redirect path={Paths.ELIGIBILITY.RESUME} to={paths.HOME} />
      <Redirect path={Paths.ELIGIBILITY.FILTER_FORM} to={paths.HOME} />
      <Redirect path={Paths.ELIGIBILITY.FILTER_FORM_RESUME} to={paths.HOME} />

      <TitledRoute
        component={NFApplyForBenefits}
        exact
        path={Paths.APPLY}
        title={routeTitles.applyForBenefitsPageTitle}
      />
      <TitledRoute
        component={NFLearnMore}
        exact
        path={`${paths.NF_MFA_SESSION.NFLEARNMORE}`}
        title={routeTitles.nfLearnMore}
      />
      <TitledRoute
        component={NFUnableToLogin}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAUNABLELOGIN}`}
        title={routeTitles.nfUnableToLogin}
      />
      <TitledRoute
        component={NFResendCode}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFARESENDCODE}/:uuid/:selectedOption/:selectedLabel`}
        title={routeTitles.nfResendCode}
      />
      <TitledRoute
        component={NFRedirect}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAREDIRECT}/:enteredSelection`}
        title={routeTitles.nfRedirect}
      />
      <TitledRoute
        component={NFMFASuccess}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFASUCCESS}`}
        title={routeTitles.nfMFASuccess}
      />
      <TitledRoute
        component={NFCreateNCIDAccount}
        exact
        path={`${paths.NFCREATENCIDACCOUNT}`}
        title={routeTitles.nfCreateNCIDAccount}
      />
      <TitledRoute
        component={NFSignUpHomePage}
        exact
        path={`${paths.NFSIGNUPHOMEPAGE}`}
        title={routeTitles.nfSignUpHomePage}
      />
      <TitledRoute
        component={NFAccountOptions}
        exact
        path={`${paths.NFACCOUNTOPTIONS}`}
        title={routeTitles.nfAccountOptions}
      />
      <TitledRoute
        component={NFApplyForAssistanceAnonymousAccountOptions}
        exact
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION.NFACCOUNTOPTIONS}`}
        title={routeTitles.nfApplyForAssistanceAnonymousAccountOptions}
      />
      {process.env.REACT_APP_SIMPLE_AUTH_ON !== 'true' && (
        <Redirect path={`${paths.LOGIN}`} to={`${paths.NFACCOUNTOPTIONS}`} />
      )}
      <TitledRoute
        component={NFLogin}
        exact
        path={`${paths.NFLOGIN}`}
        title={routeTitles.nfLogin}
      />
      <TitledRoute
        component={NFLogout}
        exact
        path={`${paths.NFLOGOUT}`}
        title={routeTitles.nfLogout}
      />
      <TitledRoute
        component={NFMFAQuestionsComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAQUESTIONS}/:uuid`}
        title={routeTitles.nfMFAQuestions}
      />
      <TitledRoute
        component={NFMFAConfirmCodeComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFACONFIRMCODE}/:uuid/:selectedOption/:selectedLabel`}
        title={routeTitles.nMFAConfirmCode}
      />
      <TitledRoute
        component={NFMFAIncorrectLoginComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAINCORRECTLOGIN}/:uuid/:selectedOption/:selectedLabel/:logInResponse`}
        title={routeTitles.nMFAIncorrectLogin}
      />
      <TitledRoute
        component={NFMFAErrorComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAERROR}`}
        title={routeTitles.nfMFAError}
      />
      <TitledRoute
        component={NFMFARememberDeviceComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAREMEMBERDEVICE}/:uuid`}
        title={routeTitles.nfMFARememberDevice}
      />
      <TitledRoute
        component={NFMFATemporarilyLockedComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFATEMPORARILYLOCKED}`}
        title={routeTitles.nfMFATemporarilyLocked}
      />
      <TitledRoute
        component={NFMFAAutoNewBornLockedComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAAUTONEWBORNLOCKED}`}
        title={routeTitles.nfMFAAutoNewBornLocked}
      />
      <TitledRoute
        component={NFMFAAccountLockedComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAACCOUNTLOCKED}`}
        title={routeTitles.nfMFAAccountLocked}
      />
      <TitledRoute
        component={NFMFAAccountDelinkedComponent}
        exact
        path={`${paths.NF_MFA_SESSION.NFMFAACCOUNTDELINKED}`}
        title={routeTitles.nfMFAAccountDelinked}
      />
      <TitledRoute
        component={OrganisationHome}
        exact
        path={`${paths.HOME}`}
        title={routeTitles.orgHome}
      />
      <TitledRoute
        component={ApplicationOverview}
        exact
        path={`${paths.APPLICATION_OVERVIEW}`}
        title={routeTitles.overviewTitle}
      />
      <TitledRoute
        component={SubmissionFormConfirmation}
        exact
        path={`${paths.APPLY}/:applicationFormId/success`}
        title={routeTitles.submissionFormConfirmation}
      />
      <TitledRoute
        component={NFFAQComponent}
        exact
        path={`${paths.NFFAQ}`}
        title={routeTitles.nfFAQ}
      />
      <TitledRoute
        component={NFLandingPageLearnMore}
        exact
        path={paths.NFLANDINGPAGELEARNMORE}
        title={routeTitles.nfLandingPageLearnMore}
      />
      <TitledRoute
        component={NFePASSDisclaimer}
        exact
        path={paths.NFEPASSDISCLAIMER}
        title={routeTitles.nfEpassDisclaimer}
      />
      <TitledRoute
        component={NFRIDPComponent}
        exact
        path={paths.NFRIDP}
        title={routeTitles.nfRidp}
      />
      <TitledRoute
        component={NFRIDPQAIEGComponent}
        exact
        path={paths.NFRIDPQAIEG}
        title={routeTitles.nfRidpIEGQA}
      />
      <TitledRoute
        component={NFFNSInfoFactSheet}
        exact
        path={paths.NFFNSINFOFACTSHEET}
        title={routeTitles.nfFNSinfoFactsheet}
      />
      <TitledRoute
        component={NFBrowserSupportVersions}
        exact
        path={paths.NFBROWSERSUPPORTVERSION}
        title={routeTitles.nfBrowserSupportVersions}
      />
      <TitledRoute
        component={NFeNoticesQandA}
        exact
        path={`${paths.NFENOTICESQANDA}`}
        title={routeTitles.nfeNoticesQandA}
      />
      <TitledRoute
        component={NFTextAndSecureQandA}
        exact
        path={`${paths.NFTEXTANDSECUREMESSAGINGQANDA}`}
        title={routeTitles.nfTextAndSecureMessagingQandA}
      />
      <TitledRoute
        component={NFFNSorSNAPOnlineRecertFAQs}
        exact
        path={`${paths.NF_FNS_OR_SNAP_ONLINE_RECERT_FAQ}`}
        title={routeTitles.nfFNSorSNAPOnlineRecertFAQ}
      />
      <TitledRoute
        component={NFFNSorSNAPOnlineCOCFAQs}
        exact
        path={`${paths.NF_FNS_OR_SNAP_ONLINE_COC_FAQ}`}
        title={routeTitles.nfFNSorSNAPOnlineCOCFAQ}
      />      
      <TitledRoute
        component={NFApplyingOnBehalfofSomeone}
        exact
        path={`${paths.NFAPPLYINGONBEHALFOFSOMEONE}`}
        title={routeTitles.nfApplyingOnBehalfofSomeone}
      />
      <TitledRoute
        component={NFLIEAP}
        exact
        path={`${paths.NFLIEAP}`}
        title={routeTitles.nflieap}
      />
      {/* -- LIEAP -- */}
      <TitledRoute
        component={NFApplyLIEAPAnonymousAccountOptions}
        exact
        path={`${paths.NF_LIEAP.NFACCOUNTOPTIONS}`}
        title={routeTitles.nfApplyLIEAPAnonymousAccountOptions}
      />
      <TitledRoute
        component={NFApplyLIEAPForm}
        exact
        isIEG
        path={`${paths.NF_LIEAP.FORM}/:applicationTypeID`}
        title={routeTitles.nfApplyLIEAPForm}
      />
      <TitledRoute
        component={NFApplyLIEAPConfirmation}
        exact
        path={`${paths.NF_LIEAP.CONFIRMATION}/:formId`}
        title={routeTitles.nfApplyLIEAPConfirmation}
      />
      <TitledRoute
        component={NFApplyLIEAPExitWarning}
        exact
        path={`${paths.NF_LIEAP.EXIT_WARNING}/:formId`}
        title={routeTitles.nfApplyLIEAPExitWarning}
      />
      <TitledRoute
        component={NFApplyLIEAPAnonymousForm}
        exact
        isIEG
        path={`${paths.NF_LIEAP.ANONYMOUS_FORM}/:applicationTypeID`}
        title={routeTitles.nfApplyLIEAPAnonymousForm}
      />
      {/* -- End LIEAP -- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAccountHome}
        exact
        path={`${paths.NFACCOUNTHOME}`}
        title={routeTitles.nfaccountHome}
      />
      {/* --Account Settings  start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAccountSettings}
        exact
        path={paths.NFACCOUNTSETTINGS}
        title={routeTitles.nfAccountSettings}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={UpdatePrimaryContact}
        exact
        path={paths.NFUPDATE_PRIMARY_CONTACT}
        title={routeTitles.nfUpdatePrimaryContact}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={confirmPrimarySecurityCode}
        exact
        path={paths.NFCONFIRM_PRIMARY_SECURITY_CODE}
        title={routeTitles.nfConfirmPrimarySecurityCode}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={confirmSecondarySecurityCode}
        exact
        path={paths.NFCONFIRM_SECONDARY_SECURITY_CODE}
        title={routeTitles.nfConfirmSecondarySecurityCode}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFUpdateSecondaryContactComponent}
        exact
        path={`${paths.NFUPDATE_SECONDARY_CONTACT}`}
        title={routeTitles.nfUpdateSecondaryContact}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAddSecondaryContactComponent}
        exact
        path={`${paths.NFADD_SECONDARY_CONTACT}`}
        title={routeTitles.nfAddSecondaryContact}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFNCDHHSOptInComponent}
        exact
        path={`${paths.NFNCDHHS_ALERTS_OPTIN}`}
        title={routeTitles.nfNCDHHSAlertsOptIn}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFNCDHHSUpdateComponent}
        exact
        path={`${paths.NFNCDHHS_ALERTS_UPDATE}`}
        title={routeTitles.nfNCDHHSAlertsUpdate}
      />
       <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSecureMessagesOptInComponent}
        exact
        path={`${paths.NF_SECURE_MSG_OPT_IN}`}
        title={routeTitles.nfSecureMessagesOptIn}
      />
       <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSecureMessagesUpdateComponent}
        exact
        path={`${paths.NF_SECURE_MSG_UPDATE}`}
        title={routeTitles.nfSecureMessagesUpdate}
      />
      {/* --Account Settings  end-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFUploadFilePicker}
        exact
        path={`${paths.NFDOCUMENTTOUPLOAD}/upload/:documentType/:vDIEDLinkID`}
        title={routeTitles.nfUploadFilePicker}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFUploadStandaloneFilePicker}
        exact
        path={`${paths.NFDOCUMENTTOUPLOAD}/upload/standalone`}
        title={routeTitles.nfUploadStandaloneFilePicker}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFENUploadStandaloneFilePicker}
        exact
        path={`${paths.NFDOCUMENTTOUPLOAD}/energy/upload/standalone`}
        title={routeTitles.nfENUploadStandaloneFilePicker}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFDocumentToUpload}
        exact
        path={`${paths.NFDOCUMENTTOUPLOAD}`}
        title={routeTitles.nfDocumentToUpload}
      />
      <TitledRoute
        component={NFDocumentToUploadQAndA}
        exact
        path={`${paths.NFDOCUMENTTOUPLOADQANDA}`}
        title={routeTitles.nfdocumentuploadqanda}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSecureInbox}
        exact
        path={`${paths.NFSECUREINBOX}`}
        title={routeTitles.nfSecureInbox}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSecureInboxQandA}
        exact
        path={`${paths.NFSECUREINBOXQANDA}`}
        title={routeTitles.nfSecureInboxQandA}
      />
      {/* --eNotices starts -- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFeNotices}
        exact
        path={`${paths.NFENOTICES}`}
        title={routeTitles.nfeNotices}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFeNoticesListComm}
        exact
        path={`${paths.NFENOTICESLISTCOMM.HOME}/:id`}
        title={routeTitles.nfeNoticesListComm}
      />
      {/* --eNotices ends -- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFCaseworkerInfoComponent}
        exact
        path={`${paths.NFCASEWORKERINFO}`}
        title={routeTitles.nfCaseworkerInfo}
      />
      {/* --Appeal start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAppealRequest}
        exact
        path={`${paths.NFAPPEALS.ROOT}`}
        title={routeTitles.nfHeaderAppealsLabel}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAppealForm}
        isIEG
        path={`${paths.NFAPPEALS.FORM}`}
        title={routeTitles.nfAppealForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAppealForm}
        exact
        isIEG
        path={`${paths.NFAPPEALS.RESUME}/:formId`}
        title={routeTitles.nfAppealDetails}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFAppealRequestsConfirmationComponent}
        exact
        path={`${paths.NFAPPEALS.SUBMITTED}`}
        title={routeTitles.nfAppealRequestConfirmation}
      />
      {/* --Appeal end-- */}
      {/* --Unified Motivation start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFApplyForAssistanceOverview}
        exact
        path={paths.NF_APPLY_FOR_ASSISTANCE_MOTIVATION.OVERVIEW}
        title={routeTitles.nfApplyForAssistanceOverview}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFApplyForAssistanceForm}
        exact
        isIEG
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfApplyForAssistanceForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFApplyForAssistanceSignAndSubmit}
        exact
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_MOTIVATION.SIGN_AND_SUBMIT}/:formId`}
        title={routeTitles.nfApplyForAssistanceSignAndSubmit}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFApplyForAssistanceConfirmation}
        exact
        path={paths.NF_APPLY_FOR_ASSISTANCE_MOTIVATION.CONFIRMATION}
        title={routeTitles.nfApplyForAssistanceConfirmation}
      />
      {/* --Unified Motivation end-- */}
      {/* --Standalone RIDP start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={nfStandaloneRidpComponent}
        exact
        path={`${paths.NFSTANDALONERIDP}`}
        title={routeTitles.nfStandaloneRidp}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFStandaloneRDIPForm}
        exact
        isIEG
        path={`${paths.NF_STANDALONE_RIDP_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfStandaloneRIDPForm}
      />
      {/* --Standalone RIDP end-- */}
      {/* --Anonymousflow starts-- */}
      <TitledRoute
        component={NFApplyForAssistanceAnonymousOverview}
        exact
        path={paths.NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION.OVERVIEW}
        title={routeTitles.nfApplyForAssistanceAnonymousOverview}
      />
      <TitledRoute
        component={NFApplyForAssistanceAnonymousForm}
        exact
        isIEG
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfApplyForAssistanceAnonymousForm}
      />
      <TitledRoute
        component={NFApplyForAssistanceAnonymousExitWarning}
        exact
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION.EXIT_WARNING}/:formId`}
        title={routeTitles.nfApplyForAssistanceAnonymousExitWarning}
      />
      <TitledRoute
        component={NFApplyForAssistanceAnonymousSignAndSubmit}
        exact
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION.SIGN_AND_SUBMIT}/:formId`}
        title={routeTitles.nfApplyForAssistanceAnonymousSignAndSubmit}
      />
      <TitledRoute
        component={NFApplyForAssistanceAnonymousConfirmation}
        exact
        path={`${paths.NF_APPLY_FOR_ASSISTANCE_ANONYMOUS_MOTIVATION.CONFIRMATION}/:formId`}
        title={routeTitles.nfApplyForAssistanceAnonymousConfirmation}
      />
      {/* --Anonymousflow ends-- */}

      <TitledRoute
        component={NFDisasterAssistance}
        exact
        path={paths.NFDISASTER_APPLY}
        title={routeTitles.nfDisasterAssistance}
      />
      {/*
      <TitledRoute
        component={NFApplyForCovidTestingOverview}
        exact
        path={paths.NF_APPLY_FOR_COVID_TESTING_MOTIVATION.OVERVIEW}
        title={routeTitles.nfApplyForCovidTestingOverview}
      />
      <TitledRoute
        component={NFApplyForCovidTestingForm}
        exact
        isIEG
        path={`${paths.NF_APPLY_FOR_COVID_TESTING_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfApplyForCovidTestingForm}
      />
      <TitledRoute
        component={NFApplyForCovidTestingExitWarning}
        exact
        path={`${paths.NF_APPLY_FOR_COVID_TESTING_MOTIVATION.EXIT_WARNING}/:formId`}
        title={routeTitles.nfApplyForCovidTestingExitWarning}
      />
      <TitledRoute
        component={NFApplyForCovidTestingSignAndSubmit}
        exact
        path={`${paths.NF_APPLY_FOR_COVID_TESTING_MOTIVATION.SIGN_AND_SUBMIT}/:formId`}
        title={routeTitles.nfApplyForCovidTestingSignAndSubmit}
      />
      <TitledRoute
        component={NFApplyForCovidTestingConfirmation}
        exact
        path={`${paths.NF_APPLY_FOR_COVID_TESTING_MOTIVATION.CONFIRMATION}/:formId`}
        title={routeTitles.nfApplyForCovidTestingConfirmation}
      />*/}

      <TitledRoute
        component={NFApplyForMedicaidExpansionOverview}
        exact
        path={paths.NF_APPLY_FOR_MXP_MOTIVATION.OVERVIEW}
        title={routeTitles.nfApplyForMedicaidExpansionOverview}
      />
      {/* --Presumptive start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFPresumptiveForm}
        exact
        isIEG
        path={`${paths.NF_PRESUMPTIVE_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfPresumptiveForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFPresumptiveConfirmation}
        exact
        path={`${paths.NF_PRESUMPTIVE_MOTIVATION.CONFIRMATION}/:formId`}
        title={routeTitles.nfPresumptiveConfirmation}
      />
      {/* --Presumptive end-- */}
      {/* --Deemed New Born start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFDeemedNewBornForm}
        exact
        isIEG
        path={`${paths.NF_DEEMED_NEW_BORN_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfDeemedNewbornForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.STANDARD,
          Authentication.USER_TYPES.LINKED
        ]}
        component={NFDeemedNewBornConfirmation}
        exact
        path={`${paths.NF_DEEMED_NEW_BORN_MOTIVATION.CONFIRMATION}/:formId`}
        title={routeTitles.nfDeemedNewbornConfirmation}
      />
      <TitledRoute
        component={NFDeemedNewBornSignAndSubmit}
        exact
        path={`${paths.NF_DEEMED_NEW_BORN_MOTIVATION.SIGN_AND_SUBMIT}/:formId`}
        title={routeTitles.nfDeemedNewbornSignAndSubmit}
      />
      {/* --Deemed New Born end-- */}
      {/* --COC starts-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFMyInformationComponent}
        exact
        path={paths.MY_INFO}
        title={routeTitles.nfMyInformation}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFUpdateMyInformationComponent}
        exact
        path={`${paths.UPDATE_INFO}/:updateType`}
        title={routeTitles.nfCOCOverview}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFFNSorSNAPCOCComponent}
        exact
        path={paths.UPDATE_INFO_FNS_OR_SNAP_COC}
        title={routeTitles.nfFnsOrSnapCOC}
      />
      {/*---Intermediate COC start here---*/}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFIntermediateCOCComponent}
        exact
        path={paths.NF_INTERMEDIATE_COC_OVERVIEW}
        title={routeTitles.nfIntermediateCOCOverview}
      /> 
      {/*---Intermediate COC ends here---*/}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFCOCForm}
        exact
        path={`${paths.NF_COC_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfCOCForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFCOCSignAndSubmit}
        exact
        path={`${paths.NF_COC_MOTIVATION.SIGN_AND_SUBMIT}/:motivationID`}
        title={routeTitles.nfCOCSignAndSubmit}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFCOCConfirmation}
        exact
        path={`${paths.NF_COC_MOTIVATION.CONFIRMATION}/:motivationID`}
        title={routeTitles.nfCOCConfirmation}
      />
      {/* --COC end-- */}

      {/* --FNS/SNAP Recert starts-- */}  
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFFnsOrSnapRecertComponent}
        exact
        path={`${paths.NF_FNS_OR_SNAP_BENEFITS}/:updateType`}
        title={routeTitles.nfRenewFnsOrSnapBenefits}
      />
       <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={nfRenewFnsOrSnapForm}
        exact
        path={`${paths.NF_FNS_OR_SNAP_RECERT_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfRenewFnsOrSnapForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={nfRenewFnsOrSnapSignAndSubmit}
        exact
        path={`${paths.NF_FNS_OR_SNAP_RECERT_MOTIVATION.SIGN_AND_SUBMIT}/:cocInstanceID`}
        title={routeTitles.nfRenewFnsOrSnapSignAndSubmit}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={nfRenewFnsOrSnapConfirmation}
        exact
        path={`${paths.NF_FNS_OR_SNAP_RECERT_MOTIVATION.CONFIRMATION}/:cocInstanceID`}
        title={routeTitles.nfRenewFnsOrSnapConfirmation}
      />
      {/* --FNS/SNAP Recert end-- */}
      
      {/* --SNAP COC Starts-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSnapCOCForm}
        exact
        path={`${paths.NF_SNAP_COC_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfSnapCOCForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSnapCOCSignAndSubmit}
        exact
        path={`${paths.NF_SNAP_COC_MOTIVATION.SIGN_AND_SUBMIT}/:cocInstanceID`}
        title={routeTitles.nfSnapCOCSignAndSubmit}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSnapCOCConfirmation}
        exact
        path={`${paths.NF_SNAP_COC_MOTIVATION.CONFIRMATION}/:cocInstanceID`}
        title={routeTitles.nfSnapCOCConfirmation}
      />
      {/* --SNAP COC Ends-- */}

      {/* --FNS COC Starts-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFFNSCOCForm}
        exact
        path={`${paths.NF_FNS_COC_MOTIVATION.FORM}/:formId`}
        title={routeTitles.nfFNSCOCForm}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFFNSCOCSignAndSubmit}
        exact
        path={`${paths.NF_FNS_COC_MOTIVATION.SIGN_AND_SUBMIT}/:cocInstanceID`}
        title={routeTitles.nfFNSCOCSignAndSubmit}
      />
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFFNSCOCConfirmation}
        exact
        path={`${paths.NF_FNS_COC_MOTIVATION.CONFIRMATION}/:cocInstanceID`}
        title={routeTitles.nfFNSCOCConfirmation}
      /> 
      {/* --FNS COC here-- */}
      
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFApplicationPendingComponent}
        exact
        path={paths.NF_APPLICATION_PENDING}
        title={routeTitles.nfApplicationPending}
      />
      {/* --YourApplications start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFYourApplications}
        exact
        path={paths.NFYOURAPPLICATIONS}
        title={routeTitles.nfYourapplications}
      />
      {/* --YourApplications end-- */}
      {/* --YourReferrals start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFYourReferrals}
        exact
        path={paths.NFYOURREFERRALS}
        title={routeTitles.nfYourreferrals}
      />
      {/* --YourReferrals end-- */}
      {/* --YourArchived start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFArchivedReferrals}
        exact
        path={paths.NFARCHIVEDREFERRALS}
        title={routeTitles.nfArchivedreferrals}
      />
      {/* --YourArchived end-- */}
      {/* --SecureMessages start-- */}
      <AuthenticatedRoute
        authUserTypes={[
          Authentication.USER_TYPES.LINKED,
          Authentication.USER_TYPES.STANDARD
        ]}
        component={NFSecureMessages}
        exact
        path={paths.NFSECUREMESSAGES}
        title={routeTitles.nfSecureMessages}
      />
      {/* --SecureMessages end-- */}
      {/* UA Routes */}
      <UARoutes />
    </Switch>
  </>
);
