/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
} from './actionTypes';

/**
 * Updates the details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the indicator of creation of details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationSetupAndStartErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfMotivationApplyForStandaloneRidpReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP);

/**
 * Updates the indicator of creation of details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfMotivationApplyForStandaloneRidpReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfMotivationApplyForStandaloneRidpErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_MOTIVATION_APPLY_FOR_STANDALONE_RIDP);

/**
 * Updates the details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationMotivationTypeByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationFinishStandaloneRidpReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP);

/**
 * Updates the indicator of creation of details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationFinishStandaloneRidpReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationFinishStandaloneRidpErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_FINISH_STANDALONE_RIDP);

/**
 * Updates the details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const deleteNfMotivationByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFMotivationStandaloneRDIP' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingDeleteNfMotivationByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const deleteNfMotivationByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

const reducers = combineReducers({
  postMotivationSetupAndStart: postMotivationSetupAndStartReducer,

  isFetchingPostMotivationSetupAndStart: isFetchingPostMotivationSetupAndStartReducer,
  postMotivationSetupAndStartError: postMotivationSetupAndStartErrorReducer,

  fetchNfMotivationApplyForStandaloneRidp: fetchNfMotivationApplyForStandaloneRidpReducer,

  isFetchingFetchNfMotivationApplyForStandaloneRidp: isFetchingFetchNfMotivationApplyForStandaloneRidpReducer,
  fetchNfMotivationApplyForStandaloneRidpError: fetchNfMotivationApplyForStandaloneRidpErrorReducer,

  getNfMotivationMotivationTypeByIegExecutionID: getNfMotivationMotivationTypeByIegExecutionIDReducer,

  isFetchingGetNfMotivationMotivationTypeByIegExecutionID: isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer,
  getNfMotivationMotivationTypeByIegExecutionIDError: getNfMotivationMotivationTypeByIegExecutionIDErrorReducer,

  postNfMotivationFinishStandaloneRidp: postNfMotivationFinishStandaloneRidpReducer,

  isFetchingPostNfMotivationFinishStandaloneRidp: isFetchingPostNfMotivationFinishStandaloneRidpReducer,
  postNfMotivationFinishStandaloneRidpError: postNfMotivationFinishStandaloneRidpErrorReducer,

  deleteNfMotivationByIegExecutionID: deleteNfMotivationByIegExecutionIDReducer,

  isFetchingDeleteNfMotivationByIegExecutionID: isFetchingDeleteNfMotivationByIegExecutionIDReducer,
  deleteNfMotivationByIegExecutionIDError: deleteNfMotivationByIegExecutionIDErrorReducer,
});

export default { reducers };
