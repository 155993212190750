/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFSecuredMessages.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFSECUREDMESSAGES/FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES`
);

const POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSECUREDMESSAGES/POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS`
);

export {
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS,
};
