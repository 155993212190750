/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFSNAPRecert.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_NF_COC_START_COC_SCRIPT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSNAPRECERT/POST_NF_COC_START_COC_SCRIPT`
);

const FETCH_NF_COC_APPLY_FOR_SNAP_RECERT = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFSNAPRECERT/FETCH_NF_COC_APPLY_FOR_SNAP_RECERT`
);

const POST_NF_COC_FINISH_COC_RECERT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSNAPRECERT/POST_NF_COC_FINISH_COC_RECERT`
);

const POST_NF_COC_SIGNANDSUBMIT_COC_RECERT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSNAPRECERT/POST_NF_COC_SIGNANDSUBMIT_COC_RECERT`
);

const GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFSNAPRECERT/GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID`
);

const POST_NF_MOTIVATION_SETUP_START_MOTIVATION = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFSNAPRECERT/POST_NF_MOTIVATION_SETUP_START_MOTIVATION`
);

const FETCH_NF_COC_LIST_SUBMITTED_RENEWALS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFSNAPRECERT/FETCH_NF_COC_LIST_SUBMITTED_RENEWALS`
);

export {
  POST_NF_COC_START_COC_SCRIPT,
  FETCH_NF_COC_APPLY_FOR_SNAP_RECERT,
  POST_NF_COC_FINISH_COC_RECERT,
  POST_NF_COC_SIGNANDSUBMIT_COC_RECERT,
  GET_NF_COC_COC_RECERT_CONFIRMATION_DETAILS_BY_COC_INSTANCE_ID,
  POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
  FETCH_NF_COC_LIST_SUBMITTED_RENEWALS,
};
