/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_UNIFIED,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
  FETCH_DRAFT_APPLICATIONS,
  FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP,
  GET_NF_MOTIVATION_ESIGNATURE,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
  GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS,
  POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
  POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST,
  FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP,
  POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
  POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME,
} from './actionTypes';

import {
  postMotivationSetupAndStartUtil,
  fetchNfMotivationApplyForUnifiedUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishUnifiedUtil,
  deleteNfMotivationByIegExecutionIDUtil,
  fetchDraftApplicationsUtil,
  fetchNfCitizenWorkspaceListExistingAppUtil,
  getNfMotivationEsignatureUtil,
  postNfMotivationSignAndSubmitUtil,
  getNfMotivationMotivationDetailsByIegExecutionIDUtil,
  getNfMotivationConfirmationDetailsByIegExecutionIDUtil,
  getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsUtil,
  postSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil,
  postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestUtil,
  fetchNfCitizenWorkspaceListExistingCocAppUtil,
  postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil,
  postNfMotivationStoreSaveAndExitPageNameUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFUnifiedMotivation slice of the store.
 *
 * @namespace NFUnifiedMotivationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postMotivationSetupAndStart = (dispatch, newUAMotivationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationSetupAndStartUtil.bind(null, newUAMotivationType),
      POST_MOTIVATION_SETUP_AND_START,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostMotivationSetupAndStart = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_SETUP_AND_START.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostMotivationSetupAndStartError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_SETUP_AND_START.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/apply_for_unified
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static fetchNfMotivationApplyForUnified = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfMotivationApplyForUnifiedUtil,
      FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfMotivationApplyForUnified.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchNfMotivationApplyForUnified = (dispatch, data) => {
    const json = {
      type: FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfMotivationApplyForUnified.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchNfMotivationApplyForUnifiedError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static getNfMotivationMotivationTypeByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationMotivationTypeByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/finish_unified
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postNfMotivationFinishUnified = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationFinishUnifiedUtil.bind(null, newUAMotivationIEGKey),
      POST_NF_MOTIVATION_FINISH_UNIFIED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationFinishUnified.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfMotivationFinishUnified = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_FINISH_UNIFIED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationFinishUnified.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfMotivationFinishUnifiedError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_FINISH_UNIFIED.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static deleteNfMotivationByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      deleteNfMotivationByIegExecutionIDUtil.bind(null, iegExecutionID),
      DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to deleteNfMotivationByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetDeleteNfMotivationByIegExecutionID = (dispatch, data) => {
    const json = {
      type: DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to deleteNfMotivationByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetDeleteNfMotivationByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/draft_applications
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static fetchDraftApplications = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchDraftApplicationsUtil,
      FETCH_DRAFT_APPLICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchDraftApplications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchDraftApplications = (dispatch, data) => {
    const json = {
      type: FETCH_DRAFT_APPLICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchDraftApplications.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchDraftApplicationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_DRAFT_APPLICATIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_workspace/list_existing_app
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static fetchNfCitizenWorkspaceListExistingApp = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCitizenWorkspaceListExistingAppUtil,
      FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCitizenWorkspaceListExistingApp.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchNfCitizenWorkspaceListExistingApp = (dispatch, data) => {
    const json = {
      type: FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCitizenWorkspaceListExistingApp.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchNfCitizenWorkspaceListExistingAppError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/{iegExecutionID}/esignature
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static getNfMotivationEsignature = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationEsignatureUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_ESIGNATURE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationEsignature.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationEsignature = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_ESIGNATURE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationEsignature.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationEsignatureError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_NF_MOTIVATION_ESIGNATURE.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/sign_and_submit
   *
   * @param {NewNFCESignAndSubmit} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postNfMotivationSignAndSubmit = (dispatch, newNFCESignAndSubmit, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationSignAndSubmitUtil.bind(null, newNFCESignAndSubmit),
      POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationSignAndSubmit.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfMotivationSignAndSubmit = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_SIGN_AND_SUBMIT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationSignAndSubmit.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfMotivationSignAndSubmitError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_SIGN_AND_SUBMIT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/motivation_details/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static getNfMotivationMotivationDetailsByIegExecutionID = (
    dispatch,
    iegExecutionID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationMotivationDetailsByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationMotivationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationMotivationDetailsByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationMotivationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationMotivationDetailsByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/confirmation_details/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static getNfMotivationConfirmationDetailsByIegExecutionID = (
    dispatch,
    iegExecutionID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationConfirmationDetailsByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationConfirmationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationConfirmationDetailsByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationConfirmationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetNfMotivationConfirmationDetailsByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request_reasons
   *
   * @param {string} application_id.
   * @param {string} application_program_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons = (
    dispatch,
    application_id,
    application_program_id,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsUtil.bind(
        null,
        application_id,
        application_program_id
      ),

      GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons = (
    dispatch,
    data
  ) => {
    const json = {
      type:
        GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
   *
   * @param {string} application_id.
   * @param {string} application_program_id.
   * @param {NewUAWithdrawalRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postSubmittedApplicationsApplicationProgramsWithdrawalRequest = (
    dispatch,
    application_id,
    application_program_id,
    newUAWithdrawalRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil.bind(
        null,
        application_id,
        application_program_id,
        newUAWithdrawalRequest
      ),
      POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postSubmittedApplicationsApplicationProgramsWithdrawalRequest.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostSubmittedApplicationsApplicationProgramsWithdrawalRequest = (dispatch, data) => {
    const json = {
      type: POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postSubmittedApplicationsApplicationProgramsWithdrawalRequest.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostSubmittedApplicationsApplicationProgramsWithdrawalRequestError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/submitted_applications/application_programs/withdrawal_coc_request
   *
   * @param {NewNFDenyWithdrawApplicantDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest = (
    dispatch,
    newNFDenyWithdrawApplicantDetails,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestUtil.bind(
        null,
        newNFDenyWithdrawApplicantDetails
      ),
      POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequest = (
    dispatch,
    data
  ) => {
    const json = {
      type: POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequestError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_workspace/list_existing_coc_app
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static fetchNfCitizenWorkspaceListExistingCocApp = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCitizenWorkspaceListExistingCocAppUtil,
      FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCitizenWorkspaceListExistingCocApp.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchNfCitizenWorkspaceListExistingCocApp = (dispatch, data) => {
    const json = {
      type: FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCitizenWorkspaceListExistingCocApp.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetFetchNfCitizenWorkspaceListExistingCocAppError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_submitted_applications/{application_id}/application_programs/{application_program_id}/withdrawal_request
   *
   * @param {string} application_id.
   * @param {string} application_program_id.
   * @param {NewUAWithdrawalRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest = (
    dispatch,
    application_id,
    application_program_id,
    newUAWithdrawalRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestUtil.bind(
        null,
        application_id,
        application_program_id,
        newUAWithdrawalRequest
      ),
      POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequest = (
    dispatch,
    data
  ) => {
    const json = {
      type: POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequestError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/store_save_and_exit_page_name
   *
   * @param {NewNFSaveAndExitKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static postNfMotivationStoreSaveAndExitPageName = (dispatch, newNFSaveAndExitKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationStoreSaveAndExitPageNameUtil.bind(null, newNFSaveAndExitKey),
      POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationStoreSaveAndExitPageName.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfMotivationStoreSaveAndExitPageName = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationStoreSaveAndExitPageName.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFUnifiedMotivationActions
   */
  static resetPostNfMotivationStoreSaveAndExitPageNameError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME.failureAction
    );
  };
}
