/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFDeemedNewBornMotivation' store object.
 * @namespace NFDeemedNewBornMotivationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFDeemedNewBornMotivation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornGetDeemedNewBornAllowed = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornGetDeemedNewBornAllowed.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingFetchNfDeemedNewBornGetDeemedNewBornAllowed = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDeemedNewBornGetDeemedNewBornAllowed.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornGetDeemedNewBornAllowedError = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornGetDeemedNewBornAllowedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfMotivationDeemedNewBorn = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationDeemedNewBorn.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingFetchNfMotivationDeemedNewBorn = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfMotivationDeemedNewBorn.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfMotivationDeemedNewBornError = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationDeemedNewBornError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStart.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingPostMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationSetupAndStart.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postMotivationSetupAndStartError = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStartError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingGetNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationMotivationTypeByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postNfMotivationFinishMotivation = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishMotivation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingPostNfMotivationFinishMotivation = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationFinishMotivation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postNfMotivationFinishMotivationError = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishMotivationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static getNfMotivationConfirmationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationConfirmationDetailsByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingGetNfMotivationConfirmationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationConfirmationDetailsByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static getNfMotivationConfirmationDetailsByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationConfirmationDetailsByIegExecutionIDError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static getNfMotivationMotivationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationDetailsByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingGetNfMotivationMotivationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationMotivationDetailsByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static getNfMotivationMotivationDetailsByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationDetailsByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postNfMotivationSignAndSubmit = state =>
    state[selectors.moduleIdentifier].postNfMotivationSignAndSubmit.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingPostNfMotivationSignAndSubmit = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationSignAndSubmit.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postNfMotivationSignAndSubmitError = state =>
    state[selectors.moduleIdentifier].postNfMotivationSignAndSubmitError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static deleteNfMotivationByIegExecutionID = state =>
    state[selectors.moduleIdentifier].deleteNfMotivationByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingDeleteNfMotivationByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingDeleteNfMotivationByIegExecutionID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static deleteNfMotivationByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].deleteNfMotivationByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornIsAnbLoggedinUserLinked = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornIsAnbLoggedinUserLinked.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingFetchNfDeemedNewBornIsAnbLoggedinUserLinked = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDeemedNewBornIsAnbLoggedinUserLinked.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornIsAnbLoggedinUserLinkedError = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornIsAnbLoggedinUserLinkedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postNfDeemedNewBornArchiveProviderAnbApplications = state =>
    state[selectors.moduleIdentifier].postNfDeemedNewBornArchiveProviderAnbApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingPostNfDeemedNewBornArchiveProviderAnbApplications = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfDeemedNewBornArchiveProviderAnbApplications
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static postNfDeemedNewBornArchiveProviderAnbApplicationsError = state =>
    state[selectors.moduleIdentifier].postNfDeemedNewBornArchiveProviderAnbApplicationsError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornSearchArchivedAnbReferrals = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornSearchArchivedAnbReferrals.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingFetchNfDeemedNewBornSearchArchivedAnbReferrals = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDeemedNewBornSearchArchivedAnbReferrals
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornSearchArchivedAnbReferralsError = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornSearchArchivedAnbReferralsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornSearchAnbReferrals = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornSearchAnbReferrals.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingFetchNfDeemedNewBornSearchAnbReferrals = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDeemedNewBornSearchAnbReferrals.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornSearchAnbReferralsError = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornSearchAnbReferralsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornListAnbUserFacilities = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornListAnbUserFacilities.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static isFetchingFetchNfDeemedNewBornListAnbUserFacilities = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfDeemedNewBornListAnbUserFacilities.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDeemedNewBornMotivationSelectors
   */
  static fetchNfDeemedNewBornListAnbUserFacilitiesError = state =>
    state[selectors.moduleIdentifier].fetchNfDeemedNewBornListAnbUserFacilitiesError.payload;
}
