/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFPendingUnifiedProgramsAppExistForUser
 *
 * @class NFPendingUnifiedProgramsAppExistForUser
 */
class NFPendingUnifiedProgramsAppExistForUser {
  /**
   * Creates an instance of NFPendingUnifiedProgramsAppExistForUser.
   * @param {*} json
   * @memberof NFPendingUnifiedProgramsAppExistForUser
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.doesPendingMAGIAppExist = json.doesPendingMAGIAppExist;

      // boolean
      this.doesPendingFNSAppExist = json.doesPendingFNSAppExist;

      // boolean
      this.doesPendingABDAppExist = json.doesPendingABDAppExist;

      // boolean
      this.doesPendingSNAPAppExist = json.doesPendingSNAPAppExist;
    }
  }
}

/**
 * Models the NFProvisionedAuthRep
 *
 * @class NFProvisionedAuthRep
 */
class NFProvisionedAuthRep {
  /**
   * Creates an instance of NFProvisionedAuthRep.
   * @param {*} json
   * @memberof NFProvisionedAuthRep
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isProvisionedAuthRep = json.isProvisionedAuthRep;
    }
  }
}

export default {
  NFPendingUnifiedProgramsAppExistForUser,

  NFProvisionedAuthRep,
};
