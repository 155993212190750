/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFEnergy.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_ENERGY_IS_ENERGY_ACTIVE = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFENERGY/FETCH_NF_ENERGY_IS_ENERGY_ACTIVE`
);

export { FETCH_NF_ENERGY_IS_ENERGY_ACTIVE };
