/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the UAProgramPdfForm
 *
 * @class UAProgramPdfForm
 */
class UAProgramPdfForm {
  /**
   * Creates an instance of UAProgramPdfForm.
   * @param {*} json
   * @memberof UAProgramPdfForm
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pdfFormId = json.pdfFormId;
    }
  }
}

/**
 * Models the UAProgram
 *
 * @class UAProgram
 */
class UAProgram {
  /**
   * Creates an instance of UAProgram.
   * @param {*} json
   * @memberof UAProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.associatedPendingApplicationId = json.associatedPendingApplicationId;

      // string
      this.icon = json.icon;

      // array - UAProgramPdfForm
      if (json.pdfFormIds) {
        this.pdfFormIds = json.pdfFormIds.map(object => new UAProgramPdfForm(object));
      } else if (json.constructor === Array) {
        this.pdfFormIds = json.map(object => new UAProgramPdfForm(object));
      }
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the UAWithdrawalReason
 *
 * @class UAWithdrawalReason
 */
class UAWithdrawalReason {
  /**
   * Creates an instance of UAWithdrawalReason.
   * @param {*} json
   * @memberof UAWithdrawalReason
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.withdrawalReason) {
        this.withdrawalReason = new CodeTableType(json.withdrawalReason);
      }
    }
  }
}

/**
 * Models the UAIntakeApplicationType
 *
 * @class UAIntakeApplicationType
 */
class UAIntakeApplicationType {
  /**
   * Creates an instance of UAIntakeApplicationType.
   * @param {*} json
   * @memberof UAIntakeApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.iegScriptId = json.iegScriptId;

      // boolean
      this.individualProgramSelectionAllowed = json.individualProgramSelectionAllowed;

      // string
      this.iegSubmissionScriptId = json.iegSubmissionScriptId;

      // string
      this.datastoreSchema = json.datastoreSchema;

      // string
      this.name = json.name;

      // string
      this.summary = json.summary;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // boolean
      this.canApplyFor = json.canApplyFor;

      // string
      this.pdfFormId = json.pdfFormId;

      // string
      this.associatedDraftApplicationId = json.associatedDraftApplicationId;

      // string
      this.url = json.url;

      // boolean
      this.submitOnCompletionInd = json.submitOnCompletionInd;

      // string
      this.icon = json.icon;

      // array - UAProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UAProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UAProgram(object));
      }

      // array - UAWithdrawalReason
      if (json.allowedWithdrawalReasons) {
        this.allowedWithdrawalReasons = json.allowedWithdrawalReasons.map(
          object => new UAWithdrawalReason(object)
        );
      } else if (json.constructor === Array) {
        this.allowedWithdrawalReasons = json.map(object => new UAWithdrawalReason(object));
      }
    }
  }
}

/**
 * Models the UAScreeningType
 *
 * @class UAScreeningType
 */
class UAScreeningType {
  /**
   * Creates an instance of UAScreeningType.
   * @param {*} json
   * @memberof UAScreeningType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.summary = json.summary;

      // string
      this.url = json.url;

      // string
      this.howToApply = json.howToApply;

      // string
      this.amIEligibile = json.amIEligibile;

      // string
      this.iegFilterScreeningSchemaName = json.iegFilterScreeningSchemaName;

      // string
      this.iegFilterScreeningScriptId = json.iegFilterScreeningScriptId;

      // string
      this.iegScreeningSchemaName = json.iegScreeningSchemaName;

      // string
      this.iegScreeningScriptId = json.iegScreeningScriptId;

      // boolean
      this.programSelectionInd = json.programSelectionInd;

      // boolean
      this.rescreenInd = json.rescreenInd;

      // string
      this.screeningTypeId = json.screeningTypeId;

      // string
      this.icon = json.icon;

      // array - UAProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new UAProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new UAProgram(object));
      }
    }
  }
}

/**
 * Models the UAOnlineCategory
 *
 * @class UAOnlineCategory
 */
class UAOnlineCategory {
  /**
   * Creates an instance of UAOnlineCategory.
   * @param {*} json
   * @memberof UAOnlineCategory
   */
  constructor(json = null) {
    if (json) {
      // string
      this.online_category_id = json.online_category_id;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.url = json.url;

      // array - UAIntakeApplicationType
      if (json.intakeApplicationTypes) {
        this.intakeApplicationTypes = json.intakeApplicationTypes.map(
          object => new UAIntakeApplicationType(object)
        );
      } else if (json.constructor === Array) {
        this.intakeApplicationTypes = json.map(object => new UAIntakeApplicationType(object));
      }

      // array - UAScreeningType
      if (json.onlineScreeningTypes) {
        this.onlineScreeningTypes = json.onlineScreeningTypes.map(
          object => new UAScreeningType(object)
        );
      } else if (json.constructor === Array) {
        this.onlineScreeningTypes = json.map(object => new UAScreeningType(object));
      }
    }
  }
}

/**
 * Models the UAOnlineCategoryList
 *
 * @class UAOnlineCategoryList
 */
class UAOnlineCategoryList {
  /**
   * Creates an instance of UAOnlineCategoryList.
   * @param {*} json
   * @memberof UAOnlineCategoryList
   */
  constructor(json = null) {
    if (json) {
      // array - UAOnlineCategory
      if (json.data) {
        this.data = json.data.map(object => new UAOnlineCategory(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UAOnlineCategory(object));
      }
    }
  }
}

/**
 * Models the NewUAOnlineApplicationProgram
 *
 * @class NewUAOnlineApplicationProgram
 */
class NewUAOnlineApplicationProgram {
  /**
   * Creates an instance of NewUAOnlineApplicationProgram.
   * @param {*} json
   * @memberof NewUAOnlineApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.programId = json.programId;
    }
  }
}

/**
 * Models the NewUAOnlineApplicationType
 *
 * @class NewUAOnlineApplicationType
 */
class NewUAOnlineApplicationType {
  /**
   * Creates an instance of NewUAOnlineApplicationType.
   * @param {*} json
   * @memberof NewUAOnlineApplicationType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_type_id = json.application_type_id;

      // string
      this.screening_form_id = json.screening_form_id;

      // array - NewUAOnlineApplicationProgram
      if (json.programs) {
        this.programs = json.programs.map(object => new NewUAOnlineApplicationProgram(object));
      } else if (json.constructor === Array) {
        this.programs = json.map(object => new NewUAOnlineApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the NewUAApplicationFormIdentifierResponse
 *
 * @class NewUAApplicationFormIdentifierResponse
 */
class NewUAApplicationFormIdentifierResponse {
  /**
   * Creates an instance of NewUAApplicationFormIdentifierResponse.
   * @param {*} json
   * @memberof NewUAApplicationFormIdentifierResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // boolean
      this.isPartialApplication = json.isPartialApplication;
    }
  }
}

/**
 * Models the UAApplicationProgramStatus
 *
 * @class UAApplicationProgramStatus
 */
class UAApplicationProgramStatus {
  /**
   * Creates an instance of UAApplicationProgramStatus.
   * @param {*} json
   * @memberof UAApplicationProgramStatus
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.hasPendingWithdrawalRequest = json.hasPendingWithdrawalRequest;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }

      // string
      this.withdrawalRequestStatusMessage = json.withdrawalRequestStatusMessage;

      // boolean
      this.canBeWithdrawn = json.canBeWithdrawn;
    }
  }
}

/**
 * Models the UASubmittedApplicationProgram
 *
 * @class UASubmittedApplicationProgram
 */
class UASubmittedApplicationProgram {
  /**
   * Creates an instance of UASubmittedApplicationProgram.
   * @param {*} json
   * @memberof UASubmittedApplicationProgram
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.application_program_id = json.application_program_id;

      // string
      this.case_id = json.case_id;

      // object - UAApplicationProgramStatus
      if (json.programStatusDetails) {
        this.programStatusDetails = new UAApplicationProgramStatus(json.programStatusDetails);
      }
    }
  }
}

/**
 * Models the UASubmittedApplication
 *
 * @class UASubmittedApplication
 */
class UASubmittedApplication {
  /**
   * Creates an instance of UASubmittedApplication.
   * @param {*} json
   * @memberof UASubmittedApplication
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_id = json.application_id;

      // string
      this.submittedOn = json.submittedOn;

      // string
      this.name = json.name;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }

      // array - UASubmittedApplicationProgram
      if (json.applicationPrograms) {
        this.applicationPrograms = json.applicationPrograms.map(
          object => new UASubmittedApplicationProgram(object)
        );
      } else if (json.constructor === Array) {
        this.applicationPrograms = json.map(object => new UASubmittedApplicationProgram(object));
      }
    }
  }
}

/**
 * Models the UASubmittedApplicationList
 *
 * @class UASubmittedApplicationList
 */
class UASubmittedApplicationList {
  /**
   * Creates an instance of UASubmittedApplicationList.
   * @param {*} json
   * @memberof UASubmittedApplicationList
   */
  constructor(json = null) {
    if (json) {
      // array - UASubmittedApplication
      if (json.data) {
        this.data = json.data.map(object => new UASubmittedApplication(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new UASubmittedApplication(object));
      }
    }
  }
}

/**
 * Models the NewUAApplicationCreationDetails
 *
 * @class NewUAApplicationCreationDetails
 */
class NewUAApplicationCreationDetails {
  /**
   * Creates an instance of NewUAApplicationCreationDetails.
   * @param {*} json
   * @memberof NewUAApplicationCreationDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_form_id = json.application_form_id;

      // string
      this.submission_form_id = json.submission_form_id;
    }
  }
}

/**
 * Models the NewUAIntakeApplicationIdentifierResponse
 *
 * @class NewUAIntakeApplicationIdentifierResponse
 */
class NewUAIntakeApplicationIdentifierResponse {
  /**
   * Creates an instance of NewUAIntakeApplicationIdentifierResponse.
   * @param {*} json
   * @memberof NewUAIntakeApplicationIdentifierResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.application_id = json.application_id;

      // string
      this.applicationReference = json.applicationReference;
    }
  }
}

/**
 * Models the Frequency
 *
 * @class Frequency
 */
class Frequency {
  /**
   * Creates an instance of Frequency.
   * @param {*} json
   * @memberof Frequency
   */
  constructor(json = null) {
    if (json) {
      // string
      this.value = json.value;

      // string
      this.description = json.description;
    }
  }
}

/**
 * Models the ActivityConcerning
 *
 * @class ActivityConcerning
 */
class ActivityConcerning {
  /**
   * Creates an instance of ActivityConcerning.
   * @param {*} json
   * @memberof ActivityConcerning
   */
  constructor(json = null) {
    if (json) {
      // string
      this.fullName = json.fullName;

      // string
      this.concernRoleID = json.concernRoleID;
    }
  }
}

/**
 * Models the ActivityDuration
 *
 * @class ActivityDuration
 */
class ActivityDuration {
  /**
   * Creates an instance of ActivityDuration.
   * @param {*} json
   * @memberof ActivityDuration
   */
  constructor(json = null) {
    if (json) {
      // integer
      this.hours = json.hours;

      // integer
      this.minutes = json.minutes;
    }
  }
}

/**
 * Models the Address
 *
 * @class Address
 */
class Address {
  /**
   * Creates an instance of Address.
   * @param {*} json
   * @memberof Address
   */
  constructor(json = null) {
    if (json) {
      // string
      this.displayText = json.displayText;

      // object - CodeTableType
      if (json.addressType) {
        this.addressType = new CodeTableType(json.addressType);
      }
    }
  }
}

/**
 * Models the PhoneNumber
 *
 * @class PhoneNumber
 */
class PhoneNumber {
  /**
   * Creates an instance of PhoneNumber.
   * @param {*} json
   * @memberof PhoneNumber
   */
  constructor(json = null) {
    if (json) {
      // string
      this.countryCode = json.countryCode;

      // string
      this.areaCode = json.areaCode;

      // string
      this.number = json.number;

      // string
      this.extension = json.extension;
    }
  }
}

/**
 * Models the ActivityProvider
 *
 * @class ActivityProvider
 */
class ActivityProvider {
  /**
   * Creates an instance of ActivityProvider.
   * @param {*} json
   * @memberof ActivityProvider
   */
  constructor(json = null) {
    if (json) {
      // string
      this.name = json.name;

      // string
      this.email = json.email;

      // object - Address
      if (json.address) {
        this.address = new Address(json.address);
      }

      // object - PhoneNumber
      if (json.phoneNumber) {
        this.phoneNumber = new PhoneNumber(json.phoneNumber);
      }
    }
  }
}

/**
 * Models the AssignedTo
 *
 * @class AssignedTo
 */
class AssignedTo {
  /**
   * Creates an instance of AssignedTo.
   * @param {*} json
   * @memberof AssignedTo
   */
  constructor(json = null) {
    if (json) {
      // string
      this.fullName = json.fullName;

      // string
      this.concern_role_id = json.concern_role_id;

      // string
      this.username = json.username;
    }
  }
}

/**
 * Models the Activity
 *
 * @class Activity
 */
class Activity {
  /**
   * Creates an instance of Activity.
   * @param {*} json
   * @memberof Activity
   */
  constructor(json = null) {
    if (json) {
      // string
      this.outcome_plan_id = json.outcome_plan_id;

      // string
      this.name = json.name;

      // string
      this.reason = json.reason;

      // string
      this.startDate = json.startDate;

      // string
      this.endDate = json.endDate;

      // object - CodeTableType
      if (json.outcome) {
        this.outcome = new CodeTableType(json.outcome);
      }

      // string
      this.username = json.username;

      // string
      this.userFullName = json.userFullName;

      // string
      this.activity_id = json.activity_id;

      // object - CodeTableType
      if (json.activityType) {
        this.activityType = new CodeTableType(json.activityType);
      }

      // string
      this.status = json.status;

      // boolean
      this.completed = json.completed;

      // object - Frequency
      if (json.frequency) {
        this.frequency = new Frequency(json.frequency);
      }

      // string
      this.providerName = json.providerName;

      // array - ActivityConcerning
      if (json.concerning) {
        this.concerning = json.concerning.map(object => new ActivityConcerning(object));
      } else if (json.constructor === Array) {
        this.concerning = json.map(object => new ActivityConcerning(object));
      }

      // object - ActivityDuration
      if (json.duration) {
        this.duration = new ActivityDuration(json.duration);
      }

      // object - ActivityProvider
      if (json.provider) {
        this.provider = new ActivityProvider(json.provider);
      }

      // object - AssignedTo
      if (json.assignedTo) {
        this.assignedTo = new AssignedTo(json.assignedTo);
      }
    }
  }
}

/**
 * Models the ActivityList
 *
 * @class ActivityList
 */
class ActivityList {
  /**
   * Creates an instance of ActivityList.
   * @param {*} json
   * @memberof ActivityList
   */
  constructor(json = null) {
    if (json) {
      // array - Activity
      if (json.data) {
        this.data = json.data.map(object => new Activity(object));
      } else if (json.constructor === Array) {
        this.data = json.map(object => new Activity(object));
      }
    }
  }
}

/**
 * Models the UAOnlineAppealRequest
 *
 * @class UAOnlineAppealRequest
 */
class UAOnlineAppealRequest {
  /**
   * Creates an instance of UAOnlineAppealRequest.
   * @param {*} json
   * @memberof UAOnlineAppealRequest
   */
  constructor(json = null) {
    if (json) {
      // string
      this.primaryAppellantName = json.primaryAppellantName;

      // object - CodeTableType
      if (json.appealRequestStatus) {
        this.appealRequestStatus = new CodeTableType(json.appealRequestStatus);
      }

      // string
      this.submittedDateTime = json.submittedDateTime;

      // string
      this.onlineAppealRequestId = json.onlineAppealRequestId;
    }
  }
}

/**
 * Models the UAOnlineAppealRequests
 *
 * @class UAOnlineAppealRequests
 */
class UAOnlineAppealRequests {
  /**
   * Creates an instance of UAOnlineAppealRequests.
   * @param {*} json
   * @memberof UAOnlineAppealRequests
   */
  constructor(json = null) {
    if (json) {
      // array - UAOnlineAppealRequest
      if (json.requests) {
        this.requests = json.requests.map(object => new UAOnlineAppealRequest(object));
      } else if (json.constructor === Array) {
        this.requests = json.map(object => new UAOnlineAppealRequest(object));
      }
    }
  }
}

export default {
  UAProgramPdfForm,

  UAProgram,

  CodeTableType,

  UAWithdrawalReason,

  UAIntakeApplicationType,

  UAScreeningType,

  UAOnlineCategory,

  UAOnlineCategoryList,

  NewUAOnlineApplicationProgram,

  NewUAOnlineApplicationType,

  NewUAApplicationFormIdentifierResponse,

  UAApplicationProgramStatus,

  UASubmittedApplicationProgram,

  UASubmittedApplication,

  UASubmittedApplicationList,

  NewUAApplicationCreationDetails,

  NewUAIntakeApplicationIdentifierResponse,

  Frequency,

  ActivityConcerning,

  ActivityDuration,

  Address,

  PhoneNumber,

  ActivityProvider,

  AssignedTo,

  Activity,

  ActivityList,

  UAOnlineAppealRequest,

  UAOnlineAppealRequests,
};
