/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFMyProfileDetails
 *
 * @class NFMyProfileDetails
 */
class NFMyProfileDetails {
  /**
   * Creates an instance of NFMyProfileDetails.
   * @param {*} json
   * @memberof NFMyProfileDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.secondaryAddLinkInd = json.secondaryAddLinkInd;

      // boolean
      this.eNoticesInd = json.eNoticesInd;

      // boolean
      this.revokeAllInd = json.revokeAllInd;

      // string
      this.itemDescriptionText = json.itemDescriptionText;

      // string
      this.myInformation = json.myInformation;

      // boolean
      this.ncidLinkInd = json.ncidLinkInd;

      // boolean
      this.nameLinkInd = json.nameLinkInd;

      // boolean
      this.primaryUpdateInd = json.primaryUpdateInd;

      // boolean
      this.secondaryUpdateInd = json.secondaryUpdateInd;

      // boolean
      this.massCreateInd = json.massCreateInd;

      // boolean
      this.massEditInd = json.massEditInd;

      // string
      this.itemDescriptionTitle = json.itemDescriptionTitle;

      // boolean
      this.phonenumerInd = json.phonenumerInd;
    }
  }
}

/**
 * Models the NFMyProfileDetailsResponse
 *
 * @class NFMyProfileDetailsResponse
 */
class NFMyProfileDetailsResponse {
  /**
   * Creates an instance of NFMyProfileDetailsResponse.
   * @param {*} json
   * @memberof NFMyProfileDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;

      // boolean
      this.isSuccess = json.isSuccess;

      // array - NFMyProfileDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NFMyProfileDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NFMyProfileDetails(object));
      }
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the NewNFUpdateUserContactDetails
 *
 * @class NewNFUpdateUserContactDetails
 */
class NewNFUpdateUserContactDetails {
  /**
   * Creates an instance of NewNFUpdateUserContactDetails.
   * @param {*} json
   * @memberof NewNFUpdateUserContactDetails
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // string
      this.emailAddress = json.emailAddress;

      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.confirmationCode = json.confirmationCode;

      // string
      this.contactInformation = json.contactInformation;
    }
  }
}

/**
 * Models the NewNFRIDPResponseResponse
 *
 * @class NewNFRIDPResponseResponse
 */
class NewNFRIDPResponseResponse {
  /**
   * Creates an instance of NewNFRIDPResponseResponse.
   * @param {*} json
   * @memberof NewNFRIDPResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;
    }
  }
}

/**
 * Models the NewNFUserContactDetails
 *
 * @class NewNFUserContactDetails
 */
class NewNFUserContactDetails {
  /**
   * Creates an instance of NewNFUserContactDetails.
   * @param {*} json
   * @memberof NewNFUserContactDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;

      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // string
      this.emailAddress = json.emailAddress;

      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.playerID = json.playerID;
    }
  }
}

/**
 * Models the NewNFUserContactDetailsResponse
 *
 * @class NewNFUserContactDetailsResponse
 */
class NewNFUserContactDetailsResponse {
  /**
   * Creates an instance of NewNFUserContactDetailsResponse.
   * @param {*} json
   * @memberof NewNFUserContactDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userName = json.userName;

      // object - CodeTableType
      if (json.contactMethod) {
        this.contactMethod = new CodeTableType(json.contactMethod);
      }

      // string
      this.emailAddress = json.emailAddress;

      // string
      this.phoneNumber = json.phoneNumber;

      // string
      this.phoneAreaCode = json.phoneAreaCode;

      // string
      this.playerID = json.playerID;
    }
  }
}

/**
 * Models the CodeItem
 *
 * @class CodeItem
 */
class CodeItem {
  /**
   * Creates an instance of CodeItem.
   * @param {*} json
   * @memberof CodeItem
   */
  constructor(json = null) {
    if (json) {
      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // integer
      this.sortOrder = json.sortOrder;

      // ChildCodeTable
      if (json.childCodeTable) {
        this.childCodeTable = new CodeTable(json.childCodeTable);
      }
    }
  }
}

/**
 * Models the CodeTable
 *
 * @class CodeTable
 */
class CodeTable {
  /**
   * Creates an instance of CodeTable.
   * @param {*} json
   * @memberof CodeTable
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.defaultValue = json.defaultValue;

      // array - CodeItem
      if (json.codeItems) {
        this.codeItems = json.codeItems.map(object => new CodeItem(object));
      } else if (json.constructor === Array) {
        this.codeItems = json.map(object => new CodeItem(object));
      }
    }
  }
}

export default {
  NFMyProfileDetails,

  NFMyProfileDetailsResponse,

  CodeTableType,

  NewNFUpdateUserContactDetails,

  NewNFRIDPResponseResponse,

  NewNFUserContactDetails,

  NewNFUserContactDetailsResponse,

  CodeItem,

  CodeTable,
};
