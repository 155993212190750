/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED,
  FETCH_NF_MOTIVATION_DEEMED_NEW_BORN,
  POST_MOTIVATION_SETUP_AND_START,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_MOTIVATION,
  GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
  FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED,
  POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS,
  FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS,
  FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS,
  FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES,
} from './actionTypes';

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDeemedNewBornGetDeemedNewBornAllowedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDeemedNewBornGetDeemedNewBornAllowedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDeemedNewBornGetDeemedNewBornAllowedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfMotivationDeemedNewBornReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_MOTIVATION_DEEMED_NEW_BORN);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfMotivationDeemedNewBornReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_MOTIVATION_DEEMED_NEW_BORN);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfMotivationDeemedNewBornErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_MOTIVATION_DEEMED_NEW_BORN);

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationSetupAndStartErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationMotivationTypeByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationFinishMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_FINISH_MOTIVATION);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationFinishMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_FINISH_MOTIVATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationFinishMotivationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_FINISH_MOTIVATION);

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationConfirmationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationConfirmationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationConfirmationDetailsByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationMotivationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationMotivationDetailsByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationMotivationDetailsByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationSignAndSubmitReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationSignAndSubmitReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationSignAndSubmitErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT);

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const deleteNfMotivationByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingDeleteNfMotivationByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const deleteNfMotivationByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID);

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDeemedNewBornIsAnbLoggedinUserLinkedReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDeemedNewBornIsAnbLoggedinUserLinkedReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDeemedNewBornIsAnbLoggedinUserLinkedErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfDeemedNewBornArchiveProviderAnbApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS
  );

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfDeemedNewBornArchiveProviderAnbApplicationsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfDeemedNewBornArchiveProviderAnbApplicationsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDeemedNewBornSearchArchivedAnbReferralsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDeemedNewBornSearchArchivedAnbReferralsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDeemedNewBornSearchArchivedAnbReferralsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS
  );

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDeemedNewBornSearchAnbReferralsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDeemedNewBornSearchAnbReferralsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDeemedNewBornSearchAnbReferralsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS);

/**
 * Updates the details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfDeemedNewBornListAnbUserFacilitiesReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES);

/**
 * Updates the indicator of creation of details in the 'NFDeemedNewBornMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfDeemedNewBornListAnbUserFacilitiesReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfDeemedNewBornListAnbUserFacilitiesErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES);

const reducers = combineReducers({
  fetchNfDeemedNewBornGetDeemedNewBornAllowed: fetchNfDeemedNewBornGetDeemedNewBornAllowedReducer,

  isFetchingFetchNfDeemedNewBornGetDeemedNewBornAllowed: isFetchingFetchNfDeemedNewBornGetDeemedNewBornAllowedReducer,
  fetchNfDeemedNewBornGetDeemedNewBornAllowedError: fetchNfDeemedNewBornGetDeemedNewBornAllowedErrorReducer,

  fetchNfMotivationDeemedNewBorn: fetchNfMotivationDeemedNewBornReducer,

  isFetchingFetchNfMotivationDeemedNewBorn: isFetchingFetchNfMotivationDeemedNewBornReducer,
  fetchNfMotivationDeemedNewBornError: fetchNfMotivationDeemedNewBornErrorReducer,

  postMotivationSetupAndStart: postMotivationSetupAndStartReducer,

  isFetchingPostMotivationSetupAndStart: isFetchingPostMotivationSetupAndStartReducer,
  postMotivationSetupAndStartError: postMotivationSetupAndStartErrorReducer,

  getNfMotivationMotivationTypeByIegExecutionID: getNfMotivationMotivationTypeByIegExecutionIDReducer,

  isFetchingGetNfMotivationMotivationTypeByIegExecutionID: isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer,
  getNfMotivationMotivationTypeByIegExecutionIDError: getNfMotivationMotivationTypeByIegExecutionIDErrorReducer,

  postNfMotivationFinishMotivation: postNfMotivationFinishMotivationReducer,

  isFetchingPostNfMotivationFinishMotivation: isFetchingPostNfMotivationFinishMotivationReducer,
  postNfMotivationFinishMotivationError: postNfMotivationFinishMotivationErrorReducer,

  getNfMotivationConfirmationDetailsByIegExecutionID: getNfMotivationConfirmationDetailsByIegExecutionIDReducer,

  isFetchingGetNfMotivationConfirmationDetailsByIegExecutionID: isFetchingGetNfMotivationConfirmationDetailsByIegExecutionIDReducer,
  getNfMotivationConfirmationDetailsByIegExecutionIDError: getNfMotivationConfirmationDetailsByIegExecutionIDErrorReducer,

  getNfMotivationMotivationDetailsByIegExecutionID: getNfMotivationMotivationDetailsByIegExecutionIDReducer,

  isFetchingGetNfMotivationMotivationDetailsByIegExecutionID: isFetchingGetNfMotivationMotivationDetailsByIegExecutionIDReducer,
  getNfMotivationMotivationDetailsByIegExecutionIDError: getNfMotivationMotivationDetailsByIegExecutionIDErrorReducer,

  postNfMotivationSignAndSubmit: postNfMotivationSignAndSubmitReducer,

  isFetchingPostNfMotivationSignAndSubmit: isFetchingPostNfMotivationSignAndSubmitReducer,
  postNfMotivationSignAndSubmitError: postNfMotivationSignAndSubmitErrorReducer,

  deleteNfMotivationByIegExecutionID: deleteNfMotivationByIegExecutionIDReducer,

  isFetchingDeleteNfMotivationByIegExecutionID: isFetchingDeleteNfMotivationByIegExecutionIDReducer,
  deleteNfMotivationByIegExecutionIDError: deleteNfMotivationByIegExecutionIDErrorReducer,

  fetchNfDeemedNewBornIsAnbLoggedinUserLinked: fetchNfDeemedNewBornIsAnbLoggedinUserLinkedReducer,

  isFetchingFetchNfDeemedNewBornIsAnbLoggedinUserLinked: isFetchingFetchNfDeemedNewBornIsAnbLoggedinUserLinkedReducer,
  fetchNfDeemedNewBornIsAnbLoggedinUserLinkedError: fetchNfDeemedNewBornIsAnbLoggedinUserLinkedErrorReducer,

  postNfDeemedNewBornArchiveProviderAnbApplications: postNfDeemedNewBornArchiveProviderAnbApplicationsReducer,

  isFetchingPostNfDeemedNewBornArchiveProviderAnbApplications: isFetchingPostNfDeemedNewBornArchiveProviderAnbApplicationsReducer,
  postNfDeemedNewBornArchiveProviderAnbApplicationsError: postNfDeemedNewBornArchiveProviderAnbApplicationsErrorReducer,

  fetchNfDeemedNewBornSearchArchivedAnbReferrals: fetchNfDeemedNewBornSearchArchivedAnbReferralsReducer,

  isFetchingFetchNfDeemedNewBornSearchArchivedAnbReferrals: isFetchingFetchNfDeemedNewBornSearchArchivedAnbReferralsReducer,
  fetchNfDeemedNewBornSearchArchivedAnbReferralsError: fetchNfDeemedNewBornSearchArchivedAnbReferralsErrorReducer,

  fetchNfDeemedNewBornSearchAnbReferrals: fetchNfDeemedNewBornSearchAnbReferralsReducer,

  isFetchingFetchNfDeemedNewBornSearchAnbReferrals: isFetchingFetchNfDeemedNewBornSearchAnbReferralsReducer,
  fetchNfDeemedNewBornSearchAnbReferralsError: fetchNfDeemedNewBornSearchAnbReferralsErrorReducer,

  fetchNfDeemedNewBornListAnbUserFacilities: fetchNfDeemedNewBornListAnbUserFacilitiesReducer,

  isFetchingFetchNfDeemedNewBornListAnbUserFacilities: isFetchingFetchNfDeemedNewBornListAnbUserFacilitiesReducer,
  fetchNfDeemedNewBornListAnbUserFacilitiesError: fetchNfDeemedNewBornListAnbUserFacilitiesErrorReducer,
});

export default { reducers };
