/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFDeemedNewBornResponse from api:
 * /v1/ua/nf_deemed_new_born/get_deemed_new_born_allowed
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDeemedNewBornGetDeemedNewBornAllowedUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_deemed_new_born/get_deemed_new_born_allowed`,
    models.NFDeemedNewBornResponse
  );
};

/**
 * Gets NFMotivationTypeResponse from api:
 * /v1/ua/nf_motivation/deemed_new_born
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfMotivationDeemedNewBornUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/deemed_new_born`,
    models.NFMotivationTypeResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/motivation/setup-and-start
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationSetupAndStartUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/setup-and-start`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFMotivationTypeResponse from api:
 * /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationMotivationTypeByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/motivation_type/${iegExecutionID}`,
    models.NFMotivationTypeResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/finish_motivation
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationFinishMotivationUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/finish_motivation`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMotivationFinishResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFCESignAndSubmitResponse from api:
 * /v1/ua/nf_motivation/confirmation_details/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationConfirmationDetailsByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/confirmation_details/${iegExecutionID}`,
    models.NFCESignAndSubmitResponse
  );
};

/**
 * Gets NFMotivationDetails from api:
 * /v1/ua/nf_motivation/motivation_details/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationMotivationDetailsByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/motivation_details/${iegExecutionID}`,
    models.NFMotivationDetails
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/sign_and_submit
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationSignAndSubmitUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/sign_and_submit`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFCESignAndSubmitResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Deletes from api:
 * /v1/ua/nf_motivation/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const deleteNfMotivationByIegExecutionIDUtil = (iegExecutionID, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/${iegExecutionID}`;
  RESTService.del(url, (success, response) => {
    callback(success, response);
  });
};

/**
 * Gets NFIsANBUserLinkedResponse from api:
 * /v1/ua/nf_deemed_new_born/is_anb_loggedin_user_linked
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDeemedNewBornIsAnbLoggedinUserLinkedUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_deemed_new_born/is_anb_loggedin_user_linked`,
    models.NFIsANBUserLinkedResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_deemed_new_born/archive_provider_anb_applications
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDeemedNewBornArchiveProviderAnbApplicationsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_deemed_new_born/archive_provider_anb_applications`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFANBReferralArchivedResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFANBApplicationsListResponse from api:
 * /v1/ua/nf_deemed_new_born/search_archived_anb_referrals
 *
 * @param {string} pageNumber.
 * @param {string} pageSize.
 * @param {string} nfProviderConcernRoleID.
 * @param {string} nfSearchBy.
 * @param {string} nfSearchByValue.
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDeemedNewBornSearchArchivedAnbReferralsUtil = (
  pageNumber,
  pageSize,
  nfProviderConcernRoleID,
  nfSearchBy,
  nfSearchByValue,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_deemed_new_born/search_archived_anb_referrals?pageNumber=${pageNumber}&pageSize=${pageSize}&nfProviderConcernRoleID=${nfProviderConcernRoleID}&nfSearchBy=${nfSearchBy}&nfSearchByValue=${nfSearchByValue}`,
    models.NFANBApplicationsListResponse
  );
};

/**
 * Gets NFANBApplicationsListResponse from api:
 * /v1/ua/nf_deemed_new_born/search_anb_referrals
 *
 * @param {string} pageNumber.
 * @param {string} pageSize.
 * @param {string} nfProviderConcernRoleID.
 * @param {string} nfSearchBy.
 * @param {string} nfSearchByValue.
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDeemedNewBornSearchAnbReferralsUtil = (
  pageNumber,
  pageSize,
  nfProviderConcernRoleID,
  nfSearchBy,
  nfSearchByValue,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_deemed_new_born/search_anb_referrals?pageNumber=${pageNumber}&pageSize=${pageSize}&nfProviderConcernRoleID=${nfProviderConcernRoleID}&nfSearchBy=${nfSearchBy}&nfSearchByValue=${nfSearchByValue}`,
    models.NFANBApplicationsListResponse
  );
};

/**
 * Gets NFANBUserFacilityListResponse from api:
 * /v1/ua/nf_deemed_new_born/list_anb_user_facilities
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDeemedNewBornListAnbUserFacilitiesUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_deemed_new_born/list_anb_user_facilities`,
    models.NFANBUserFacilityListResponse
  );
};

export {
  fetchNfDeemedNewBornGetDeemedNewBornAllowedUtil,
  fetchNfMotivationDeemedNewBornUtil,
  postMotivationSetupAndStartUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishMotivationUtil,
  getNfMotivationConfirmationDetailsByIegExecutionIDUtil,
  getNfMotivationMotivationDetailsByIegExecutionIDUtil,
  postNfMotivationSignAndSubmitUtil,
  deleteNfMotivationByIegExecutionIDUtil,
  fetchNfDeemedNewBornIsAnbLoggedinUserLinkedUtil,
  postNfDeemedNewBornArchiveProviderAnbApplicationsUtil,
  fetchNfDeemedNewBornSearchArchivedAnbReferralsUtil,
  fetchNfDeemedNewBornSearchAnbReferralsUtil,
  fetchNfDeemedNewBornListAnbUserFacilitiesUtil,
};
