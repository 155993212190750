/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFPresumptiveMotivation' store object.
 * @namespace NFPresumptiveMotivationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFPresumptiveMotivation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static postMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStart.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static isFetchingPostMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationSetupAndStart.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static postMotivationSetupAndStartError = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStartError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static fetchNfMotivationPresumptive = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationPresumptive.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static isFetchingFetchNfMotivationPresumptive = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfMotivationPresumptive.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static fetchNfMotivationPresumptiveError = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationPresumptiveError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static isFetchingGetNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationMotivationTypeByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static postNfMotivationFinishMotivation = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishMotivation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static isFetchingPostNfMotivationFinishMotivation = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationFinishMotivation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static postNfMotivationFinishMotivationError = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishMotivationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static fetchNfHcrgetpresumptiveuserPresumptive = state =>
    state[selectors.moduleIdentifier].fetchNfHcrgetpresumptiveuserPresumptive.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static isFetchingFetchNfHcrgetpresumptiveuserPresumptive = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfHcrgetpresumptiveuserPresumptive.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFPresumptiveMotivationSelectors
   */
  static fetchNfHcrgetpresumptiveuserPresumptiveError = state =>
    state[selectors.moduleIdentifier].fetchNfHcrgetpresumptiveuserPresumptiveError.payload;
}
