/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFCovidTestingMotivation.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFCOVIDTESTINGMOTIVATION/FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING`
);

const POST_NF_MOTIVATION_FINISH_COVID_TESTING = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFCOVIDTESTINGMOTIVATION/POST_NF_MOTIVATION_FINISH_COVID_TESTING`
);

export { FETCH_NF_MOTIVATION_APPLY_FOR_COVID_TESTING, POST_NF_MOTIVATION_FINISH_COVID_TESTING };
