/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFMassCommunication' store object.
 * @namespace NFMassCommunicationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFMassCommunication';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationViewPrefMethod = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationViewPrefMethod.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static isFetchingPostNfMassCommunicationViewPrefMethod = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMassCommunicationViewPrefMethod.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationViewPrefMethodError = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationViewPrefMethodError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationCreateMassComm = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationCreateMassComm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static isFetchingPostNfMassCommunicationCreateMassComm = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMassCommunicationCreateMassComm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationCreateMassCommError = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationCreateMassCommError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationUpdateMassCommunication = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationUpdateMassCommunication.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static isFetchingPostNfMassCommunicationUpdateMassCommunication = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMassCommunicationUpdateMassCommunication
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationUpdateMassCommunicationError = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationUpdateMassCommunicationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationCancelMassCommunication = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationCancelMassCommunication.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static isFetchingPostNfMassCommunicationCancelMassCommunication = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMassCommunicationCancelMassCommunication
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMassCommunicationSelectors
   */
  static postNfMassCommunicationCancelMassCommunicationError = state =>
    state[selectors.moduleIdentifier].postNfMassCommunicationCancelMassCommunicationError.payload;
}
