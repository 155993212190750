/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';

import selectors from './selectors';

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_lifeevent/apply_for_coc
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfLifeeventApplyForCoc = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfLifeeventApplyForCoc(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfLifeeventApplyForCoc(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfLifeeventApplyForCoc(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_lifeevent/apply_for_ar
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfLifeeventApplyForAr = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfLifeeventApplyForAr(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfLifeeventApplyForAr(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfLifeeventApplyForAr(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_coc/unsupported_message
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCocUnsupportedMessage = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfCocUnsupportedMessage(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCocUnsupportedMessage(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCocUnsupportedMessage(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/life_events_form
 *
 * postLifeEventsForm - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostLifeEventsForm = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/life_events_form
   *
   * @param {NewUALifeEventContext} data object that will be posted to the api
   * @returns {Promise}
   */
  const postLifeEventsForm = useCallback(
    newUALifeEventContext => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postLifeEventsForm(dispatch, newUALifeEventContext, (success, responseData) => {
          if (success) {
            resolve(responseData);
            setResponse({
              data: responseData,
              isFetching: false,
              error: null,
            });
          } else {
            reject(responseData);
            setResponse({
              data: null,
              isFetching: false,
              error: responseData,
            });
          }
        });
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postLifeEventsForm,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/finish_coc
 *
 * postNfMotivationFinishCoc - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationFinishCoc = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/finish_coc
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationFinishCoc = useCallback(
    newUAMotivationIEGKey => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationFinishCoc(
          dispatch,
          newUAMotivationIEGKey,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationFinishCoc,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/sign_and_submit_coc
 *
 * postNfMotivationSignAndSubmitCoc - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationSignAndSubmitCoc = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/sign_and_submit_coc
   *
   * @param {NewNFCESignAndSubmit} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationSignAndSubmitCoc = useCallback(
    newNFCESignAndSubmit => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationSignAndSubmitCoc(
          dispatch,
          newNFCESignAndSubmit,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationSignAndSubmitCoc,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/{motivation_id}/esignature_by_motivation
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} motivation_id.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationEsignatureByMotivation = (motivation_id, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.getNfMotivationEsignatureByMotivation(state));

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationEsignatureByMotivation(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    motivation_id => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationEsignatureByMotivation(dispatch, motivation_id, (success, data) => {
        setResponse({
          data: success ? data : null,
          isFetching: false,
          error: success ? null : data,
        });
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(motivation_id);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/coc_confirmation_details/{motivation_id}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} motivation_id.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationCocConfirmationDetailsByMotivationId = (motivation_id, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfMotivationCocConfirmationDetailsByMotivationId(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationCocConfirmationDetailsByMotivationId(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    motivation_id => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationCocConfirmationDetailsByMotivationId(
        dispatch,
        motivation_id,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(motivation_id);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_life_events/list_submitted_lifeevents
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfLifeEventsListSubmittedLifeevents = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.fetchNfLifeEventsListSubmittedLifeevents(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetFetchNfLifeEventsListSubmittedLifeevents(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfLifeEventsListSubmittedLifeevents(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_citizen_portal_evidence/read_my_info_tab_details
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCitizenPortalEvidenceReadMyInfoTabDetails = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.fetchNfCitizenPortalEvidenceReadMyInfoTabDetails(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCitizenPortalEvidenceReadMyInfoTabDetails(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCitizenPortalEvidenceReadMyInfoTabDetails(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_citizen_portal_evidence/get_enable_abd/{nfMotivationID}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} nfMotivationID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID = (nfMotivationID, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    nfMotivationID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID(
        dispatch,
        nfMotivationID,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(nfMotivationID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_citizen_portal_evidence/getEnableUnifiedMagi/{nfMotivationID}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} nfMotivationID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID = (
  nfMotivationID,
  deps = []
) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    nfMotivationID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID(
        dispatch,
        nfMotivationID,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(nfMotivationID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/setup_start_motivation
 *
 * postNfMotivationSetupStartMotivation - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationSetupStartMotivation = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/setup_start_motivation
   *
   * @param {NewNFStartMotivationKey} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationSetupStartMotivation = useCallback(
    newNFStartMotivationKey => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationSetupStartMotivation(
          dispatch,
          newNFStartMotivationKey,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationSetupStartMotivation,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_coc/apply_for_snap_coc
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCocApplyForSnapCoc = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfCocApplyForSnapCoc(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCocApplyForSnapCoc(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCocApplyForSnapCoc(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_coc/apply_for_fns_coc
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCocApplyForFnsCoc = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfCocApplyForFnsCoc(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCocApplyForFnsCoc(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCocApplyForFnsCoc(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

export default {
  useFetchNfLifeeventApplyForCoc,
  useFetchNfLifeeventApplyForAr,
  useFetchNfCocUnsupportedMessage,
  usePostLifeEventsForm,
  usePostNfMotivationFinishCoc,
  usePostNfMotivationSignAndSubmitCoc,
  useGetNfMotivationEsignatureByMotivation,
  useGetNfMotivationCocConfirmationDetailsByMotivationId,
  useFetchNfLifeEventsListSubmittedLifeevents,
  useFetchNfCitizenPortalEvidenceReadMyInfoTabDetails,
  useGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID,
  useGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID,
  usePostNfMotivationSetupStartMotivation,
  useFetchNfCocApplyForSnapCoc,
  useFetchNfCocApplyForFnsCoc,
};
