/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFMotivationStandaloneRDIP' store object.
 * @namespace NFMotivationStandaloneRDIPSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFMotivationStandaloneRDIP';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static postMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStart.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static isFetchingPostMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationSetupAndStart.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static postMotivationSetupAndStartError = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStartError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static fetchNfMotivationApplyForStandaloneRidp = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationApplyForStandaloneRidp.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static isFetchingFetchNfMotivationApplyForStandaloneRidp = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfMotivationApplyForStandaloneRidp.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static fetchNfMotivationApplyForStandaloneRidpError = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationApplyForStandaloneRidpError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static isFetchingGetNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationMotivationTypeByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static postNfMotivationFinishStandaloneRidp = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishStandaloneRidp.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static isFetchingPostNfMotivationFinishStandaloneRidp = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationFinishStandaloneRidp.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static postNfMotivationFinishStandaloneRidpError = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishStandaloneRidpError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static deleteNfMotivationByIegExecutionID = state =>
    state[selectors.moduleIdentifier].deleteNfMotivationByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static isFetchingDeleteNfMotivationByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingDeleteNfMotivationByIegExecutionID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFMotivationStandaloneRDIPSelectors
   */
  static deleteNfMotivationByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].deleteNfMotivationByIegExecutionIDError.payload;
}
