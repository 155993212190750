/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFCOCConfigResponse from api:
 * /v1/ua/nf_coc/apply_for_fns_recert
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfCocApplyForFnsRecertUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_coc/apply_for_fns_recert`,
    models.NFCOCConfigResponse
  );
};

export { fetchNfCocApplyForFnsRecertUtil };
