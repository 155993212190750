/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFPersonInformationDetails from api:
 * /v1/ua/nf_textMessage_subscriptionua/get_Person_Information
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfTextMessageSubscriptionuaGetPersonInformationUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_textMessage_subscriptionua/get_Person_Information`,
    models.NFPersonInformationDetails
  );
};

/**
 * Gets NFUnreadSecureMessagesCount from api:
 * /v1/ua/nf_textMessage_subscriptionua/unread_SecureMessages_Count
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_textMessage_subscriptionua/unread_SecureMessages_Count`,
    models.NFUnreadSecureMessagesCount
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_textMessage_subscriptionua/create_textMessage_subscription
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_textMessage_subscriptionua/create_textMessage_subscription`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFTextMessageResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_textMessage_subscriptionua/update_TextMessage_Subscription
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_textMessage_subscriptionua/update_TextMessage_Subscription`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFTextMessageResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_textMessage_subscriptionua/unsubscribe_textMessage
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfTextMessageSubscriptionuaUnsubscribeTextMessageUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_textMessage_subscriptionua/unsubscribe_textMessage`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFTextMessageResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export {
  fetchNfTextMessageSubscriptionuaGetPersonInformationUtil,
  fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountUtil,
  postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionUtil,
  postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionUtil,
  postNfTextMessageSubscriptionuaUnsubscribeTextMessageUtil,
};
