const ID = {
  NFAPPLICATION_FOOTER_FAQ_ID: 'nf-application-footer-faq-id',
  NFAPPLICATION_FOOTER_ENROLLMENT_BROKER_ID: 'nf-application-footer-enrollment-broker-id',
  NFAPPLICATION_FOOTER_FIND_AN_OFFICE_ID: 'nf-application-footer-find-an-office-id',
  NFAPPLICATION_FOOTER_DHHS_ID: 'nf-application-footer-dhhs-id',
  NFAPPLICATION_FOOTER_HEALTHCARE_ID: 'nf-application-footer-Health-Care.gov-id',
  NFAPPLICATION_FOOTER_DISCLAIMER_ID: 'nf-application-footer-disclaimer-id',
  //NFAPPLICATION_FOOTER_DROPDOWN_ID: 'nf-application-footer-dropdown-id',
  NF_EPASS_DISCLAIMER_BACK_BUTTON_ID: 'nf-epass-disclaimer-back-button-id',
  NFAPPLICATION_FOOTER_ENHANCED_EPASS_ACCOUNTS: 'nf-epass-enhanced-epass-accounts-id',
  NFAPPLICATION_FOOTER_ENOTICES_ID: 'nf-epass-enotices-id',
  NFAPPLICATION_FOOTER_DOCUMENT_UPLOAD_ID: 'nf-epass-document-upload-id',
  NFAPPLICATION_FNS_FACT_INFO_ID: 'nf-epass-fns-fact-info-button-id',
  NFAPPLICATION_BROWSER_SUPPORT_CLEAR_CACHE_ID: 'nf-epass-browser-support-version-cache-id',
  NFAPPLICATION_FOOTER_TEXT_AND_SECURE_MESSAGE_ID: 'nf-epass-text-secure-messaging-id',
  NFAPPLICATION_FNS_SNAP_ONLINE_COC_FAQ_ID: 'nf-application-fns-snap-online-coc-faq-id',
  NFAPPLICATION_FNS_SNAP_ONLINE_RECERT_FAQ_ID: 'nf-application-fns-snap-online-recert-faq-id',
};

/* eslint-disable import/prefer-default-export */
export { ID };
