/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Gets NFOutstandingVerificationsResponse from api:
 * /v1/ua/nf_document_upload/outstanding_verifications
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDocumentUploadOutstandingVerificationsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_document_upload/outstanding_verifications`,
    models.NFOutstandingVerificationsResponse
  );
};

/**
 * Gets NFRejectedVerificationDocumentsResponse from api:
 * /v1/ua/nf_document_upload/rejected_documents
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDocumentUploadRejectedDocumentsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_document_upload/rejected_documents`,
    models.NFRejectedVerificationDocumentsResponse
  );
};

/**
 * Gets NFUploadedVerificationDocumentsResponse from api:
 * /v1/ua/nf_document_upload/uploaded_verification_documents/{vdied_link_id}
 *
 * @param {string} vdied_link_id.
 * @param {any} callback function to call once this function has finished
 */
const getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdUtil = (
  vdied_link_id,
  callback
) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_document_upload/uploaded_verification_documents/${vdied_link_id}`,
    models.NFUploadedVerificationDocumentsResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/delete_uploaded_verification_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadDeleteUploadedVerificationDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/delete_uploaded_verification_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFDocumentUploadResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_verification_document
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadVerificationDocumentUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_verification_document`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadVerificationDocumentResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/submit_verification_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadSubmitVerificationDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/submit_verification_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFDocumentUploadResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/submit_non_linked_standalone_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/submit_non_linked_standalone_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFDocumentUploadResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_multiple_verification_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadMultipleVerificationDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_multiple_verification_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadedMultipleVerificationDocumentsResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFLinkedInd from api:
 * /v1/ua/nf_doc_upload/is_user_linked
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDocUploadIsUserLinkedUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_doc_upload/is_user_linked`,
    models.NFLinkedInd
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_dsnap_document
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadDsnapDocumentUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_dsnap_document`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadDocumentResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_multiple_dsnap_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadMultipleDsnapDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_multiple_dsnap_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadMultipleDocumentsResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_online_document
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadOnlineDocumentUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_online_document`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadDocumentResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_multiple_online_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadMultipleOnlineDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_multiple_online_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadMultipleDocumentsResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFUploadedStandaloneDocumentsResponse from api:
 * /v1/ua/nf_document_upload/uploaded_Standalone_Documents
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDocumentUploadUploadedStandaloneDocumentsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_document_upload/uploaded_Standalone_Documents`,
    models.NFUploadedStandaloneDocumentsResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_multiple_standalone_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadMultipleStandaloneDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_multiple_standalone_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadMultipleDocumentsResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_multiple_non_linked_standalone_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsUtil = (
  data = {},
  callback
) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_multiple_non_linked_standalone_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadMultipleDocumentsResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/delete_uploaded_standalone_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadDeleteUploadedStandaloneDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/delete_uploaded_standalone_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFDocumentUploadStandaloneResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_document_upload/upload_multiple_energy_documents
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfDocumentUploadUploadMultipleEnergyDocumentsUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_document_upload/upload_multiple_energy_documents`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUploadMultipleDocumentsResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFENMultipleUploadedDocumentsResponse from api:
 * /v1/ua/nf_document_upload/uploaded_energy_documents
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDocumentUploadUploadedEnergyDocumentsUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_document_upload/uploaded_energy_documents`,
    models.NFENMultipleUploadedDocumentsResponse
  );
};

/**
 * Gets NFExistingApplicationAndCaseResponse from api:
 * /v1/ua/nf_document_upload/listNFExistingApplicationAndCases
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfDocumentUploadListNFExistingApplicationAndCasesUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_document_upload/listNFExistingApplicationAndCases`,
    models.NFExistingApplicationAndCaseResponse
  );
};

export {
  fetchNfDocumentUploadOutstandingVerificationsUtil,
  fetchNfDocumentUploadRejectedDocumentsUtil,
  getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdUtil,
  postNfDocumentUploadDeleteUploadedVerificationDocumentsUtil,
  postNfDocumentUploadUploadVerificationDocumentUtil,
  postNfDocumentUploadSubmitVerificationDocumentsUtil,
  postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleVerificationDocumentsUtil,
  fetchNfDocUploadIsUserLinkedUtil,
  postNfDocumentUploadUploadDsnapDocumentUtil,
  postNfDocumentUploadUploadMultipleDsnapDocumentsUtil,
  postNfDocumentUploadUploadOnlineDocumentUtil,
  postNfDocumentUploadUploadMultipleOnlineDocumentsUtil,
  fetchNfDocumentUploadUploadedStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsUtil,
  postNfDocumentUploadDeleteUploadedStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleEnergyDocumentsUtil,
  fetchNfDocumentUploadUploadedEnergyDocumentsUtil,
  fetchNfDocumentUploadListNFExistingApplicationAndCasesUtil,
};
