/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED,
  FETCH_NF_MOTIVATION_DEEMED_NEW_BORN,
  POST_MOTIVATION_SETUP_AND_START,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_MOTIVATION,
  GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
  FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED,
  POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS,
  FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS,
  FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS,
  FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES,
} from './actionTypes';

import {
  fetchNfDeemedNewBornGetDeemedNewBornAllowedUtil,
  fetchNfMotivationDeemedNewBornUtil,
  postMotivationSetupAndStartUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishMotivationUtil,
  getNfMotivationConfirmationDetailsByIegExecutionIDUtil,
  getNfMotivationMotivationDetailsByIegExecutionIDUtil,
  postNfMotivationSignAndSubmitUtil,
  deleteNfMotivationByIegExecutionIDUtil,
  fetchNfDeemedNewBornIsAnbLoggedinUserLinkedUtil,
  postNfDeemedNewBornArchiveProviderAnbApplicationsUtil,
  fetchNfDeemedNewBornSearchArchivedAnbReferralsUtil,
  fetchNfDeemedNewBornSearchAnbReferralsUtil,
  fetchNfDeemedNewBornListAnbUserFacilitiesUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFDeemedNewBornMotivation slice of the store.
 *
 * @namespace NFDeemedNewBornMotivationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_deemed_new_born/get_deemed_new_born_allowed
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static fetchNfDeemedNewBornGetDeemedNewBornAllowed = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDeemedNewBornGetDeemedNewBornAllowedUtil,
      FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDeemedNewBornGetDeemedNewBornAllowed.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornGetDeemedNewBornAllowed = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDeemedNewBornGetDeemedNewBornAllowed.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornGetDeemedNewBornAllowedError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DEEMED_NEW_BORN_GET_DEEMED_NEW_BORN_ALLOWED.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/deemed_new_born
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static fetchNfMotivationDeemedNewBorn = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfMotivationDeemedNewBornUtil,
      FETCH_NF_MOTIVATION_DEEMED_NEW_BORN,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfMotivationDeemedNewBorn.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfMotivationDeemedNewBorn = (dispatch, data) => {
    const json = {
      type: FETCH_NF_MOTIVATION_DEEMED_NEW_BORN.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfMotivationDeemedNewBorn.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfMotivationDeemedNewBornError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_MOTIVATION_DEEMED_NEW_BORN.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static postMotivationSetupAndStart = (dispatch, newUAMotivationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postMotivationSetupAndStartUtil.bind(null, newUAMotivationType),
      POST_MOTIVATION_SETUP_AND_START,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostMotivationSetupAndStart = (dispatch, data) => {
    const json = {
      type: POST_MOTIVATION_SETUP_AND_START.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postMotivationSetupAndStart.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostMotivationSetupAndStartError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_MOTIVATION_SETUP_AND_START.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static getNfMotivationMotivationTypeByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationMotivationTypeByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationMotivationTypeByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetGetNfMotivationMotivationTypeByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/finish_motivation
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static postNfMotivationFinishMotivation = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationFinishMotivationUtil.bind(null, newUAMotivationIEGKey),
      POST_NF_MOTIVATION_FINISH_MOTIVATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationFinishMotivation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostNfMotivationFinishMotivation = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_FINISH_MOTIVATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationFinishMotivation.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostNfMotivationFinishMotivationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_FINISH_MOTIVATION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/confirmation_details/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static getNfMotivationConfirmationDetailsByIegExecutionID = (
    dispatch,
    iegExecutionID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationConfirmationDetailsByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationConfirmationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetGetNfMotivationConfirmationDetailsByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationConfirmationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetGetNfMotivationConfirmationDetailsByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/motivation_details/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static getNfMotivationMotivationDetailsByIegExecutionID = (
    dispatch,
    iegExecutionID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationMotivationDetailsByIegExecutionIDUtil.bind(null, iegExecutionID),

      GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationMotivationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetGetNfMotivationMotivationDetailsByIegExecutionID = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationMotivationDetailsByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetGetNfMotivationMotivationDetailsByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/sign_and_submit
   *
   * @param {NewNFCESignAndSubmit} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static postNfMotivationSignAndSubmit = (dispatch, newNFCESignAndSubmit, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationSignAndSubmitUtil.bind(null, newNFCESignAndSubmit),
      POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationSignAndSubmit.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostNfMotivationSignAndSubmit = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_SIGN_AND_SUBMIT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationSignAndSubmit.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostNfMotivationSignAndSubmitError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_SIGN_AND_SUBMIT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static deleteNfMotivationByIegExecutionID = (dispatch, iegExecutionID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      deleteNfMotivationByIegExecutionIDUtil.bind(null, iegExecutionID),
      DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to deleteNfMotivationByIegExecutionID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetDeleteNfMotivationByIegExecutionID = (dispatch, data) => {
    const json = {
      type: DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to deleteNfMotivationByIegExecutionID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetDeleteNfMotivationByIegExecutionIDError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_deemed_new_born/is_anb_loggedin_user_linked
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static fetchNfDeemedNewBornIsAnbLoggedinUserLinked = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDeemedNewBornIsAnbLoggedinUserLinkedUtil,
      FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDeemedNewBornIsAnbLoggedinUserLinked.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornIsAnbLoggedinUserLinked = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDeemedNewBornIsAnbLoggedinUserLinked.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornIsAnbLoggedinUserLinkedError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DEEMED_NEW_BORN_IS_ANB_LOGGEDIN_USER_LINKED.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_deemed_new_born/archive_provider_anb_applications
   *
   * @param {NewNFANBArchiveApplicationDetailsList} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static postNfDeemedNewBornArchiveProviderAnbApplications = (
    dispatch,
    newNFANBArchiveApplicationDetailsList,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDeemedNewBornArchiveProviderAnbApplicationsUtil.bind(
        null,
        newNFANBArchiveApplicationDetailsList
      ),
      POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDeemedNewBornArchiveProviderAnbApplications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostNfDeemedNewBornArchiveProviderAnbApplications = (dispatch, data) => {
    const json = {
      type: POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDeemedNewBornArchiveProviderAnbApplications.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetPostNfDeemedNewBornArchiveProviderAnbApplicationsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DEEMED_NEW_BORN_ARCHIVE_PROVIDER_ANB_APPLICATIONS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_deemed_new_born/search_archived_anb_referrals
   *
   * @param {string} pageNumber.
   * @param {string} pageSize.
   * @param {string} nfProviderConcernRoleID.
   * @param {string} nfSearchBy.
   * @param {string} nfSearchByValue.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static fetchNfDeemedNewBornSearchArchivedAnbReferrals = (
    dispatch,
    pageNumber,
    pageSize,
    nfProviderConcernRoleID,
    nfSearchBy,
    nfSearchByValue,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDeemedNewBornSearchArchivedAnbReferralsUtil.bind(
        null,
        pageNumber,
        pageSize,
        nfProviderConcernRoleID,
        nfSearchBy,
        nfSearchByValue
      ),

      FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDeemedNewBornSearchArchivedAnbReferrals.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornSearchArchivedAnbReferrals = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDeemedNewBornSearchArchivedAnbReferrals.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornSearchArchivedAnbReferralsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DEEMED_NEW_BORN_SEARCH_ARCHIVED_ANB_REFERRALS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_deemed_new_born/search_anb_referrals
   *
   * @param {string} pageNumber.
   * @param {string} pageSize.
   * @param {string} nfProviderConcernRoleID.
   * @param {string} nfSearchBy.
   * @param {string} nfSearchByValue.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static fetchNfDeemedNewBornSearchAnbReferrals = (
    dispatch,
    pageNumber,
    pageSize,
    nfProviderConcernRoleID,
    nfSearchBy,
    nfSearchByValue,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDeemedNewBornSearchAnbReferralsUtil.bind(
        null,
        pageNumber,
        pageSize,
        nfProviderConcernRoleID,
        nfSearchBy,
        nfSearchByValue
      ),

      FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDeemedNewBornSearchAnbReferrals.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornSearchAnbReferrals = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDeemedNewBornSearchAnbReferrals.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornSearchAnbReferralsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DEEMED_NEW_BORN_SEARCH_ANB_REFERRALS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_deemed_new_born/list_anb_user_facilities
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static fetchNfDeemedNewBornListAnbUserFacilities = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDeemedNewBornListAnbUserFacilitiesUtil,
      FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDeemedNewBornListAnbUserFacilities.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornListAnbUserFacilities = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDeemedNewBornListAnbUserFacilities.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDeemedNewBornMotivationActions
   */
  static resetFetchNfDeemedNewBornListAnbUserFacilitiesError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DEEMED_NEW_BORN_LIST_ANB_USER_FACILITIES.failureAction
    );
  };
}
