/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE } from './actionTypes';

import { postNfCounterByNfCounterTypeCodeUtil } from './utils';

/**
 * Contains the action creator functions that update the NFOrganizationVoterRegistration slice of the store.
 *
 * @namespace NFOrganizationVoterRegistrationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_counter/{nfCounterTypeCode}
   *
   * @param {string} nfCounterTypeCode.
   * @param {NewNFCounterRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFOrganizationVoterRegistrationActions
   */
  static postNfCounterByNfCounterTypeCode = (
    dispatch,
    nfCounterTypeCode,
    newNFCounterRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfCounterByNfCounterTypeCodeUtil.bind(null, nfCounterTypeCode, newNFCounterRequest),
      POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfCounterByNfCounterTypeCode.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFOrganizationVoterRegistrationActions
   */
  static resetPostNfCounterByNfCounterTypeCode = (dispatch, data) => {
    const json = {
      type: POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfCounterByNfCounterTypeCode.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFOrganizationVoterRegistrationActions
   */
  static resetPostNfCounterByNfCounterTypeCodeError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_COUNTER_BY_NF_COUNTER_TYPE_CODE.failureAction);
  };
}
