/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION,
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE,
} from './actionTypes';

/**
 * Updates the details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfTextMessageSubscriptionuaGetPersonInformationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION
  );

/**
 * Updates the indicator of creation of details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfTextMessageSubscriptionuaGetPersonInformationReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfTextMessageSubscriptionuaGetPersonInformationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_GET_PERSON_INFORMATION
  );

/**
 * Updates the details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT
  );

/**
 * Updates the indicator of creation of details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNREAD_SECURE_MESSAGES_COUNT
  );

/**
 * Updates the details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION
  );

/**
 * Updates the indicator of creation of details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfTextMessageSubscriptionuaCreateTextMessageSubscriptionReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_CREATE_TEXT_MESSAGE_SUBSCRIPTION
  );

/**
 * Updates the details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION
  );

/**
 * Updates the indicator of creation of details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_TEXT_MESSAGE_SUBSCRIPTION
  );

/**
 * Updates the details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfTextMessageSubscriptionuaUnsubscribeTextMessageReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE
  );

/**
 * Updates the indicator of creation of details in the 'NFSecureMessagesInfo' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfTextMessageSubscriptionuaUnsubscribeTextMessageReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfTextMessageSubscriptionuaUnsubscribeTextMessageErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UNSUBSCRIBE_TEXT_MESSAGE
  );

const reducers = combineReducers({
  fetchNfTextMessageSubscriptionuaGetPersonInformation: fetchNfTextMessageSubscriptionuaGetPersonInformationReducer,

  isFetchingFetchNfTextMessageSubscriptionuaGetPersonInformation: isFetchingFetchNfTextMessageSubscriptionuaGetPersonInformationReducer,
  fetchNfTextMessageSubscriptionuaGetPersonInformationError: fetchNfTextMessageSubscriptionuaGetPersonInformationErrorReducer,

  fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount: fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountReducer,

  isFetchingFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCount: isFetchingFetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountReducer,
  fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountError: fetchNfTextMessageSubscriptionuaUnreadSecureMessagesCountErrorReducer,

  postNfTextMessageSubscriptionuaCreateTextMessageSubscription: postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionReducer,

  isFetchingPostNfTextMessageSubscriptionuaCreateTextMessageSubscription: isFetchingPostNfTextMessageSubscriptionuaCreateTextMessageSubscriptionReducer,
  postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionError: postNfTextMessageSubscriptionuaCreateTextMessageSubscriptionErrorReducer,

  postNfTextMessageSubscriptionuaUpdateTextMessageSubscription: postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionReducer,

  isFetchingPostNfTextMessageSubscriptionuaUpdateTextMessageSubscription: isFetchingPostNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionReducer,
  postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionError: postNfTextMessageSubscriptionuaUpdateTextMessageSubscriptionErrorReducer,

  postNfTextMessageSubscriptionuaUnsubscribeTextMessage: postNfTextMessageSubscriptionuaUnsubscribeTextMessageReducer,

  isFetchingPostNfTextMessageSubscriptionuaUnsubscribeTextMessage: isFetchingPostNfTextMessageSubscriptionuaUnsubscribeTextMessageReducer,
  postNfTextMessageSubscriptionuaUnsubscribeTextMessageError: postNfTextMessageSubscriptionuaUnsubscribeTextMessageErrorReducer,
});

export default { reducers };
