/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFCovidTestingMotivation' store object.
 * @namespace NFCovidTestingMotivationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFCovidTestingMotivation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCovidTestingMotivationSelectors
   */
  static fetchNfMotivationApplyForCovidTesting = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationApplyForCovidTesting.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCovidTestingMotivationSelectors
   */
  static isFetchingFetchNfMotivationApplyForCovidTesting = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfMotivationApplyForCovidTesting.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCovidTestingMotivationSelectors
   */
  static fetchNfMotivationApplyForCovidTestingError = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationApplyForCovidTestingError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCovidTestingMotivationSelectors
   */
  static postNfMotivationFinishCovidTesting = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishCovidTesting.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCovidTestingMotivationSelectors
   */
  static isFetchingPostNfMotivationFinishCovidTesting = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationFinishCovidTesting.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCovidTestingMotivationSelectors
   */
  static postNfMotivationFinishCovidTestingError = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishCovidTestingError.payload;
}
