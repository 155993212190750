/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS,
  FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS,
  GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID,
  POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS,
  FETCH_NF_DOC_UPLOAD_IS_USER_LINKED,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS,
  POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS,
  FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES,
} from './actionTypes';

import {
  fetchNfDocumentUploadOutstandingVerificationsUtil,
  fetchNfDocumentUploadRejectedDocumentsUtil,
  getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdUtil,
  postNfDocumentUploadDeleteUploadedVerificationDocumentsUtil,
  postNfDocumentUploadUploadVerificationDocumentUtil,
  postNfDocumentUploadSubmitVerificationDocumentsUtil,
  postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleVerificationDocumentsUtil,
  fetchNfDocUploadIsUserLinkedUtil,
  postNfDocumentUploadUploadDsnapDocumentUtil,
  postNfDocumentUploadUploadMultipleDsnapDocumentsUtil,
  postNfDocumentUploadUploadOnlineDocumentUtil,
  postNfDocumentUploadUploadMultipleOnlineDocumentsUtil,
  fetchNfDocumentUploadUploadedStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsUtil,
  postNfDocumentUploadDeleteUploadedStandaloneDocumentsUtil,
  postNfDocumentUploadUploadMultipleEnergyDocumentsUtil,
  fetchNfDocumentUploadUploadedEnergyDocumentsUtil,
  fetchNfDocumentUploadListNFExistingApplicationAndCasesUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFDocumentUpload slice of the store.
 *
 * @namespace NFDocumentUploadActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/outstanding_verifications
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static fetchNfDocumentUploadOutstandingVerifications = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDocumentUploadOutstandingVerificationsUtil,
      FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDocumentUploadOutstandingVerifications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadOutstandingVerifications = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDocumentUploadOutstandingVerifications.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadOutstandingVerificationsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DOCUMENT_UPLOAD_OUTSTANDING_VERIFICATIONS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/rejected_documents
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static fetchNfDocumentUploadRejectedDocuments = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDocumentUploadRejectedDocumentsUtil,
      FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDocumentUploadRejectedDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadRejectedDocuments = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDocumentUploadRejectedDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadRejectedDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DOCUMENT_UPLOAD_REJECTED_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/uploaded_verification_documents/{vdied_link_id}
   *
   * @param {string} vdied_link_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId = (
    dispatch,
    vdied_link_id,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdUtil.bind(null, vdied_link_id),

      GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId = (dispatch, data) => {
    const json = {
      type: GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkId.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetGetNfDocumentUploadUploadedVerificationDocumentsByVdiedLinkIdError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_DOCUMENT_UPLOAD_UPLOADED_VERIFICATION_DOCUMENTS_BY_VDIED_LINK_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/delete_uploaded_verification_documents
   *
   * @param {NewNFDeleteUploadedVerificationDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadDeleteUploadedVerificationDocuments = (
    dispatch,
    newNFDeleteUploadedVerificationDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadDeleteUploadedVerificationDocumentsUtil.bind(
        null,
        newNFDeleteUploadedVerificationDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadDeleteUploadedVerificationDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadDeleteUploadedVerificationDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadDeleteUploadedVerificationDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadDeleteUploadedVerificationDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_VERIFICATION_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_verification_document
   *
   * @param {NewNFUploadVerificationDocumentRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadVerificationDocument = (
    dispatch,
    newNFUploadVerificationDocumentRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadVerificationDocumentUtil.bind(
        null,
        newNFUploadVerificationDocumentRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadVerificationDocument.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadVerificationDocument = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadVerificationDocument.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadVerificationDocumentError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_VERIFICATION_DOCUMENT.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/submit_verification_documents
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadSubmitVerificationDocuments = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadSubmitVerificationDocumentsUtil.bind(null, {}),
      POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadSubmitVerificationDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadSubmitVerificationDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadSubmitVerificationDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadSubmitVerificationDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_SUBMIT_VERIFICATION_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/submit_non_linked_standalone_documents
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadSubmitNonLinkedStandaloneDocuments = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadSubmitNonLinkedStandaloneDocumentsUtil.bind(null, {}),
      POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadSubmitNonLinkedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadSubmitNonLinkedStandaloneDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadSubmitNonLinkedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadSubmitNonLinkedStandaloneDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_SUBMIT_NON_LINKED_STANDALONE_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_multiple_verification_documents
   *
   * @param {NewNFUploadedMultipleVerificationDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadMultipleVerificationDocuments = (
    dispatch,
    newNFUploadedMultipleVerificationDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadMultipleVerificationDocumentsUtil.bind(
        null,
        newNFUploadedMultipleVerificationDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadMultipleVerificationDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleVerificationDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadMultipleVerificationDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleVerificationDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_VERIFICATION_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_doc_upload/is_user_linked
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static fetchNfDocUploadIsUserLinked = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDocUploadIsUserLinkedUtil,
      FETCH_NF_DOC_UPLOAD_IS_USER_LINKED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDocUploadIsUserLinked.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocUploadIsUserLinked = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DOC_UPLOAD_IS_USER_LINKED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDocUploadIsUserLinked.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocUploadIsUserLinkedError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_DOC_UPLOAD_IS_USER_LINKED.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_dsnap_document
   *
   * @param {NewNFUploadDocumentRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadDsnapDocument = (
    dispatch,
    newNFUploadDocumentRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadDsnapDocumentUtil.bind(null, newNFUploadDocumentRequest),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadDsnapDocument.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadDsnapDocument = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadDsnapDocument.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadDsnapDocumentError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_DSNAP_DOCUMENT.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_multiple_dsnap_documents
   *
   * @param {NewNFUploadMultipleDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadMultipleDsnapDocuments = (
    dispatch,
    newNFUploadMultipleDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadMultipleDsnapDocumentsUtil.bind(
        null,
        newNFUploadMultipleDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadMultipleDsnapDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleDsnapDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadMultipleDsnapDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleDsnapDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_DSNAP_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_online_document
   *
   * @param {NewNFUploadDocumentRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadOnlineDocument = (
    dispatch,
    newNFUploadDocumentRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadOnlineDocumentUtil.bind(null, newNFUploadDocumentRequest),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadOnlineDocument.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadOnlineDocument = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadOnlineDocument.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadOnlineDocumentError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_ONLINE_DOCUMENT.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_multiple_online_documents
   *
   * @param {NewNFUploadMultipleDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadMultipleOnlineDocuments = (
    dispatch,
    newNFUploadMultipleDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadMultipleOnlineDocumentsUtil.bind(
        null,
        newNFUploadMultipleDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadMultipleOnlineDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleOnlineDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadMultipleOnlineDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleOnlineDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ONLINE_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/uploaded_Standalone_Documents
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static fetchNfDocumentUploadUploadedStandaloneDocuments = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDocumentUploadUploadedStandaloneDocumentsUtil,
      FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDocumentUploadUploadedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadUploadedStandaloneDocuments = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDocumentUploadUploadedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadUploadedStandaloneDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_STANDALONE_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_multiple_standalone_documents
   *
   * @param {NewNFUploadMultipleDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadMultipleStandaloneDocuments = (
    dispatch,
    newNFUploadMultipleDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadMultipleStandaloneDocumentsUtil.bind(
        null,
        newNFUploadMultipleDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadMultipleStandaloneDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleStandaloneDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadMultipleStandaloneDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleStandaloneDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_STANDALONE_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_multiple_non_linked_standalone_documents
   *
   * @param {NewNFUploadMultipleDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments = (
    dispatch,
    newNFUploadMultipleDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsUtil.bind(
        null,
        newNFUploadMultipleDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadMultipleNonLinkedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleNonLinkedStandaloneDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_NON_LINKED_STANDALONE_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/delete_uploaded_standalone_documents
   *
   * @param {NewNFDeleteUploadedStandaloneDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadDeleteUploadedStandaloneDocuments = (
    dispatch,
    newNFDeleteUploadedStandaloneDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadDeleteUploadedStandaloneDocumentsUtil.bind(
        null,
        newNFDeleteUploadedStandaloneDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadDeleteUploadedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadDeleteUploadedStandaloneDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadDeleteUploadedStandaloneDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadDeleteUploadedStandaloneDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_DELETE_UPLOADED_STANDALONE_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/upload_multiple_energy_documents
   *
   * @param {NewNFENUploadMultipleDocumentsRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static postNfDocumentUploadUploadMultipleEnergyDocuments = (
    dispatch,
    newNFENUploadMultipleDocumentsRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfDocumentUploadUploadMultipleEnergyDocumentsUtil.bind(
        null,
        newNFENUploadMultipleDocumentsRequest
      ),
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfDocumentUploadUploadMultipleEnergyDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleEnergyDocuments = (dispatch, data) => {
    const json = {
      type: POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfDocumentUploadUploadMultipleEnergyDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetPostNfDocumentUploadUploadMultipleEnergyDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_DOCUMENT_UPLOAD_UPLOAD_MULTIPLE_ENERGY_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/uploaded_energy_documents
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static fetchNfDocumentUploadUploadedEnergyDocuments = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDocumentUploadUploadedEnergyDocumentsUtil,
      FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDocumentUploadUploadedEnergyDocuments.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadUploadedEnergyDocuments = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDocumentUploadUploadedEnergyDocuments.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadUploadedEnergyDocumentsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DOCUMENT_UPLOAD_UPLOADED_ENERGY_DOCUMENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_document_upload/listNFExistingApplicationAndCases
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFDocumentUploadActions
   */
  static fetchNfDocumentUploadListNFExistingApplicationAndCases = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfDocumentUploadListNFExistingApplicationAndCasesUtil,
      FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfDocumentUploadListNFExistingApplicationAndCases.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadListNFExistingApplicationAndCases = (dispatch, data) => {
    const json = {
      type: FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfDocumentUploadListNFExistingApplicationAndCases.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFDocumentUploadActions
   */
  static resetFetchNfDocumentUploadListNFExistingApplicationAndCasesError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_DOCUMENT_UPLOAD_LIST_NF_EXISTING_APPLICATION_AND_CASES.failureAction
    );
  };
}
