/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFOrganisation.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_NF_EXTERNAL_URL_CONFIG = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFORGANISATION/FETCH_NF_EXTERNAL_URL_CONFIG`
);

export { FETCH_NF_EXTERNAL_URL_CONFIG };
