/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the HouseholdMemberSummaryDetails
 *
 * @class HouseholdMemberSummaryDetails
 */
class HouseholdMemberSummaryDetails {
  /**
   * Creates an instance of HouseholdMemberSummaryDetails.
   * @param {*} json
   * @memberof HouseholdMemberSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.ssn = json.ssn;

      // object - CodeTableType
      if (json.citizenshipStatus) {
        this.citizenshipStatus = new CodeTableType(json.citizenshipStatus);
      }

      // string
      this.dateOfBirth = json.dateOfBirth;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the IncomeSummaryDetails
 *
 * @class IncomeSummaryDetails
 */
class IncomeSummaryDetails {
  /**
   * Creates an instance of IncomeSummaryDetails.
   * @param {*} json
   * @memberof IncomeSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.memberName = json.memberName;

      // number
      this.amount = json.amount;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // object - CodeTableType
      if (json.frequency) {
        this.frequency = new CodeTableType(json.frequency);
      }
    }
  }
}

/**
 * Models the LivingArrangementsSummaryDetails
 *
 * @class LivingArrangementsSummaryDetails
 */
class LivingArrangementsSummaryDetails {
  /**
   * Creates an instance of LivingArrangementsSummaryDetails.
   * @param {*} json
   * @memberof LivingArrangementsSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.address = json.address;

      // string
      this.addressOccupants = json.addressOccupants;
    }
  }
}

/**
 * Models the TaxFilingSummaryDetails
 *
 * @class TaxFilingSummaryDetails
 */
class TaxFilingSummaryDetails {
  /**
   * Creates an instance of TaxFilingSummaryDetails.
   * @param {*} json
   * @memberof TaxFilingSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.memberName = json.memberName;

      // string
      this.dependentMemberName = json.dependentMemberName;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }
    }
  }
}

/**
 * Models the CitizenAccountSummaryDetails
 *
 * @class CitizenAccountSummaryDetails
 */
class CitizenAccountSummaryDetails {
  /**
   * Creates an instance of CitizenAccountSummaryDetails.
   * @param {*} json
   * @memberof CitizenAccountSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.pendingLifeEventInd = json.pendingLifeEventInd;

      // boolean
      this.displayIncomeSummaryInd = json.displayIncomeSummaryInd;

      // boolean
      this.displayTaxFilingSummaryInd = json.displayTaxFilingSummaryInd;

      // boolean
      this.displayLivingArrangementSummaryInd = json.displayLivingArrangementSummaryInd;

      // boolean
      this.pendingAnnualRenewalInd = json.pendingAnnualRenewalInd;

      // boolean
      this.pendingChangeOfCircumstanceEventInd = json.pendingChangeOfCircumstanceEventInd;

      // array - HouseholdMemberSummaryDetails
      if (json.householdMemberList) {
        this.householdMemberList = json.householdMemberList.map(
          object => new HouseholdMemberSummaryDetails(object)
        );
      } else if (json.constructor === Array) {
        this.householdMemberList = json.map(object => new HouseholdMemberSummaryDetails(object));
      }

      // array - IncomeSummaryDetails
      if (json.incomeList) {
        this.incomeList = json.incomeList.map(object => new IncomeSummaryDetails(object));
      } else if (json.constructor === Array) {
        this.incomeList = json.map(object => new IncomeSummaryDetails(object));
      }

      // array - LivingArrangementsSummaryDetails
      if (json.livingArrangmentList) {
        this.livingArrangmentList = json.livingArrangmentList.map(
          object => new LivingArrangementsSummaryDetails(object)
        );
      } else if (json.constructor === Array) {
        this.livingArrangmentList = json.map(
          object => new LivingArrangementsSummaryDetails(object)
        );
      }

      // array - TaxFilingSummaryDetails
      if (json.taxFilingList) {
        this.taxFilingList = json.taxFilingList.map(object => new TaxFilingSummaryDetails(object));
      } else if (json.constructor === Array) {
        this.taxFilingList = json.map(object => new TaxFilingSummaryDetails(object));
      }
    }
  }
}

/**
 * Models the NFAddressDetails
 *
 * @class NFAddressDetails
 */
class NFAddressDetails {
  /**
   * Creates an instance of NFAddressDetails.
   * @param {*} json
   * @memberof NFAddressDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.address = json.address;

      // string
      this.addressType = json.addressType;
    }
  }
}

/**
 * Models the NFCitizenCaseInfoDetails
 *
 * @class NFCitizenCaseInfoDetails
 */
class NFCitizenCaseInfoDetails {
  /**
   * Creates an instance of NFCitizenCaseInfoDetails.
   * @param {*} json
   * @memberof NFCitizenCaseInfoDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfSNAPRecertInd = json.nfSNAPRecertInd;

      // boolean
      this.nfFNSRecertInd = json.nfFNSRecertInd;

      // boolean
      this.nfSNAPCOCInd = json.nfSNAPCOCInd;

      // boolean
      this.nfFNSCOCInd = json.nfFNSCOCInd;
    }
  }
}

/**
 * Models the NFHouseholdMemberSummaryDetails
 *
 * @class NFHouseholdMemberSummaryDetails
 */
class NFHouseholdMemberSummaryDetails {
  /**
   * Creates an instance of NFHouseholdMemberSummaryDetails.
   * @param {*} json
   * @memberof NFHouseholdMemberSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfType = json.nfType;

      // string
      this.nfSsn = json.nfSsn;

      // string
      this.nfCitizenshipStatus = json.nfCitizenshipStatus;

      // string
      this.nfName = json.nfName;

      // string
      this.nfDateOfBirth = json.nfDateOfBirth;
    }
  }
}

/**
 * Models the NFIncomeSummaryDetails
 *
 * @class NFIncomeSummaryDetails
 */
class NFIncomeSummaryDetails {
  /**
   * Creates an instance of NFIncomeSummaryDetails.
   * @param {*} json
   * @memberof NFIncomeSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.memberName = json.memberName;

      // object - CodeTableType
      if (json.frequency) {
        this.frequency = new CodeTableType(json.frequency);
      }

      // number
      this.amount = json.amount;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // string
      this.employerName = json.employerName;
    }
  }
}

/**
 * Models the NFPregnancyInfo
 *
 * @class NFPregnancyInfo
 */
class NFPregnancyInfo {
  /**
   * Creates an instance of NFPregnancyInfo.
   * @param {*} json
   * @memberof NFPregnancyInfo
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pregnancyMemberName = json.pregnancyMemberName;

      // string
      this.pregnancyDueDate = json.pregnancyDueDate;

      // string
      this.pregnancyNoOfUnborn = json.pregnancyNoOfUnborn;
    }
  }
}

/**
 * Models the NFCitizenAccountSummaryDetails
 *
 * @class NFCitizenAccountSummaryDetails
 */
class NFCitizenAccountSummaryDetails {
  /**
   * Creates an instance of NFCitizenAccountSummaryDetails.
   * @param {*} json
   * @memberof NFCitizenAccountSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // object - CitizenAccountSummaryDetails
      if (json.citizenAccountdtls) {
        this.citizenAccountdtls = new CitizenAccountSummaryDetails(json.citizenAccountdtls);
      }

      // array - NFAddressDetails
      if (json.addressDtls) {
        this.addressDtls = json.addressDtls.map(object => new NFAddressDetails(object));
      } else if (json.constructor === Array) {
        this.addressDtls = json.map(object => new NFAddressDetails(object));
      }

      // object - NFCitizenCaseInfoDetails
      if (json.nfCitizenCaseDtls) {
        this.nfCitizenCaseDtls = new NFCitizenCaseInfoDetails(json.nfCitizenCaseDtls);
      }

      // array - NFHouseholdMemberSummaryDetails
      if (json.houseHoldDtls) {
        this.houseHoldDtls = json.houseHoldDtls.map(
          object => new NFHouseholdMemberSummaryDetails(object)
        );
      } else if (json.constructor === Array) {
        this.houseHoldDtls = json.map(object => new NFHouseholdMemberSummaryDetails(object));
      }

      // array - NFIncomeSummaryDetails
      if (json.nfIncomeList) {
        this.nfIncomeList = json.nfIncomeList.map(object => new NFIncomeSummaryDetails(object));
      } else if (json.constructor === Array) {
        this.nfIncomeList = json.map(object => new NFIncomeSummaryDetails(object));
      }

      // array - NFPregnancyInfo
      if (json.nfPregnancyList) {
        this.nfPregnancyList = json.nfPregnancyList.map(object => new NFPregnancyInfo(object));
      } else if (json.constructor === Array) {
        this.nfPregnancyList = json.map(object => new NFPregnancyInfo(object));
      }
    }
  }
}

/**
 * Models the NFCitizenPortalEvidenceResponse
 *
 * @class NFCitizenPortalEvidenceResponse
 */
class NFCitizenPortalEvidenceResponse {
  /**
   * Creates an instance of NFCitizenPortalEvidenceResponse.
   * @param {*} json
   * @memberof NFCitizenPortalEvidenceResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // boolean
      this.isClosedPDC = json.isClosedPDC;

      // object - NFCitizenAccountSummaryDetails
      if (json.accountSummaryDtls) {
        this.accountSummaryDtls = new NFCitizenAccountSummaryDetails(json.accountSummaryDtls);
      }
    }
  }
}

export default {
  CodeTableType,

  HouseholdMemberSummaryDetails,

  IncomeSummaryDetails,

  LivingArrangementsSummaryDetails,

  TaxFilingSummaryDetails,

  CitizenAccountSummaryDetails,

  NFAddressDetails,

  NFCitizenCaseInfoDetails,

  NFHouseholdMemberSummaryDetails,

  NFIncomeSummaryDetails,

  NFPregnancyInfo,

  NFCitizenAccountSummaryDetails,

  NFCitizenPortalEvidenceResponse,
};
