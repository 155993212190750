/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_NFMFA_GET_MFA_QUESTIONS,
  POST_NFMFA_SEND_MFA_CODE,
  POST_NFMFA_RESEND_MFA_CODE,
} from './actionTypes';

/**
 * Updates the details in the 'NFMFASession' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfmfaGetMFAQuestionsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NFMFA_GET_MFA_QUESTIONS);

/**
 * Updates the indicator of creation of details in the 'NFMFASession' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfmfaGetMFAQuestionsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NFMFA_GET_MFA_QUESTIONS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfmfaGetMFAQuestionsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NFMFA_GET_MFA_QUESTIONS);

/**
 * Updates the details in the 'NFMFASession' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfmfaSendMFACodeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NFMFA_SEND_MFA_CODE);

/**
 * Updates the indicator of creation of details in the 'NFMFASession' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfmfaSendMFACodeReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NFMFA_SEND_MFA_CODE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfmfaSendMFACodeErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NFMFA_SEND_MFA_CODE);

/**
 * Updates the details in the 'NFMFASession' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfmfaResendMFACodeReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NFMFA_RESEND_MFA_CODE);

/**
 * Updates the indicator of creation of details in the 'NFMFASession' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfmfaResendMFACodeReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NFMFA_RESEND_MFA_CODE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfmfaResendMFACodeErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NFMFA_RESEND_MFA_CODE);

const reducers = combineReducers({
  postNfmfaGetMFAQuestions: postNfmfaGetMFAQuestionsReducer,

  isFetchingPostNfmfaGetMFAQuestions: isFetchingPostNfmfaGetMFAQuestionsReducer,
  postNfmfaGetMFAQuestionsError: postNfmfaGetMFAQuestionsErrorReducer,

  postNfmfaSendMFACode: postNfmfaSendMFACodeReducer,

  isFetchingPostNfmfaSendMFACode: isFetchingPostNfmfaSendMFACodeReducer,
  postNfmfaSendMFACodeError: postNfmfaSendMFACodeErrorReducer,

  postNfmfaResendMFACode: postNfmfaResendMFACodeReducer,

  isFetchingPostNfmfaResendMFACode: isFetchingPostNfmfaResendMFACodeReducer,
  postNfmfaResendMFACodeError: postNfmfaResendMFACodeErrorReducer,
});

export default { reducers };
