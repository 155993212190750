/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFeNotices' store object.
 * @namespace NFeNoticesSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFeNotices';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static fetchNfSecureInboxIsSubscribed = state =>
    state[selectors.moduleIdentifier].fetchNfSecureInboxIsSubscribed.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingFetchNfSecureInboxIsSubscribed = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfSecureInboxIsSubscribed.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static fetchNfSecureInboxIsSubscribedError = state =>
    state[selectors.moduleIdentifier].fetchNfSecureInboxIsSubscribedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static fetchNfSecureInboxListComm = state =>
    state[selectors.moduleIdentifier].fetchNfSecureInboxListComm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingFetchNfSecureInboxListComm = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfSecureInboxListComm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static fetchNfSecureInboxListCommError = state =>
    state[selectors.moduleIdentifier].fetchNfSecureInboxListCommError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxPrimaryContactDtls = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxPrimaryContactDtls.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingPostNfSecureInboxPrimaryContactDtls = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfSecureInboxPrimaryContactDtls.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxPrimaryContactDtlsError = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxPrimaryContactDtlsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxSubscribeEnotices = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxSubscribeEnotices.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingPostNfSecureInboxSubscribeEnotices = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfSecureInboxSubscribeEnotices.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxSubscribeEnoticesError = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxSubscribeEnoticesError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxUnsubscribeEnotices = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxUnsubscribeEnotices.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingPostNfSecureInboxUnsubscribeEnotices = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfSecureInboxUnsubscribeEnotices.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxUnsubscribeEnoticesError = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxUnsubscribeEnoticesError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxSendByMailByCommunicationID = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxSendByMailByCommunicationID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingPostNfSecureInboxSendByMailByCommunicationID = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfSecureInboxSendByMailByCommunicationID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxSendByMailByCommunicationIDError = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxSendByMailByCommunicationIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static fetchNfSecureInboxGetMailingAddress = state =>
    state[selectors.moduleIdentifier].fetchNfSecureInboxGetMailingAddress.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingFetchNfSecureInboxGetMailingAddress = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfSecureInboxGetMailingAddress.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static fetchNfSecureInboxGetMailingAddressError = state =>
    state[selectors.moduleIdentifier].fetchNfSecureInboxGetMailingAddressError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static getNfSecureInboxGetENoticesByCommunicationID = state =>
    state[selectors.moduleIdentifier].getNfSecureInboxGetENoticesByCommunicationID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingGetNfSecureInboxGetENoticesByCommunicationID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfSecureInboxGetENoticesByCommunicationID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static getNfSecureInboxGetENoticesByCommunicationIDError = state =>
    state[selectors.moduleIdentifier].getNfSecureInboxGetENoticesByCommunicationIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxUpdateENoticeViewStatus = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxUpdateENoticeViewStatus.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static isFetchingPostNfSecureInboxUpdateENoticeViewStatus = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfSecureInboxUpdateENoticeViewStatus.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFeNoticesSelectors
   */
  static postNfSecureInboxUpdateENoticeViewStatusError = state =>
    state[selectors.moduleIdentifier].postNfSecureInboxUpdateENoticeViewStatusError.payload;
}
