/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for SampleModule.
 *
 */

import { ReduxUtils } from '@spm/core';

const FETCH_ONLINE_CATEGORIES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `SAMPLEMODULE/FETCH_ONLINE_CATEGORIES`
);

const CREATE_APPLICATION_FORM = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `SAMPLEMODULE/CREATE_APPLICATION_FORM`
);

const FETCH_SUBMITTED_APPLICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `SAMPLEMODULE/FETCH_SUBMITTED_APPLICATIONS`
);

const POST_APPLICATIONS = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `SAMPLEMODULE/POST_APPLICATIONS`
);

const FETCH_ACTIVITIES = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `SAMPLEMODULE/FETCH_ACTIVITIES`
);

const FETCH_APPEALS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `SAMPLEMODULE/FETCH_APPEALS`
);

const SET_SELECTED_APPLICATION_TYPE = ReduxUtils.generateActionType(
  'ua-library',
  `SAMPLEMODULE/SET_SELECTED_APPLICATION_TYPE`,
  'SET'
);

export {
  FETCH_ONLINE_CATEGORIES,
  CREATE_APPLICATION_FORM,
  FETCH_SUBMITTED_APPLICATIONS,
  POST_APPLICATIONS,
  FETCH_ACTIVITIES,
  FETCH_APPEALS,
  SET_SELECTED_APPLICATION_TYPE,
};
