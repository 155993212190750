/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFProfile' store object.
 * @namespace NFProfileSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFProfile';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static fetchNfRidpProfileInfo = state =>
    state[selectors.moduleIdentifier].fetchNfRidpProfileInfo.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingFetchNfRidpProfileInfo = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfRidpProfileInfo.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static fetchNfRidpProfileInfoError = state =>
    state[selectors.moduleIdentifier].fetchNfRidpProfileInfoError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpUpdatePriContact = state =>
    state[selectors.moduleIdentifier].postNfRidpUpdatePriContact.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingPostNfRidpUpdatePriContact = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfRidpUpdatePriContact.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpUpdatePriContactError = state =>
    state[selectors.moduleIdentifier].postNfRidpUpdatePriContactError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpUpdateSecContact = state =>
    state[selectors.moduleIdentifier].postNfRidpUpdateSecContact.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingPostNfRidpUpdateSecContact = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfRidpUpdateSecContact.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpUpdateSecContactError = state =>
    state[selectors.moduleIdentifier].postNfRidpUpdateSecContactError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpResendCodeUpdateContact = state =>
    state[selectors.moduleIdentifier].postNfRidpResendCodeUpdateContact.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingPostNfRidpResendCodeUpdateContact = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfRidpResendCodeUpdateContact.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpResendCodeUpdateContactError = state =>
    state[selectors.moduleIdentifier].postNfRidpResendCodeUpdateContactError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpUpdateConfirmationNumber = state =>
    state[selectors.moduleIdentifier].postNfRidpUpdateConfirmationNumber.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingPostNfRidpUpdateConfirmationNumber = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfRidpUpdateConfirmationNumber.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpUpdateConfirmationNumberError = state =>
    state[selectors.moduleIdentifier].postNfRidpUpdateConfirmationNumberError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpRevokeAll = state =>
    state[selectors.moduleIdentifier].postNfRidpRevokeAll.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingPostNfRidpRevokeAll = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfRidpRevokeAll.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static postNfRidpRevokeAllError = state =>
    state[selectors.moduleIdentifier].postNfRidpRevokeAllError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static getCodetablesByTableName = state =>
    state[selectors.moduleIdentifier].getCodetablesByTableName.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static isFetchingGetCodetablesByTableName = state =>
    state[selectors.moduleIdentifier].isFetchingGetCodetablesByTableName.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFProfileSelectors
   */
  static getCodetablesByTableNameError = state =>
    state[selectors.moduleIdentifier].getCodetablesByTableNameError.payload;
}
