/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import { FETCH_NF_CITIZEN_ACCOUNT_LIST_CWINFO } from './actionTypes';

/**
 * Updates the details in the 'NFCaseworkerInformation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenAccountListCwinfoReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_CITIZEN_ACCOUNT_LIST_CWINFO);

/**
 * Updates the indicator of creation of details in the 'NFCaseworkerInformation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenAccountListCwinfoReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_CITIZEN_ACCOUNT_LIST_CWINFO);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenAccountListCwinfoErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_CITIZEN_ACCOUNT_LIST_CWINFO);

const reducers = combineReducers({
  fetchNfCitizenAccountListCwinfo: fetchNfCitizenAccountListCwinfoReducer,

  isFetchingFetchNfCitizenAccountListCwinfo: isFetchingFetchNfCitizenAccountListCwinfoReducer,
  fetchNfCitizenAccountListCwinfoError: fetchNfCitizenAccountListCwinfoErrorReducer,
});

export default { reducers };
