/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFDocUploadBannerMsgs' store object.
 * @namespace NFDocUploadBannerMsgsSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFDocUploadBannerMsgs';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocUploadBannerMsgsSelectors
   */
  static fetchNfCitizenWorkspaceDocUploadInfoMsgs = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceDocUploadInfoMsgs.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocUploadBannerMsgsSelectors
   */
  static isFetchingFetchNfCitizenWorkspaceDocUploadInfoMsgs = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCitizenWorkspaceDocUploadInfoMsgs.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFDocUploadBannerMsgsSelectors
   */
  static fetchNfCitizenWorkspaceDocUploadInfoMsgsError = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceDocUploadInfoMsgsError.payload;
}
