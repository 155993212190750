/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED,
  FETCH_NF_SECURE_INBOX_LIST_COMM,
  POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS,
  POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES,
  POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES,
  POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID,
  FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS,
  GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID,
  POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS,
} from './actionTypes';

import {
  fetchNfSecureInboxIsSubscribedUtil,
  fetchNfSecureInboxListCommUtil,
  postNfSecureInboxPrimaryContactDtlsUtil,
  postNfSecureInboxSubscribeEnoticesUtil,
  postNfSecureInboxUnsubscribeEnoticesUtil,
  postNfSecureInboxSendByMailByCommunicationIDUtil,
  fetchNfSecureInboxGetMailingAddressUtil,
  getNfSecureInboxGetENoticesByCommunicationIDUtil,
  postNfSecureInboxUpdateENoticeViewStatusUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFeNotices slice of the store.
 *
 * @namespace NFeNoticesActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/is_subscribed
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static fetchNfSecureInboxIsSubscribed = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfSecureInboxIsSubscribedUtil,
      FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfSecureInboxIsSubscribed.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetFetchNfSecureInboxIsSubscribed = (dispatch, data) => {
    const json = {
      type: FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfSecureInboxIsSubscribed.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetFetchNfSecureInboxIsSubscribedError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_SECURE_INBOX_IS_SUBSCRIBED.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/list_comm
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static fetchNfSecureInboxListComm = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfSecureInboxListCommUtil,
      FETCH_NF_SECURE_INBOX_LIST_COMM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfSecureInboxListComm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetFetchNfSecureInboxListComm = (dispatch, data) => {
    const json = {
      type: FETCH_NF_SECURE_INBOX_LIST_COMM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfSecureInboxListComm.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetFetchNfSecureInboxListCommError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_SECURE_INBOX_LIST_COMM.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/primary_contact_dtls
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static postNfSecureInboxPrimaryContactDtls = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfSecureInboxPrimaryContactDtlsUtil.bind(null, {}),
      POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfSecureInboxPrimaryContactDtls.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxPrimaryContactDtls = (dispatch, data) => {
    const json = {
      type: POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfSecureInboxPrimaryContactDtls.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxPrimaryContactDtlsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_SECURE_INBOX_PRIMARY_CONTACT_DTLS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/subscribe_enotices
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static postNfSecureInboxSubscribeEnotices = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfSecureInboxSubscribeEnoticesUtil.bind(null, {}),
      POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfSecureInboxSubscribeEnotices.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxSubscribeEnotices = (dispatch, data) => {
    const json = {
      type: POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfSecureInboxSubscribeEnotices.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxSubscribeEnoticesError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_SECURE_INBOX_SUBSCRIBE_ENOTICES.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/unsubscribe_enotices
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static postNfSecureInboxUnsubscribeEnotices = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfSecureInboxUnsubscribeEnoticesUtil.bind(null, {}),
      POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfSecureInboxUnsubscribeEnotices.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxUnsubscribeEnotices = (dispatch, data) => {
    const json = {
      type: POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfSecureInboxUnsubscribeEnotices.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxUnsubscribeEnoticesError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_SECURE_INBOX_UNSUBSCRIBE_ENOTICES.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/send_by_mail/{communicationID}
   *
   * @param {string} communicationID.
   * @param {NewNFSecureInboxCommunicationRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static postNfSecureInboxSendByMailByCommunicationID = (
    dispatch,
    communicationID,
    newNFSecureInboxCommunicationRequest,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfSecureInboxSendByMailByCommunicationIDUtil.bind(
        null,
        communicationID,
        newNFSecureInboxCommunicationRequest
      ),
      POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfSecureInboxSendByMailByCommunicationID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxSendByMailByCommunicationID = (dispatch, data) => {
    const json = {
      type: POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfSecureInboxSendByMailByCommunicationID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxSendByMailByCommunicationIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_SECURE_INBOX_SEND_BY_MAIL_BY_COMMUNICATION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/get_mailing_address
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static fetchNfSecureInboxGetMailingAddress = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfSecureInboxGetMailingAddressUtil,
      FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfSecureInboxGetMailingAddress.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetFetchNfSecureInboxGetMailingAddress = (dispatch, data) => {
    const json = {
      type: FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfSecureInboxGetMailingAddress.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetFetchNfSecureInboxGetMailingAddressError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_SECURE_INBOX_GET_MAILING_ADDRESS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/get_eNotices/{communicationID}
   *
   * @param {string} communicationID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static getNfSecureInboxGetENoticesByCommunicationID = (dispatch, communicationID, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfSecureInboxGetENoticesByCommunicationIDUtil.bind(null, communicationID),

      GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfSecureInboxGetENoticesByCommunicationID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetGetNfSecureInboxGetENoticesByCommunicationID = (dispatch, data) => {
    const json = {
      type: GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfSecureInboxGetENoticesByCommunicationID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetGetNfSecureInboxGetENoticesByCommunicationIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_SECURE_INBOX_GET_E_NOTICES_BY_COMMUNICATION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_secure_inbox/update_ENotice_ViewStatus
   *
   * @param {NewCommunicationIDKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFeNoticesActions
   */
  static postNfSecureInboxUpdateENoticeViewStatus = (dispatch, newCommunicationIDKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfSecureInboxUpdateENoticeViewStatusUtil.bind(null, newCommunicationIDKey),
      POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfSecureInboxUpdateENoticeViewStatus.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxUpdateENoticeViewStatus = (dispatch, data) => {
    const json = {
      type: POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfSecureInboxUpdateENoticeViewStatus.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFeNoticesActions
   */
  static resetPostNfSecureInboxUpdateENoticeViewStatusError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_SECURE_INBOX_UPDATE_E_NOTICE_VIEW_STATUS.failureAction
    );
  };
}
