/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFCOCConfigResponse
 *
 * @class NFCOCConfigResponse
 */
class NFCOCConfigResponse {
  /**
   * Creates an instance of NFCOCConfigResponse.
   * @param {*} json
   * @memberof NFCOCConfigResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfCOCConfigID = json.nfCOCConfigID;

      // string
      this.cocTypeCode = json.cocTypeCode;

      // string
      this.processID = json.processID;
    }
  }
}

export default {
  NFCOCConfigResponse,
};
