/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/*
 *
 * Action types for NFUnifiedMotivation.
 *
 */

import { ReduxUtils } from '@spm/core';

const POST_MOTIVATION_SETUP_AND_START = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_MOTIVATION_SETUP_AND_START`
);

const FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED`
);

const GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID`
);

const POST_NF_MOTIVATION_FINISH_UNIFIED = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_NF_MOTIVATION_FINISH_UNIFIED`
);

const DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID = ReduxUtils.generateActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID`,
  'DELETE'
);

const FETCH_DRAFT_APPLICATIONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/FETCH_DRAFT_APPLICATIONS`
);

const FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP`
);

const GET_NF_MOTIVATION_ESIGNATURE = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/GET_NF_MOTIVATION_ESIGNATURE`
);

const POST_NF_MOTIVATION_SIGN_AND_SUBMIT = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_NF_MOTIVATION_SIGN_AND_SUBMIT`
);

const GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID`
);

const GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID`
);

const GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS`
);

const POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST`
);

const POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST`
);

const FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP = ReduxUtils.generateFetchActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP`
);

const POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST`
);

const POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME = ReduxUtils.generatePostActionTypes(
  'ua-library',
  `NFUNIFIEDMOTIVATION/POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME`
);

export {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_APPLY_FOR_UNIFIED,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_UNIFIED,
  DELETE_NF_MOTIVATION_BY_IEG_EXECUTION_ID,
  FETCH_DRAFT_APPLICATIONS,
  FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_APP,
  GET_NF_MOTIVATION_ESIGNATURE,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT,
  GET_NF_MOTIVATION_MOTIVATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_NF_MOTIVATION_CONFIRMATION_DETAILS_BY_IEG_EXECUTION_ID,
  GET_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST_REASONS,
  POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
  POST_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_COC_REQUEST,
  FETCH_NF_CITIZEN_WORKSPACE_LIST_EXISTING_COC_APP,
  POST_NF_SUBMITTED_APPLICATIONS_APPLICATION_PROGRAMS_WITHDRAWAL_REQUEST,
  POST_NF_MOTIVATION_STORE_SAVE_AND_EXIT_PAGE_NAME,
};
