/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_NFMFA_GET_MFA_QUESTIONS,
  POST_NFMFA_SEND_MFA_CODE,
  POST_NFMFA_RESEND_MFA_CODE,
} from './actionTypes';

import {
  postNfmfaGetMFAQuestionsUtil,
  postNfmfaSendMFACodeUtil,
  postNfmfaResendMFACodeUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFMFASession slice of the store.
 *
 * @namespace NFMFASessionActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nfmfa/getMFAQuestions
   *
   * @param {NewNFExternalUserSessionUUIDKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMFASessionActions
   */
  static postNfmfaGetMFAQuestions = (dispatch, newNFExternalUserSessionUUIDKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfmfaGetMFAQuestionsUtil.bind(null, newNFExternalUserSessionUUIDKey),
      POST_NFMFA_GET_MFA_QUESTIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfmfaGetMFAQuestions.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMFASessionActions
   */
  static resetPostNfmfaGetMFAQuestions = (dispatch, data) => {
    const json = {
      type: POST_NFMFA_GET_MFA_QUESTIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfmfaGetMFAQuestions.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMFASessionActions
   */
  static resetPostNfmfaGetMFAQuestionsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NFMFA_GET_MFA_QUESTIONS.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nfmfa/sendMFACode
   *
   * @param {NewNFMFACodeRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMFASessionActions
   */
  static postNfmfaSendMFACode = (dispatch, newNFMFACodeRequest, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfmfaSendMFACodeUtil.bind(null, newNFMFACodeRequest),
      POST_NFMFA_SEND_MFA_CODE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfmfaSendMFACode.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMFASessionActions
   */
  static resetPostNfmfaSendMFACode = (dispatch, data) => {
    const json = {
      type: POST_NFMFA_SEND_MFA_CODE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfmfaSendMFACode.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMFASessionActions
   */
  static resetPostNfmfaSendMFACodeError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NFMFA_SEND_MFA_CODE.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nfmfa/resendMFACode
   *
   * @param {NewNFMFACodeRequest} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMFASessionActions
   */
  static postNfmfaResendMFACode = (dispatch, newNFMFACodeRequest, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfmfaResendMFACodeUtil.bind(null, newNFMFACodeRequest),
      POST_NFMFA_RESEND_MFA_CODE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfmfaResendMFACode.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMFASessionActions
   */
  static resetPostNfmfaResendMFACode = (dispatch, data) => {
    const json = {
      type: POST_NFMFA_RESEND_MFA_CODE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfmfaResendMFACode.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMFASessionActions
   */
  static resetPostNfmfaResendMFACodeError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NFMFA_RESEND_MFA_CODE.failureAction);
  };
}
