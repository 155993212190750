/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFSNAPRecert' store object.
 * @namespace NFSNAPRecertSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFSNAPRecert';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfCocStartCocScript = state =>
    state[selectors.moduleIdentifier].postNfCocStartCocScript.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingPostNfCocStartCocScript = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfCocStartCocScript.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfCocStartCocScriptError = state =>
    state[selectors.moduleIdentifier].postNfCocStartCocScriptError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static fetchNfCocApplyForSnapRecert = state =>
    state[selectors.moduleIdentifier].fetchNfCocApplyForSnapRecert.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingFetchNfCocApplyForSnapRecert = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCocApplyForSnapRecert.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static fetchNfCocApplyForSnapRecertError = state =>
    state[selectors.moduleIdentifier].fetchNfCocApplyForSnapRecertError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfCocFinishCocRecert = state =>
    state[selectors.moduleIdentifier].postNfCocFinishCocRecert.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingPostNfCocFinishCocRecert = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfCocFinishCocRecert.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfCocFinishCocRecertError = state =>
    state[selectors.moduleIdentifier].postNfCocFinishCocRecertError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfCocSignandsubmitCocRecert = state =>
    state[selectors.moduleIdentifier].postNfCocSignandsubmitCocRecert.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingPostNfCocSignandsubmitCocRecert = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfCocSignandsubmitCocRecert.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfCocSignandsubmitCocRecertError = state =>
    state[selectors.moduleIdentifier].postNfCocSignandsubmitCocRecertError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static getNfCocCocRecertConfirmationDetailsByCocInstanceID = state =>
    state[selectors.moduleIdentifier].getNfCocCocRecertConfirmationDetailsByCocInstanceID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingGetNfCocCocRecertConfirmationDetailsByCocInstanceID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfCocCocRecertConfirmationDetailsByCocInstanceID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static getNfCocCocRecertConfirmationDetailsByCocInstanceIDError = state =>
    state[selectors.moduleIdentifier].getNfCocCocRecertConfirmationDetailsByCocInstanceIDError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfMotivationSetupStartMotivation = state =>
    state[selectors.moduleIdentifier].postNfMotivationSetupStartMotivation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingPostNfMotivationSetupStartMotivation = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationSetupStartMotivation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static postNfMotivationSetupStartMotivationError = state =>
    state[selectors.moduleIdentifier].postNfMotivationSetupStartMotivationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static fetchNfCocListSubmittedRenewals = state =>
    state[selectors.moduleIdentifier].fetchNfCocListSubmittedRenewals.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static isFetchingFetchNfCocListSubmittedRenewals = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCocListSubmittedRenewals.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFSNAPRecertSelectors
   */
  static fetchNfCocListSubmittedRenewalsError = state =>
    state[selectors.moduleIdentifier].fetchNfCocListSubmittedRenewalsError.payload;
}
