/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES,
  POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS,
} from './actionTypes';

import {
  fetchNfTextMessageSubscriptionuaListSecureMessagesUtil,
  postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFSecuredMessages slice of the store.
 *
 * @namespace NFSecuredMessagesActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/list_Secure_Messages
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFSecuredMessagesActions
   */
  static fetchNfTextMessageSubscriptionuaListSecureMessages = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfTextMessageSubscriptionuaListSecureMessagesUtil,
      FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfTextMessageSubscriptionuaListSecureMessages.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecuredMessagesActions
   */
  static resetFetchNfTextMessageSubscriptionuaListSecureMessages = (dispatch, data) => {
    const json = {
      type: FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfTextMessageSubscriptionuaListSecureMessages.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecuredMessagesActions
   */
  static resetFetchNfTextMessageSubscriptionuaListSecureMessagesError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_LIST_SECURE_MESSAGES.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_textMessage_subscriptionua/update_Secure_Message_View_Status
   *
   * @param {NewCommunicationIDKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFSecuredMessagesActions
   */
  static postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatus = (
    dispatch,
    newCommunicationIDKey,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusUtil.bind(
        null,
        newCommunicationIDKey
      ),
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatus.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFSecuredMessagesActions
   */
  static resetPostNfTextMessageSubscriptionuaUpdateSecureMessageViewStatus = (dispatch, data) => {
    const json = {
      type: POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfTextMessageSubscriptionuaUpdateSecureMessageViewStatus.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFSecuredMessagesActions
   */
  static resetPostNfTextMessageSubscriptionuaUpdateSecureMessageViewStatusError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_TEXT_MESSAGE_SUBSCRIPTIONUA_UPDATE_SECURE_MESSAGE_VIEW_STATUS.failureAction
    );
  };
}
