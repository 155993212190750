/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';

import selectors from './selectors';

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_citizen_workspace/apply_lieap
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfCitizenWorkspaceApplyLieap = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfCitizenWorkspaceApplyLieap(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfCitizenWorkspaceApplyLieap(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfCitizenWorkspaceApplyLieap(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_citizen_workspace/start_lieap
 *
 * postNfCitizenWorkspaceStartLieap - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfCitizenWorkspaceStartLieap = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_citizen_workspace/start_lieap
   *
   * @param {NewUAOnlineApplicationType} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfCitizenWorkspaceStartLieap = useCallback(
    newUAOnlineApplicationType => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfCitizenWorkspaceStartLieap(
          dispatch,
          newUAOnlineApplicationType,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfCitizenWorkspaceStartLieap,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/form_details/{application_form_id}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} application_form_id.
 * @param {string} isPartialApplication.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetFormDetailsByApplicationFormId = (
  application_form_id,
  isPartialApplication,
  deps = []
) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.getFormDetailsByApplicationFormId(state));

  const resetData = useCallback(
    data => {
      actions.resetGetFormDetailsByApplicationFormId(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    (application_form_id, isPartialApplication) => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getFormDetailsByApplicationFormId(
        dispatch,
        application_form_id,
        isPartialApplication,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(application_form_id, isPartialApplication);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/draft_applications
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchDraftApplications = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchDraftApplications(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchDraftApplications(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchDraftApplications(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

export default {
  useFetchNfCitizenWorkspaceApplyLieap,
  usePostNfCitizenWorkspaceStartLieap,
  useGetFormDetailsByApplicationFormId,
  useFetchDraftApplications,
};
