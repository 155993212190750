/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  POST_MOTIVATION_SETUP_AND_START,
  FETCH_NF_MOTIVATION_PRESUMPTIVE,
  GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID,
  POST_NF_MOTIVATION_FINISH_MOTIVATION,
  FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE,
} from './actionTypes';

/**
 * Updates the details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the indicator of creation of details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostMotivationSetupAndStartReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postMotivationSetupAndStartErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_MOTIVATION_SETUP_AND_START);

/**
 * Updates the details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfMotivationPresumptiveReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_MOTIVATION_PRESUMPTIVE);

/**
 * Updates the indicator of creation of details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfMotivationPresumptiveReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_MOTIVATION_PRESUMPTIVE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfMotivationPresumptiveErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_MOTIVATION_PRESUMPTIVE);

/**
 * Updates the details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID);

/**
 * Updates the indicator of creation of details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationMotivationTypeByIegExecutionIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_MOTIVATION_TYPE_BY_IEG_EXECUTION_ID
  );

/**
 * Updates the details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationFinishMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_FINISH_MOTIVATION);

/**
 * Updates the indicator of creation of details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationFinishMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_FINISH_MOTIVATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationFinishMotivationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_FINISH_MOTIVATION);

/**
 * Updates the details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfHcrgetpresumptiveuserPresumptiveReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE);

/**
 * Updates the indicator of creation of details in the 'NFPresumptiveMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfHcrgetpresumptiveuserPresumptiveReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfHcrgetpresumptiveuserPresumptiveErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_HCRGETPRESUMPTIVEUSER_PRESUMPTIVE);

const reducers = combineReducers({
  postMotivationSetupAndStart: postMotivationSetupAndStartReducer,

  isFetchingPostMotivationSetupAndStart: isFetchingPostMotivationSetupAndStartReducer,
  postMotivationSetupAndStartError: postMotivationSetupAndStartErrorReducer,

  fetchNfMotivationPresumptive: fetchNfMotivationPresumptiveReducer,

  isFetchingFetchNfMotivationPresumptive: isFetchingFetchNfMotivationPresumptiveReducer,
  fetchNfMotivationPresumptiveError: fetchNfMotivationPresumptiveErrorReducer,

  getNfMotivationMotivationTypeByIegExecutionID: getNfMotivationMotivationTypeByIegExecutionIDReducer,

  isFetchingGetNfMotivationMotivationTypeByIegExecutionID: isFetchingGetNfMotivationMotivationTypeByIegExecutionIDReducer,
  getNfMotivationMotivationTypeByIegExecutionIDError: getNfMotivationMotivationTypeByIegExecutionIDErrorReducer,

  postNfMotivationFinishMotivation: postNfMotivationFinishMotivationReducer,

  isFetchingPostNfMotivationFinishMotivation: isFetchingPostNfMotivationFinishMotivationReducer,
  postNfMotivationFinishMotivationError: postNfMotivationFinishMotivationErrorReducer,

  fetchNfHcrgetpresumptiveuserPresumptive: fetchNfHcrgetpresumptiveuserPresumptiveReducer,

  isFetchingFetchNfHcrgetpresumptiveuserPresumptive: isFetchingFetchNfHcrgetpresumptiveuserPresumptiveReducer,
  fetchNfHcrgetpresumptiveuserPresumptiveError: fetchNfHcrgetpresumptiveuserPresumptiveErrorReducer,
});

export default { reducers };
