/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_LIFEEVENT_APPLY_FOR_COC,
  FETCH_NF_LIFEEVENT_APPLY_FOR_AR,
  FETCH_NF_COC_UNSUPPORTED_MESSAGE,
  POST_LIFE_EVENTS_FORM,
  POST_NF_MOTIVATION_FINISH_COC,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC,
  GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION,
  GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID,
  FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS,
  FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS,
  GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID,
  GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID,
  POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
  FETCH_NF_COC_APPLY_FOR_SNAP_COC,
  FETCH_NF_COC_APPLY_FOR_FNS_COC,
} from './actionTypes';

import {
  fetchNfLifeeventApplyForCocUtil,
  fetchNfLifeeventApplyForArUtil,
  fetchNfCocUnsupportedMessageUtil,
  postLifeEventsFormUtil,
  postNfMotivationFinishCocUtil,
  postNfMotivationSignAndSubmitCocUtil,
  getNfMotivationEsignatureByMotivationUtil,
  getNfMotivationCocConfirmationDetailsByMotivationIdUtil,
  fetchNfLifeEventsListSubmittedLifeeventsUtil,
  fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsUtil,
  getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDUtil,
  getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDUtil,
  postNfMotivationSetupStartMotivationUtil,
  fetchNfCocApplyForSnapCocUtil,
  fetchNfCocApplyForFnsCocUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFCocMotivation slice of the store.
 *
 * @namespace NFCocMotivationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_lifeevent/apply_for_coc
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfLifeeventApplyForCoc = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfLifeeventApplyForCocUtil,
      FETCH_NF_LIFEEVENT_APPLY_FOR_COC,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfLifeeventApplyForCoc.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfLifeeventApplyForCoc = (dispatch, data) => {
    const json = {
      type: FETCH_NF_LIFEEVENT_APPLY_FOR_COC.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfLifeeventApplyForCoc.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfLifeeventApplyForCocError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_LIFEEVENT_APPLY_FOR_COC.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_lifeevent/apply_for_ar
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfLifeeventApplyForAr = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfLifeeventApplyForArUtil,
      FETCH_NF_LIFEEVENT_APPLY_FOR_AR,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfLifeeventApplyForAr.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfLifeeventApplyForAr = (dispatch, data) => {
    const json = {
      type: FETCH_NF_LIFEEVENT_APPLY_FOR_AR.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfLifeeventApplyForAr.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfLifeeventApplyForArError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_LIFEEVENT_APPLY_FOR_AR.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/unsupported_message
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfCocUnsupportedMessage = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCocUnsupportedMessageUtil,
      FETCH_NF_COC_UNSUPPORTED_MESSAGE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCocUnsupportedMessage.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCocUnsupportedMessage = (dispatch, data) => {
    const json = {
      type: FETCH_NF_COC_UNSUPPORTED_MESSAGE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCocUnsupportedMessage.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCocUnsupportedMessageError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_COC_UNSUPPORTED_MESSAGE.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/life_events_form
   *
   * @param {NewUALifeEventContext} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static postLifeEventsForm = (dispatch, newUALifeEventContext, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postLifeEventsFormUtil.bind(null, newUALifeEventContext),
      POST_LIFE_EVENTS_FORM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postLifeEventsForm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostLifeEventsForm = (dispatch, data) => {
    const json = {
      type: POST_LIFE_EVENTS_FORM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postLifeEventsForm.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostLifeEventsFormError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_LIFE_EVENTS_FORM.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/finish_coc
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static postNfMotivationFinishCoc = (dispatch, newUAMotivationIEGKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationFinishCocUtil.bind(null, newUAMotivationIEGKey),
      POST_NF_MOTIVATION_FINISH_COC,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationFinishCoc.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostNfMotivationFinishCoc = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_FINISH_COC.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationFinishCoc.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostNfMotivationFinishCocError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_FINISH_COC.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/sign_and_submit_coc
   *
   * @param {NewNFCESignAndSubmit} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static postNfMotivationSignAndSubmitCoc = (dispatch, newNFCESignAndSubmit, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationSignAndSubmitCocUtil.bind(null, newNFCESignAndSubmit),
      POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationSignAndSubmitCoc.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostNfMotivationSignAndSubmitCoc = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationSignAndSubmitCoc.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostNfMotivationSignAndSubmitCocError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/{motivation_id}/esignature_by_motivation
   *
   * @param {string} motivation_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static getNfMotivationEsignatureByMotivation = (dispatch, motivation_id, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationEsignatureByMotivationUtil.bind(null, motivation_id),

      GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationEsignatureByMotivation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfMotivationEsignatureByMotivation = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationEsignatureByMotivation.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfMotivationEsignatureByMotivationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/coc_confirmation_details/{motivation_id}
   *
   * @param {string} motivation_id.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static getNfMotivationCocConfirmationDetailsByMotivationId = (
    dispatch,
    motivation_id,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfMotivationCocConfirmationDetailsByMotivationIdUtil.bind(null, motivation_id),

      GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfMotivationCocConfirmationDetailsByMotivationId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfMotivationCocConfirmationDetailsByMotivationId = (dispatch, data) => {
    const json = {
      type: GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfMotivationCocConfirmationDetailsByMotivationId.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfMotivationCocConfirmationDetailsByMotivationIdError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_life_events/list_submitted_lifeevents
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfLifeEventsListSubmittedLifeevents = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfLifeEventsListSubmittedLifeeventsUtil,
      FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfLifeEventsListSubmittedLifeevents.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfLifeEventsListSubmittedLifeevents = (dispatch, data) => {
    const json = {
      type: FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfLifeEventsListSubmittedLifeevents.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfLifeEventsListSubmittedLifeeventsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_portal_evidence/read_my_info_tab_details
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfCitizenPortalEvidenceReadMyInfoTabDetails = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsUtil,
      FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCitizenPortalEvidenceReadMyInfoTabDetails.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCitizenPortalEvidenceReadMyInfoTabDetails = (dispatch, data) => {
    const json = {
      type: FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCitizenPortalEvidenceReadMyInfoTabDetails.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCitizenPortalEvidenceReadMyInfoTabDetailsError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_portal_evidence/get_enable_abd/{nfMotivationID}
   *
   * @param {string} nfMotivationID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID = (
    dispatch,
    nfMotivationID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDUtil.bind(null, nfMotivationID),

      GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID = (dispatch, data) => {
    const json = {
      type: GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_portal_evidence/getEnableUnifiedMagi/{nfMotivationID}
   *
   * @param {string} nfMotivationID.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID = (
    dispatch,
    nfMotivationID,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDUtil.bind(null, nfMotivationID),

      GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID = (dispatch, data) => {
    const json = {
      type:
        GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_motivation/setup_start_motivation
   *
   * @param {NewNFStartMotivationKey} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static postNfMotivationSetupStartMotivation = (dispatch, newNFStartMotivationKey, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMotivationSetupStartMotivationUtil.bind(null, newNFStartMotivationKey),
      POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMotivationSetupStartMotivation.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostNfMotivationSetupStartMotivation = (dispatch, data) => {
    const json = {
      type: POST_NF_MOTIVATION_SETUP_START_MOTIVATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMotivationSetupStartMotivation.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetPostNfMotivationSetupStartMotivationError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_MOTIVATION_SETUP_START_MOTIVATION.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/apply_for_snap_coc
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfCocApplyForSnapCoc = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCocApplyForSnapCocUtil,
      FETCH_NF_COC_APPLY_FOR_SNAP_COC,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCocApplyForSnapCoc.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCocApplyForSnapCoc = (dispatch, data) => {
    const json = {
      type: FETCH_NF_COC_APPLY_FOR_SNAP_COC.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCocApplyForSnapCoc.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCocApplyForSnapCocError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_COC_APPLY_FOR_SNAP_COC.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_coc/apply_for_fns_coc
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFCocMotivationActions
   */
  static fetchNfCocApplyForFnsCoc = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCocApplyForFnsCocUtil,
      FETCH_NF_COC_APPLY_FOR_FNS_COC,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCocApplyForFnsCoc.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCocApplyForFnsCoc = (dispatch, data) => {
    const json = {
      type: FETCH_NF_COC_APPLY_FOR_FNS_COC.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCocApplyForFnsCoc.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFCocMotivationActions
   */
  static resetFetchNfCocApplyForFnsCocError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_COC_APPLY_FOR_FNS_COC.failureAction);
  };
}
