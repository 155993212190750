/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/nf_mass_communication/view_pref_method
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMassCommunicationViewPrefMethodUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_mass_communication/view_pref_method`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFUserContactDetailsResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_mass_communication/create_mass_comm
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMassCommunicationCreateMassCommUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_mass_communication/create_mass_comm`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMassCommResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_mass_communication/update_mass_communication
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMassCommunicationUpdateMassCommunicationUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_mass_communication/update_mass_communication`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMassCommResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Post request to path:
 * /v1/ua/nf_mass_communication/cancel_mass_communication
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMassCommunicationCancelMassCommunicationUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_mass_communication/cancel_mass_communication`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMassCommResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

export {
  postNfMassCommunicationViewPrefMethodUtil,
  postNfMassCommunicationCreateMassCommUtil,
  postNfMassCommunicationUpdateMassCommunicationUtil,
  postNfMassCommunicationCancelMassCommunicationUtil,
};
