/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFUnifiedMotivation' store object.
 * @namespace NFUnifiedMotivationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFUnifiedMotivation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStart.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostMotivationSetupAndStart = state =>
    state[selectors.moduleIdentifier].isFetchingPostMotivationSetupAndStart.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postMotivationSetupAndStartError = state =>
    state[selectors.moduleIdentifier].postMotivationSetupAndStartError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchNfMotivationApplyForUnified = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationApplyForUnified.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingFetchNfMotivationApplyForUnified = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfMotivationApplyForUnified.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchNfMotivationApplyForUnifiedError = state =>
    state[selectors.moduleIdentifier].fetchNfMotivationApplyForUnifiedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingGetNfMotivationMotivationTypeByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationMotivationTypeByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationMotivationTypeByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationTypeByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfMotivationFinishUnified = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishUnified.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostNfMotivationFinishUnified = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationFinishUnified.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfMotivationFinishUnifiedError = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishUnifiedError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static deleteNfMotivationByIegExecutionID = state =>
    state[selectors.moduleIdentifier].deleteNfMotivationByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingDeleteNfMotivationByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingDeleteNfMotivationByIegExecutionID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static deleteNfMotivationByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].deleteNfMotivationByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchDraftApplications = state =>
    state[selectors.moduleIdentifier].fetchDraftApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingFetchDraftApplications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchDraftApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchDraftApplicationsError = state =>
    state[selectors.moduleIdentifier].fetchDraftApplicationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchNfCitizenWorkspaceListExistingApp = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceListExistingApp.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingFetchNfCitizenWorkspaceListExistingApp = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCitizenWorkspaceListExistingApp.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchNfCitizenWorkspaceListExistingAppError = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceListExistingAppError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationEsignature = state =>
    state[selectors.moduleIdentifier].getNfMotivationEsignature.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingGetNfMotivationEsignature = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationEsignature.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationEsignatureError = state =>
    state[selectors.moduleIdentifier].getNfMotivationEsignatureError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfMotivationSignAndSubmit = state =>
    state[selectors.moduleIdentifier].postNfMotivationSignAndSubmit.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostNfMotivationSignAndSubmit = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationSignAndSubmit.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfMotivationSignAndSubmitError = state =>
    state[selectors.moduleIdentifier].postNfMotivationSignAndSubmitError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationMotivationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationDetailsByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingGetNfMotivationMotivationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationMotivationDetailsByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationMotivationDetailsByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationMotivationDetailsByIegExecutionIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationConfirmationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].getNfMotivationConfirmationDetailsByIegExecutionID.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingGetNfMotivationConfirmationDetailsByIegExecutionID = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationConfirmationDetailsByIegExecutionID
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getNfMotivationConfirmationDetailsByIegExecutionIDError = state =>
    state[selectors.moduleIdentifier].getNfMotivationConfirmationDetailsByIegExecutionIDError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons = state =>
    state[selectors.moduleIdentifier]
      .getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons = state =>
    state[selectors.moduleIdentifier]
      .isFetchingGetSubmittedApplicationsApplicationProgramsWithdrawalRequestReasons.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsError = state =>
    state[selectors.moduleIdentifier]
      .getSubmittedApplicationsApplicationProgramsWithdrawalRequestReasonsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postSubmittedApplicationsApplicationProgramsWithdrawalRequest = state =>
    state[selectors.moduleIdentifier].postSubmittedApplicationsApplicationProgramsWithdrawalRequest
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalRequest = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalRequest.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postSubmittedApplicationsApplicationProgramsWithdrawalRequestError = state =>
    state[selectors.moduleIdentifier]
      .postSubmittedApplicationsApplicationProgramsWithdrawalRequestError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest = state =>
    state[selectors.moduleIdentifier]
      .postSubmittedApplicationsApplicationProgramsWithdrawalCocRequest.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequest = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostSubmittedApplicationsApplicationProgramsWithdrawalCocRequest.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestError = state =>
    state[selectors.moduleIdentifier]
      .postSubmittedApplicationsApplicationProgramsWithdrawalCocRequestError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchNfCitizenWorkspaceListExistingCocApp = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceListExistingCocApp.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingFetchNfCitizenWorkspaceListExistingCocApp = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCitizenWorkspaceListExistingCocApp.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static fetchNfCitizenWorkspaceListExistingCocAppError = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenWorkspaceListExistingCocAppError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest = state =>
    state[selectors.moduleIdentifier]
      .postNfSubmittedApplicationsApplicationProgramsWithdrawalRequest.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequest = state =>
    state[selectors.moduleIdentifier]
      .isFetchingPostNfSubmittedApplicationsApplicationProgramsWithdrawalRequest.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestError = state =>
    state[selectors.moduleIdentifier]
      .postNfSubmittedApplicationsApplicationProgramsWithdrawalRequestError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfMotivationStoreSaveAndExitPageName = state =>
    state[selectors.moduleIdentifier].postNfMotivationStoreSaveAndExitPageName.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static isFetchingPostNfMotivationStoreSaveAndExitPageName = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationStoreSaveAndExitPageName.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFUnifiedMotivationSelectors
   */
  static postNfMotivationStoreSaveAndExitPageNameError = state =>
    state[selectors.moduleIdentifier].postNfMotivationStoreSaveAndExitPageNameError.payload;
}
