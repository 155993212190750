/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP,
  POST_NF_CITIZEN_WORKSPACE_START_LIEAP,
  GET_FORM_DETAILS_BY_APPLICATION_FORM_ID,
  FETCH_DRAFT_APPLICATIONS,
} from './actionTypes';

import {
  fetchNfCitizenWorkspaceApplyLieapUtil,
  postNfCitizenWorkspaceStartLieapUtil,
  getFormDetailsByApplicationFormIdUtil,
  fetchDraftApplicationsUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFLIEAPModule slice of the store.
 *
 * @namespace NFLIEAPModuleActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_workspace/apply_lieap
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static fetchNfCitizenWorkspaceApplyLieap = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfCitizenWorkspaceApplyLieapUtil,
      FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfCitizenWorkspaceApplyLieap.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetFetchNfCitizenWorkspaceApplyLieap = (dispatch, data) => {
    const json = {
      type: FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfCitizenWorkspaceApplyLieap.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetFetchNfCitizenWorkspaceApplyLieapError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_CITIZEN_WORKSPACE_APPLY_LIEAP.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_citizen_workspace/start_lieap
   *
   * @param {NewUAOnlineApplicationType} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static postNfCitizenWorkspaceStartLieap = (dispatch, newUAOnlineApplicationType, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfCitizenWorkspaceStartLieapUtil.bind(null, newUAOnlineApplicationType),
      POST_NF_CITIZEN_WORKSPACE_START_LIEAP,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfCitizenWorkspaceStartLieap.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetPostNfCitizenWorkspaceStartLieap = (dispatch, data) => {
    const json = {
      type: POST_NF_CITIZEN_WORKSPACE_START_LIEAP.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfCitizenWorkspaceStartLieap.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetPostNfCitizenWorkspaceStartLieapError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_CITIZEN_WORKSPACE_START_LIEAP.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/form_details/{application_form_id}
   *
   * @param {string} application_form_id.
   * @param {string} isPartialApplication.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static getFormDetailsByApplicationFormId = (
    dispatch,
    application_form_id,
    isPartialApplication,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      getFormDetailsByApplicationFormIdUtil.bind(null, application_form_id, isPartialApplication),

      GET_FORM_DETAILS_BY_APPLICATION_FORM_ID,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getFormDetailsByApplicationFormId.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetGetFormDetailsByApplicationFormId = (dispatch, data) => {
    const json = {
      type: GET_FORM_DETAILS_BY_APPLICATION_FORM_ID.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getFormDetailsByApplicationFormId.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetGetFormDetailsByApplicationFormIdError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_FORM_DETAILS_BY_APPLICATION_FORM_ID.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/draft_applications
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static fetchDraftApplications = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchDraftApplicationsUtil,
      FETCH_DRAFT_APPLICATIONS,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchDraftApplications.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetFetchDraftApplications = (dispatch, data) => {
    const json = {
      type: FETCH_DRAFT_APPLICATIONS.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchDraftApplications.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFLIEAPModuleActions
   */
  static resetFetchDraftApplicationsError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_DRAFT_APPLICATIONS.failureAction);
  };
}
