/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'SampleModule' store object.
 * @namespace SampleModuleSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UASampleModule';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchOnlineCategories = state =>
    state[selectors.moduleIdentifier].fetchOnlineCategories.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static isFetchingFetchOnlineCategories = state =>
    state[selectors.moduleIdentifier].isFetchingFetchOnlineCategories.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchOnlineCategoriesError = state =>
    state[selectors.moduleIdentifier].fetchOnlineCategoriesError.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static isFetchingPostApplicationForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostApplicationForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static createApplicationFormError = state =>
    state[selectors.moduleIdentifier].createApplicationFormError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchSubmittedApplications = state =>
    state[selectors.moduleIdentifier].fetchSubmittedApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static isFetchingFetchSubmittedApplications = state =>
    state[selectors.moduleIdentifier].isFetchingFetchSubmittedApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchSubmittedApplicationsError = state =>
    state[selectors.moduleIdentifier].fetchSubmittedApplicationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static postApplications = state => state[selectors.moduleIdentifier].postApplications.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static isFetchingPostApplications = state =>
    state[selectors.moduleIdentifier].isFetchingPostApplications.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static postApplicationsError = state =>
    state[selectors.moduleIdentifier].postApplicationsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchActivities = state => state[selectors.moduleIdentifier].fetchActivities.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static isFetchingFetchActivities = state =>
    state[selectors.moduleIdentifier].isFetchingFetchActivities.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchActivitiesError = state =>
    state[selectors.moduleIdentifier].fetchActivitiesError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchAppeals = state => state[selectors.moduleIdentifier].fetchAppeals.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static isFetchingFetchAppeals = state =>
    state[selectors.moduleIdentifier].isFetchingFetchAppeals.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static fetchAppealsError = state => state[selectors.moduleIdentifier].fetchAppealsError.payload;

  /**
   * Selects the data from the store
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof SampleModuleSelectors
   */
  static selectedApplicationType = state =>
    state[selectors.moduleIdentifier].selectedApplicationType.payload;
}
