/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFCocMotivation' store object.
 * @namespace NFCocMotivationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFCocMotivation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfLifeeventApplyForCoc = state =>
    state[selectors.moduleIdentifier].fetchNfLifeeventApplyForCoc.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfLifeeventApplyForCoc = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfLifeeventApplyForCoc.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfLifeeventApplyForCocError = state =>
    state[selectors.moduleIdentifier].fetchNfLifeeventApplyForCocError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfLifeeventApplyForAr = state =>
    state[selectors.moduleIdentifier].fetchNfLifeeventApplyForAr.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfLifeeventApplyForAr = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfLifeeventApplyForAr.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfLifeeventApplyForArError = state =>
    state[selectors.moduleIdentifier].fetchNfLifeeventApplyForArError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCocUnsupportedMessage = state =>
    state[selectors.moduleIdentifier].fetchNfCocUnsupportedMessage.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfCocUnsupportedMessage = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCocUnsupportedMessage.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCocUnsupportedMessageError = state =>
    state[selectors.moduleIdentifier].fetchNfCocUnsupportedMessageError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postLifeEventsForm = state => state[selectors.moduleIdentifier].postLifeEventsForm.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingPostLifeEventsForm = state =>
    state[selectors.moduleIdentifier].isFetchingPostLifeEventsForm.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postLifeEventsFormError = state =>
    state[selectors.moduleIdentifier].postLifeEventsFormError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postNfMotivationFinishCoc = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishCoc.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingPostNfMotivationFinishCoc = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationFinishCoc.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postNfMotivationFinishCocError = state =>
    state[selectors.moduleIdentifier].postNfMotivationFinishCocError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postNfMotivationSignAndSubmitCoc = state =>
    state[selectors.moduleIdentifier].postNfMotivationSignAndSubmitCoc.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingPostNfMotivationSignAndSubmitCoc = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationSignAndSubmitCoc.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postNfMotivationSignAndSubmitCocError = state =>
    state[selectors.moduleIdentifier].postNfMotivationSignAndSubmitCocError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfMotivationEsignatureByMotivation = state =>
    state[selectors.moduleIdentifier].getNfMotivationEsignatureByMotivation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingGetNfMotivationEsignatureByMotivation = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationEsignatureByMotivation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfMotivationEsignatureByMotivationError = state =>
    state[selectors.moduleIdentifier].getNfMotivationEsignatureByMotivationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfMotivationCocConfirmationDetailsByMotivationId = state =>
    state[selectors.moduleIdentifier].getNfMotivationCocConfirmationDetailsByMotivationId.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingGetNfMotivationCocConfirmationDetailsByMotivationId = state =>
    state[selectors.moduleIdentifier].isFetchingGetNfMotivationCocConfirmationDetailsByMotivationId
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfMotivationCocConfirmationDetailsByMotivationIdError = state =>
    state[selectors.moduleIdentifier].getNfMotivationCocConfirmationDetailsByMotivationIdError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfLifeEventsListSubmittedLifeevents = state =>
    state[selectors.moduleIdentifier].fetchNfLifeEventsListSubmittedLifeevents.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfLifeEventsListSubmittedLifeevents = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfLifeEventsListSubmittedLifeevents.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfLifeEventsListSubmittedLifeeventsError = state =>
    state[selectors.moduleIdentifier].fetchNfLifeEventsListSubmittedLifeeventsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCitizenPortalEvidenceReadMyInfoTabDetails = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenPortalEvidenceReadMyInfoTabDetails.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetails = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetails
      .payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsError = state =>
    state[selectors.moduleIdentifier].fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID = state =>
    state[selectors.moduleIdentifier].getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID = state =>
    state[selectors.moduleIdentifier]
      .isFetchingGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDError = state =>
    state[selectors.moduleIdentifier].getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDError
      .payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID = state =>
    state[selectors.moduleIdentifier].getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID
      .payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID = state =>
    state[selectors.moduleIdentifier]
      .isFetchingGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDError = state =>
    state[selectors.moduleIdentifier]
      .getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postNfMotivationSetupStartMotivation = state =>
    state[selectors.moduleIdentifier].postNfMotivationSetupStartMotivation.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingPostNfMotivationSetupStartMotivation = state =>
    state[selectors.moduleIdentifier].isFetchingPostNfMotivationSetupStartMotivation.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static postNfMotivationSetupStartMotivationError = state =>
    state[selectors.moduleIdentifier].postNfMotivationSetupStartMotivationError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCocApplyForSnapCoc = state =>
    state[selectors.moduleIdentifier].fetchNfCocApplyForSnapCoc.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfCocApplyForSnapCoc = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCocApplyForSnapCoc.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCocApplyForSnapCocError = state =>
    state[selectors.moduleIdentifier].fetchNfCocApplyForSnapCocError.payload;

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCocApplyForFnsCoc = state =>
    state[selectors.moduleIdentifier].fetchNfCocApplyForFnsCoc.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static isFetchingFetchNfCocApplyForFnsCoc = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfCocApplyForFnsCoc.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFCocMotivationSelectors
   */
  static fetchNfCocApplyForFnsCocError = state =>
    state[selectors.moduleIdentifier].fetchNfCocApplyForFnsCocError.payload;
}
