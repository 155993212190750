/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/**
 * Contains the selectors that select state from the 'NFOrganisation' store object.
 * @namespace NFOrganisationSelectors
 */
export default class selectors {
  /**
   * @private
   */
  static moduleIdentifier = 'UANFOrganisation';

  /**
   * Selects the data from the store.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFOrganisationSelectors
   */
  static fetchNfExternalURLConfig = state =>
    state[selectors.moduleIdentifier].fetchNfExternalURLConfig.payload;

  /**
   * Selects the flag that is set while the get data request is in progress.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFOrganisationSelectors
   */
  static isFetchingFetchNfExternalURLConfig = state =>
    state[selectors.moduleIdentifier].isFetchingFetchNfExternalURLConfig.payload;

  /**
   * Selects the data errors for the server request.
   *
   * @param {Object} state
   * @function
   * @static
   * @memberof NFOrganisationSelectors
   */
  static fetchNfExternalURLConfigError = state =>
    state[selectors.moduleIdentifier].fetchNfExternalURLConfigError.payload;
}
