/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from './actions';

import selectors from './selectors';

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/motivation/setup-and-start
 *
 * postMotivationSetupAndStart - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostMotivationSetupAndStart = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/motivation/setup-and-start
   *
   * @param {NewUAMotivationType} data object that will be posted to the api
   * @returns {Promise}
   */
  const postMotivationSetupAndStart = useCallback(
    newUAMotivationType => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postMotivationSetupAndStart(
          dispatch,
          newUAMotivationType,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postMotivationSetupAndStart,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/apply_for_standalone_ridp
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useFetchNfMotivationApplyForStandaloneRidp = (deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state => selectors.fetchNfMotivationApplyForStandaloneRidp(state));

  const resetData = useCallback(
    data => {
      actions.resetFetchNfMotivationApplyForStandaloneRidp(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(() => {
    setResponse({
      ...response,
      isFetching: true,
    });
    actions.fetchNfMotivationApplyForStandaloneRidp(dispatch, (success, data) => {
      setResponse({
        data: success ? data : null,
        isFetching: false,
        error: success ? null : data,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hook is used to Call the api on the component mount and
 * dispatch the results to be cached in redux.
 * api: /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
 *
 * If the data is already stored in Redux, the API is not called again.
 *
 * By default, this hook is only executed once for the component, just after the component is mounted.
 *
 * The frequency of executions can be changed using the param deps. The deps param is passed to the useEffect hook.
 *
 * Returns:
 *
 * data - Response data from the api call
 *
 * error - Response errors.
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary.
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * reFetchData - Forces a new API call
 *
 * resetData - Clears the data stored in Redux
 *
 * @param {string} iegExecutionID.
 * @param {Array} deps - Defines when this hook should be executed. It is passed to the useEffect hook used internally.
 * For more details see: https://reactjs.org/docs/hooks-effect.html
 */
const useGetNfMotivationMotivationTypeByIegExecutionID = (iegExecutionID, deps = []) => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: true,
    error: null,
  });

  const cachedData = useSelector(state =>
    selectors.getNfMotivationMotivationTypeByIegExecutionID(state)
  );

  const resetData = useCallback(
    data => {
      actions.resetGetNfMotivationMotivationTypeByIegExecutionID(dispatch, data);
      setResponse({
        data,
        isFetching: false,
        error: null,
      });
    },
    [dispatch]
  );

  const fetchData = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      actions.getNfMotivationMotivationTypeByIegExecutionID(
        dispatch,
        iegExecutionID,
        (success, data) => {
          setResponse({
            data: success ? data : null,
            isFetching: false,
            error: success ? null : data,
          });
        }
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!cachedData) {
      fetchData(iegExecutionID);
    } else {
      setResponse({
        data: cachedData,
        isFetching: false,
        error: null,
      });
    }
  }, deps);

  return {
    ...response,
    reFetchData: fetchData,
    resetData,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/finish_standalone_ridp
 *
 * postNfMotivationFinishStandaloneRidp - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const usePostNfMotivationFinishStandaloneRidp = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/finish_standalone_ridp
   *
   * @param {NewUAMotivationIEGKey} data object that will be posted to the api
   * @returns {Promise}
   */
  const postNfMotivationFinishStandaloneRidp = useCallback(
    newUAMotivationIEGKey => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.postNfMotivationFinishStandaloneRidp(
          dispatch,
          newUAMotivationIEGKey,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    postNfMotivationFinishStandaloneRidp,
  };
};

/**
 * This hooks returns a function to call the api and
 * dispatch the results to the redux store.
 * api: /v1/ua/nf_motivation/{iegExecutionID}
 *
 * deleteNfMotivationByIegExecutionID - calls the api and returns a Promise
 *
 * isFetching - Boolean to indicate if the server is processing the request
 *
 * data - Response data from the api call
 *
 * error - Response errors
 * Note: The errors can be automatically handled by the HOC withErrorsBoundary
 * See: https://www.ibm.com/support/knowledgecenter/en/SS8S5A_7.0.8/com.ibm.curam.universalaccess.doc/CitizenEngagement/c_CECUST_error-handling.html
 */
const useDeleteNfMotivationByIegExecutionID = () => {
  const dispatch = useDispatch();
  const [response, setResponse] = useState({
    data: null,
    isFetching: false,
    error: null,
  });

  /**
   * call the api and
   * dispatch the results to the redux store.
   * api: /v1/ua/nf_motivation/{iegExecutionID}
   *
   * @param {string} iegExecutionID.
   * @returns {Promise}
   */
  const deleteNfMotivationByIegExecutionID = useCallback(
    iegExecutionID => {
      setResponse({
        ...response,
        isFetching: true,
      });
      return new Promise((resolve, reject) => {
        actions.deleteNfMotivationByIegExecutionID(
          dispatch,
          iegExecutionID,
          (success, responseData) => {
            if (success) {
              resolve(responseData);
              setResponse({
                data: responseData,
                isFetching: false,
                error: null,
              });
            } else {
              reject(responseData);
              setResponse({
                data: null,
                isFetching: false,
                error: responseData,
              });
            }
          }
        );
      });
    },
    [dispatch]
  );

  return {
    ...response,
    deleteNfMotivationByIegExecutionID,
  };
};

export default {
  usePostMotivationSetupAndStart,
  useFetchNfMotivationApplyForStandaloneRidp,
  useGetNfMotivationMotivationTypeByIegExecutionID,
  usePostNfMotivationFinishStandaloneRidp,
  useDeleteNfMotivationByIegExecutionID,
};
