/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  FETCH_NF_RIDP_PROFILE_INFO,
  POST_NF_RIDP_UPDATE_PRI_CONTACT,
  POST_NF_RIDP_UPDATE_SEC_CONTACT,
  POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT,
  POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER,
  POST_NF_RIDP_REVOKE_ALL,
  GET_CODETABLES_BY_TABLE_NAME,
} from './actionTypes';

import {
  fetchNfRidpProfileInfoUtil,
  postNfRidpUpdatePriContactUtil,
  postNfRidpUpdateSecContactUtil,
  postNfRidpResendCodeUpdateContactUtil,
  postNfRidpUpdateConfirmationNumberUtil,
  postNfRidpRevokeAllUtil,
  getCodetablesByTableNameUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFProfile slice of the store.
 *
 * @namespace NFProfileActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_ridp/profile_info
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static fetchNfRidpProfileInfo = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfRidpProfileInfoUtil,
      FETCH_NF_RIDP_PROFILE_INFO,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfRidpProfileInfo.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetFetchNfRidpProfileInfo = (dispatch, data) => {
    const json = {
      type: FETCH_NF_RIDP_PROFILE_INFO.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfRidpProfileInfo.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetFetchNfRidpProfileInfoError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_RIDP_PROFILE_INFO.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_ridp/update_pri_contact
   *
   * @param {NewNFUpdateUserContactDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static postNfRidpUpdatePriContact = (dispatch, newNFUpdateUserContactDetails, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfRidpUpdatePriContactUtil.bind(null, newNFUpdateUserContactDetails),
      POST_NF_RIDP_UPDATE_PRI_CONTACT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfRidpUpdatePriContact.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpUpdatePriContact = (dispatch, data) => {
    const json = {
      type: POST_NF_RIDP_UPDATE_PRI_CONTACT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfRidpUpdatePriContact.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpUpdatePriContactError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_RIDP_UPDATE_PRI_CONTACT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_ridp/update_sec_contact
   *
   * @param {NewNFUpdateUserContactDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static postNfRidpUpdateSecContact = (dispatch, newNFUpdateUserContactDetails, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfRidpUpdateSecContactUtil.bind(null, newNFUpdateUserContactDetails),
      POST_NF_RIDP_UPDATE_SEC_CONTACT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfRidpUpdateSecContact.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpUpdateSecContact = (dispatch, data) => {
    const json = {
      type: POST_NF_RIDP_UPDATE_SEC_CONTACT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfRidpUpdateSecContact.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpUpdateSecContactError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_RIDP_UPDATE_SEC_CONTACT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_ridp/resend_code_update_contact
   *
   * @param {NewNFUpdateUserContactDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static postNfRidpResendCodeUpdateContact = (
    dispatch,
    newNFUpdateUserContactDetails,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfRidpResendCodeUpdateContactUtil.bind(null, newNFUpdateUserContactDetails),
      POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfRidpResendCodeUpdateContact.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpResendCodeUpdateContact = (dispatch, data) => {
    const json = {
      type: POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfRidpResendCodeUpdateContact.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpResendCodeUpdateContactError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_RIDP_RESEND_CODE_UPDATE_CONTACT.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_ridp/update_confirmation_number
   *
   * @param {NewNFUserContactDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static postNfRidpUpdateConfirmationNumber = (dispatch, newNFUserContactDetails, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfRidpUpdateConfirmationNumberUtil.bind(null, newNFUserContactDetails),
      POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfRidpUpdateConfirmationNumber.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpUpdateConfirmationNumber = (dispatch, data) => {
    const json = {
      type: POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfRidpUpdateConfirmationNumber.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpUpdateConfirmationNumberError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_RIDP_UPDATE_CONFIRMATION_NUMBER.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_ridp/revoke_all
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static postNfRidpRevokeAll = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfRidpRevokeAllUtil.bind(null, {}),
      POST_NF_RIDP_REVOKE_ALL,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfRidpRevokeAll.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpRevokeAll = (dispatch, data) => {
    const json = {
      type: POST_NF_RIDP_REVOKE_ALL.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfRidpRevokeAll.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetPostNfRidpRevokeAllError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, POST_NF_RIDP_REVOKE_ALL.failureAction);
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/codetables/{table_name}
   *
   * @param {string} table_name.
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFProfileActions
   */
  static getCodetablesByTableName = (dispatch, table_name, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      getCodetablesByTableNameUtil.bind(null, table_name),

      GET_CODETABLES_BY_TABLE_NAME,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to getCodetablesByTableName.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFProfileActions
   */
  static resetGetCodetablesByTableName = (dispatch, data) => {
    const json = {
      type: GET_CODETABLES_BY_TABLE_NAME.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to getCodetablesByTableName.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFProfileActions
   */
  static resetGetCodetablesByTableNameError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, GET_CODETABLES_BY_TABLE_NAME.failureAction);
  };
}
