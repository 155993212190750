/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { FETCH_NF_ENERGY_IS_ENERGY_ACTIVE } from './actionTypes';

import { fetchNfEnergyIsEnergyActiveUtil } from './utils';

/**
 * Contains the action creator functions that update the NFEnergy slice of the store.
 *
 * @namespace NFEnergyActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_energy/is_energy_active
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFEnergyActions
   */
  static fetchNfEnergyIsEnergyActive = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfEnergyIsEnergyActiveUtil,
      FETCH_NF_ENERGY_IS_ENERGY_ACTIVE,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfEnergyIsEnergyActive.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFEnergyActions
   */
  static resetFetchNfEnergyIsEnergyActive = (dispatch, data) => {
    const json = {
      type: FETCH_NF_ENERGY_IS_ENERGY_ACTIVE.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfEnergyIsEnergyActive.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFEnergyActions
   */
  static resetFetchNfEnergyIsEnergyActiveError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_ENERGY_IS_ENERGY_ACTIVE.failureAction);
  };
}
