/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the NFLifeEventTypeResponse
 *
 * @class NFLifeEventTypeResponse
 */
class NFLifeEventTypeResponse {
  /**
   * Creates an instance of NFLifeEventTypeResponse.
   * @param {*} json
   * @memberof NFLifeEventTypeResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventTypeName = json.lifeEventTypeName;

      // string
      this.lifeEventContextId = json.lifeEventContextId;

      // string
      this.schema = json.schema;
    }
  }
}

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the CoCUnsupportScenDetails
 *
 * @class CoCUnsupportScenDetails
 */
class CoCUnsupportScenDetails {
  /**
   * Creates an instance of CoCUnsupportScenDetails.
   * @param {*} json
   * @memberof CoCUnsupportScenDetails
   */
  constructor(json = null) {
    if (json) {
      // object - CodeTableType
      if (json.unsupportedScenarioCode) {
        this.unsupportedScenarioCode = new CodeTableType(json.unsupportedScenarioCode);
      }
    }
  }
}

/**
 * Models the CoCUnsupportScenDetailsList
 *
 * @class CoCUnsupportScenDetailsList
 */
class CoCUnsupportScenDetailsList {
  /**
   * Creates an instance of CoCUnsupportScenDetailsList.
   * @param {*} json
   * @memberof CoCUnsupportScenDetailsList
   */
  constructor(json = null) {
    if (json) {
      // string
      this.unsupportedScenarioMessage = json.unsupportedScenarioMessage;

      // array - CoCUnsupportScenDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new CoCUnsupportScenDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new CoCUnsupportScenDetails(object));
      }
    }
  }
}

/**
 * Models the NFChangeOfCircumstanceResponse
 *
 * @class NFChangeOfCircumstanceResponse
 */
class NFChangeOfCircumstanceResponse {
  /**
   * Creates an instance of NFChangeOfCircumstanceResponse.
   * @param {*} json
   * @memberof NFChangeOfCircumstanceResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // object - CoCUnsupportScenDetailsList
      if (json.dtls) {
        this.dtls = new CoCUnsupportScenDetailsList(json.dtls);
      }
    }
  }
}

/**
 * Models the NewUALifeEventContext
 *
 * @class NewUALifeEventContext
 */
class NewUALifeEventContext {
  /**
   * Creates an instance of NewUALifeEventContext.
   * @param {*} json
   * @memberof NewUALifeEventContext
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventContextId = json.lifeEventContextId;

      // string
      this.name = json.name;

      // string
      this.description = json.description;

      // integer
      this.common = json.common;

      // string
      this.imageName = json.imageName;

      // integer
      this.displayOrder = json.displayOrder;

      // string
      this.additionalInfo = json.additionalInfo;

      // string
      this.submissionText = json.submissionText;

      // string
      this.launchDescription = json.launchDescription;

      // boolean
      this.isCitizenConsentEnabled = json.isCitizenConsentEnabled;

      // boolean
      this.isCitizenConsentOneChoiceEnabled = json.isCitizenConsentOneChoiceEnabled;

      // string
      this.imageAltText = json.imageAltText;
    }
  }
}

/**
 * Models the NewUALifeEventFormIdResponse
 *
 * @class NewUALifeEventFormIdResponse
 */
class NewUALifeEventFormIdResponse {
  /**
   * Creates an instance of NewUALifeEventFormIdResponse.
   * @param {*} json
   * @memberof NewUALifeEventFormIdResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.formId = json.formId;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKey
 *
 * @class NewUAMotivationIEGKey
 */
class NewUAMotivationIEGKey {
  /**
   * Creates an instance of NewUAMotivationIEGKey.
   * @param {*} json
   * @memberof NewUAMotivationIEGKey
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NewNFMotivationFinishResponseResponse
 *
 * @class NewNFMotivationFinishResponseResponse
 */
class NewNFMotivationFinishResponseResponse {
  /**
   * Creates an instance of NewNFMotivationFinishResponseResponse.
   * @param {*} json
   * @memberof NewNFMotivationFinishResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // string
      this.application_id = json.application_id;

      // string
      this.applicationReference = json.applicationReference;

      // string
      this.motivationID = json.motivationID;

      // boolean
      this.isARCOC = json.isARCOC;

      // boolean
      this.hasAuthRep = json.hasAuthRep;
    }
  }
}

/**
 * Models the NewCESignAndSubmit
 *
 * @class NewCESignAndSubmit
 */
class NewCESignAndSubmit {
  /**
   * Creates an instance of NewCESignAndSubmit.
   * @param {*} json
   * @memberof NewCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.perjuryESignatureInd = json.perjuryESignatureInd;

      // boolean
      this.defaultRenewalInd = json.defaultRenewalInd;

      // boolean
      this.absentParentESignatureInd = json.absentParentESignatureInd;

      // boolean
      this.informationChangedESignatureInd = json.informationChangedESignatureInd;

      // string
      this.sigLastName = json.sigLastName;

      // string
      this.sigFirstName = json.sigFirstName;

      // string
      this.middleInitial = json.middleInitial;

      // string
      this.renewalDuration = json.renewalDuration;

      // string
      this.motivation_id = json.motivation_id;

      // string
      this.categoryId = json.categoryId;

      // string
      this.navigatorID = json.navigatorID;
    }
  }
}

/**
 * Models the NewNFCESignAndSubmit
 *
 * @class NewNFCESignAndSubmit
 */
class NewNFCESignAndSubmit {
  /**
   * Creates an instance of NewNFCESignAndSubmit.
   * @param {*} json
   * @memberof NewNFCESignAndSubmit
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfMedicaidTransportationRespInd = json.nfMedicaidTransportationRespInd;

      // boolean
      this.rightsAndResponsibilitiesInd = json.rightsAndResponsibilitiesInd;

      // boolean
      this.nfUAInformationChangedESignatureInd = json.nfUAInformationChangedESignatureInd;

      // boolean
      this.nfMedicaidNoticeOfPrivacyInd = json.nfMedicaidNoticeOfPrivacyInd;

      // boolean
      this.authorizeReleaseInformationInd = json.authorizeReleaseInformationInd;

      // string
      this.iegExecutionID = json.iegExecutionID;

      // string
      this.motivationID = json.motivationID;

      // object - NewCESignAndSubmit
      if (json.signAndSubmitDetails) {
        this.signAndSubmitDetails = new NewCESignAndSubmit(json.signAndSubmitDetails);
      }
    }
  }
}

/**
 * Models the NewCESignAndSubmitResponseResponse
 *
 * @class NewCESignAndSubmitResponseResponse
 */
class NewCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the NewErrorResponse
 *
 * @class NewErrorResponse
 */
class NewErrorResponse {
  /**
   * Creates an instance of NewErrorResponse.
   * @param {*} json
   * @memberof NewErrorResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.message = json.message;

      // string
      this.level = json.level;

      // string
      this.code = json.code;
    }
  }
}

/**
 * Models the NewNFCESignAndSubmitResponseResponse
 *
 * @class NewNFCESignAndSubmitResponseResponse
 */
class NewNFCESignAndSubmitResponseResponse {
  /**
   * Creates an instance of NewNFCESignAndSubmitResponseResponse.
   * @param {*} json
   * @memberof NewNFCESignAndSubmitResponseResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // boolean
      this.isPresumptiveFlow = json.isPresumptiveFlow;

      // string
      this.intakeapplicationID = json.intakeapplicationID;

      // object - NewCESignAndSubmitResponseResponse
      if (json.signAndSubmitResponse) {
        this.signAndSubmitResponse = new NewCESignAndSubmitResponseResponse(
          json.signAndSubmitResponse
        );
      }

      // array - NewErrorResponse
      if (json.errors) {
        this.errors = json.errors.map(object => new NewErrorResponse(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new NewErrorResponse(object));
      }
    }
  }
}

/**
 * Models the CEReadESignatureDetails
 *
 * @class CEReadESignatureDetails
 */
class CEReadESignatureDetails {
  /**
   * Creates an instance of CEReadESignatureDetails.
   * @param {*} json
   * @memberof CEReadESignatureDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.showConsentAccuracyQuestion = json.showConsentAccuracyQuestion;

      // boolean
      this.hasAbsentParent = json.hasAbsentParent;

      // boolean
      this.isAnnualRenewalMotivationType = json.isAnnualRenewalMotivationType;

      // boolean
      this.showConsentUpdateInfoQuestion = json.showConsentUpdateInfoQuestion;

      // boolean
      this.showCooperateMedicalQuestion = json.showCooperateMedicalQuestion;

      // boolean
      this.showCoverageRenewalQuestion = json.showCoverageRenewalQuestion;

      // boolean
      this.showFullNameInputs = json.showFullNameInputs;

      // boolean
      this.showMedicalExpenseQuestion = json.showMedicalExpenseQuestion;

      // boolean
      this.isEligibleForMA = json.isEligibleForMA;
    }
  }
}

/**
 * Models the NFCEReadSignatureDetailsResponse
 *
 * @class NFCEReadSignatureDetailsResponse
 */
class NFCEReadSignatureDetailsResponse {
  /**
   * Creates an instance of NFCEReadSignatureDetailsResponse.
   * @param {*} json
   * @memberof NFCEReadSignatureDetailsResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.showUAMedicalAssistanceQuestion = json.showUAMedicalAssistanceQuestion;

      // string
      this.username = json.username;

      // object - CEReadESignatureDetails
      if (json.eSignatureDetails) {
        this.eSignatureDetails = new CEReadESignatureDetails(json.eSignatureDetails);
      }
    }
  }
}

/**
 * Models the CESignAndSubmitResponse
 *
 * @class CESignAndSubmitResponse
 */
class CESignAndSubmitResponse {
  /**
   * Creates an instance of CESignAndSubmitResponse.
   * @param {*} json
   * @memberof CESignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.contact = json.contact;

      // string
      this.reference = json.reference;
    }
  }
}

/**
 * Models the Error
 *
 * @class Error
 */
class Error {
  /**
   * Creates an instance of Error.
   * @param {*} json
   * @memberof Error
   */
  constructor(json = null) {
    if (json) {
      // integer
      this.code = json.code;

      // string
      this.message = json.message;

      // string
      this.message_id = json.message_id;

      // string
      this.level = json.level;
    }
  }
}

/**
 * Models the NFCESignAndSubmitResponse
 *
 * @class NFCESignAndSubmitResponse
 */
class NFCESignAndSubmitResponse {
  /**
   * Creates an instance of NFCESignAndSubmitResponse.
   * @param {*} json
   * @memberof NFCESignAndSubmitResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.userRole = json.userRole;

      // boolean
      this.isPresumptiveFlow = json.isPresumptiveFlow;

      // string
      this.intakeapplicationID = json.intakeapplicationID;

      // object - CESignAndSubmitResponse
      if (json.signAndSubmitResponse) {
        this.signAndSubmitResponse = new CESignAndSubmitResponse(json.signAndSubmitResponse);
      }

      // array - Error
      if (json.errors) {
        this.errors = json.errors.map(object => new Error(object));
      } else if (json.constructor === Array) {
        this.errors = json.map(object => new Error(object));
      }
    }
  }
}

/**
 * Models the SubmittedLifeEventDetails
 *
 * @class SubmittedLifeEventDetails
 */
class SubmittedLifeEventDetails {
  /**
   * Creates an instance of SubmittedLifeEventDetails.
   * @param {*} json
   * @memberof SubmittedLifeEventDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.lifeEventName = json.lifeEventName;

      // string
      this.lifeEventID = json.lifeEventID;

      // string
      this.submittedDate = json.submittedDate;

      // boolean
      this.hasAnswerScript = json.hasAnswerScript;

      // string
      this.remoteSystems = json.remoteSystems;

      // boolean
      this.hasRecommendationsRuleSet = json.hasRecommendationsRuleSet;
    }
  }
}

/**
 * Models the NFSubmittedLifeEventsDetails
 *
 * @class NFSubmittedLifeEventsDetails
 */
class NFSubmittedLifeEventsDetails {
  /**
   * Creates an instance of NFSubmittedLifeEventsDetails.
   * @param {*} json
   * @memberof NFSubmittedLifeEventsDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.displayPDFInd = json.displayPDFInd;

      // string
      this.applicationUrl = json.applicationUrl;

      // string
      this.applicationName = json.applicationName;

      // string
      this.intakeApplicationID = json.intakeApplicationID;

      // string
      this.attachmentID = json.attachmentID;

      // object - SubmittedLifeEventDetails
      if (json.dtls) {
        this.dtls = new SubmittedLifeEventDetails(json.dtls);
      }
    }
  }
}

/**
 * Models the NFSubmittedLifeEventsDetailsList
 *
 * @class NFSubmittedLifeEventsDetailsList
 */
class NFSubmittedLifeEventsDetailsList {
  /**
   * Creates an instance of NFSubmittedLifeEventsDetailsList.
   * @param {*} json
   * @memberof NFSubmittedLifeEventsDetailsList
   */
  constructor(json = null) {
    if (json) {
      // string
      this.header = json.header;

      // string
      this.playerID = json.playerID;

      // array - NFSubmittedLifeEventsDetails
      if (json.listDtls) {
        this.listDtls = json.listDtls.map(object => new NFSubmittedLifeEventsDetails(object));
      } else if (json.constructor === Array) {
        this.listDtls = json.map(object => new NFSubmittedLifeEventsDetails(object));
      }
    }
  }
}

/**
 * Models the NFCitizenLifeEventsResponse
 *
 * @class NFCitizenLifeEventsResponse
 */
class NFCitizenLifeEventsResponse {
  /**
   * Creates an instance of NFCitizenLifeEventsResponse.
   * @param {*} json
   * @memberof NFCitizenLifeEventsResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isScuccess = json.isScuccess;

      // object - NFSubmittedLifeEventsDetailsList
      if (json.dtls) {
        this.dtls = new NFSubmittedLifeEventsDetailsList(json.dtls);
      }
    }
  }
}

/**
 * Models the HouseholdMemberSummaryDetails
 *
 * @class HouseholdMemberSummaryDetails
 */
class HouseholdMemberSummaryDetails {
  /**
   * Creates an instance of HouseholdMemberSummaryDetails.
   * @param {*} json
   * @memberof HouseholdMemberSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.ssn = json.ssn;

      // object - CodeTableType
      if (json.citizenshipStatus) {
        this.citizenshipStatus = new CodeTableType(json.citizenshipStatus);
      }

      // string
      this.dateOfBirth = json.dateOfBirth;

      // string
      this.name = json.name;
    }
  }
}

/**
 * Models the IncomeSummaryDetails
 *
 * @class IncomeSummaryDetails
 */
class IncomeSummaryDetails {
  /**
   * Creates an instance of IncomeSummaryDetails.
   * @param {*} json
   * @memberof IncomeSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.memberName = json.memberName;

      // number
      this.amount = json.amount;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // object - CodeTableType
      if (json.frequency) {
        this.frequency = new CodeTableType(json.frequency);
      }
    }
  }
}

/**
 * Models the LivingArrangementsSummaryDetails
 *
 * @class LivingArrangementsSummaryDetails
 */
class LivingArrangementsSummaryDetails {
  /**
   * Creates an instance of LivingArrangementsSummaryDetails.
   * @param {*} json
   * @memberof LivingArrangementsSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.address = json.address;

      // string
      this.addressOccupants = json.addressOccupants;
    }
  }
}

/**
 * Models the TaxFilingSummaryDetails
 *
 * @class TaxFilingSummaryDetails
 */
class TaxFilingSummaryDetails {
  /**
   * Creates an instance of TaxFilingSummaryDetails.
   * @param {*} json
   * @memberof TaxFilingSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.memberName = json.memberName;

      // string
      this.dependentMemberName = json.dependentMemberName;

      // object - CodeTableType
      if (json.status) {
        this.status = new CodeTableType(json.status);
      }
    }
  }
}

/**
 * Models the CitizenAccountSummaryDetails
 *
 * @class CitizenAccountSummaryDetails
 */
class CitizenAccountSummaryDetails {
  /**
   * Creates an instance of CitizenAccountSummaryDetails.
   * @param {*} json
   * @memberof CitizenAccountSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.pendingLifeEventInd = json.pendingLifeEventInd;

      // boolean
      this.displayIncomeSummaryInd = json.displayIncomeSummaryInd;

      // boolean
      this.displayTaxFilingSummaryInd = json.displayTaxFilingSummaryInd;

      // boolean
      this.displayLivingArrangementSummaryInd = json.displayLivingArrangementSummaryInd;

      // boolean
      this.pendingAnnualRenewalInd = json.pendingAnnualRenewalInd;

      // boolean
      this.pendingChangeOfCircumstanceEventInd = json.pendingChangeOfCircumstanceEventInd;

      // array - HouseholdMemberSummaryDetails
      if (json.householdMemberList) {
        this.householdMemberList = json.householdMemberList.map(
          object => new HouseholdMemberSummaryDetails(object)
        );
      } else if (json.constructor === Array) {
        this.householdMemberList = json.map(object => new HouseholdMemberSummaryDetails(object));
      }

      // array - IncomeSummaryDetails
      if (json.incomeList) {
        this.incomeList = json.incomeList.map(object => new IncomeSummaryDetails(object));
      } else if (json.constructor === Array) {
        this.incomeList = json.map(object => new IncomeSummaryDetails(object));
      }

      // array - LivingArrangementsSummaryDetails
      if (json.livingArrangmentList) {
        this.livingArrangmentList = json.livingArrangmentList.map(
          object => new LivingArrangementsSummaryDetails(object)
        );
      } else if (json.constructor === Array) {
        this.livingArrangmentList = json.map(
          object => new LivingArrangementsSummaryDetails(object)
        );
      }

      // array - TaxFilingSummaryDetails
      if (json.taxFilingList) {
        this.taxFilingList = json.taxFilingList.map(object => new TaxFilingSummaryDetails(object));
      } else if (json.constructor === Array) {
        this.taxFilingList = json.map(object => new TaxFilingSummaryDetails(object));
      }
    }
  }
}

/**
 * Models the NFAddressDetails
 *
 * @class NFAddressDetails
 */
class NFAddressDetails {
  /**
   * Creates an instance of NFAddressDetails.
   * @param {*} json
   * @memberof NFAddressDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.address = json.address;

      // string
      this.addressType = json.addressType;
    }
  }
}

/**
 * Models the NFCitizenCaseInfoDetails
 *
 * @class NFCitizenCaseInfoDetails
 */
class NFCitizenCaseInfoDetails {
  /**
   * Creates an instance of NFCitizenCaseInfoDetails.
   * @param {*} json
   * @memberof NFCitizenCaseInfoDetails
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfSNAPRecertInd = json.nfSNAPRecertInd;

      // boolean
      this.nfFNSRecertInd = json.nfFNSRecertInd;

      // boolean
      this.nfSNAPCOCInd = json.nfSNAPCOCInd;

      // boolean
      this.nfFNSCOCInd = json.nfFNSCOCInd;
    }
  }
}

/**
 * Models the NFHouseholdMemberSummaryDetails
 *
 * @class NFHouseholdMemberSummaryDetails
 */
class NFHouseholdMemberSummaryDetails {
  /**
   * Creates an instance of NFHouseholdMemberSummaryDetails.
   * @param {*} json
   * @memberof NFHouseholdMemberSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfType = json.nfType;

      // string
      this.nfSsn = json.nfSsn;

      // string
      this.nfCitizenshipStatus = json.nfCitizenshipStatus;

      // string
      this.nfName = json.nfName;

      // string
      this.nfDateOfBirth = json.nfDateOfBirth;
    }
  }
}

/**
 * Models the NFIncomeSummaryDetails
 *
 * @class NFIncomeSummaryDetails
 */
class NFIncomeSummaryDetails {
  /**
   * Creates an instance of NFIncomeSummaryDetails.
   * @param {*} json
   * @memberof NFIncomeSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.memberName = json.memberName;

      // object - CodeTableType
      if (json.frequency) {
        this.frequency = new CodeTableType(json.frequency);
      }

      // number
      this.amount = json.amount;

      // object - CodeTableType
      if (json.type) {
        this.type = new CodeTableType(json.type);
      }

      // string
      this.employerName = json.employerName;
    }
  }
}

/**
 * Models the NFPregnancyInfo
 *
 * @class NFPregnancyInfo
 */
class NFPregnancyInfo {
  /**
   * Creates an instance of NFPregnancyInfo.
   * @param {*} json
   * @memberof NFPregnancyInfo
   */
  constructor(json = null) {
    if (json) {
      // string
      this.pregnancyMemberName = json.pregnancyMemberName;

      // string
      this.pregnancyDueDate = json.pregnancyDueDate;

      // string
      this.pregnancyNoOfUnborn = json.pregnancyNoOfUnborn;
    }
  }
}

/**
 * Models the NFCitizenAccountSummaryDetails
 *
 * @class NFCitizenAccountSummaryDetails
 */
class NFCitizenAccountSummaryDetails {
  /**
   * Creates an instance of NFCitizenAccountSummaryDetails.
   * @param {*} json
   * @memberof NFCitizenAccountSummaryDetails
   */
  constructor(json = null) {
    if (json) {
      // object - CitizenAccountSummaryDetails
      if (json.citizenAccountdtls) {
        this.citizenAccountdtls = new CitizenAccountSummaryDetails(json.citizenAccountdtls);
      }

      // array - NFAddressDetails
      if (json.addressDtls) {
        this.addressDtls = json.addressDtls.map(object => new NFAddressDetails(object));
      } else if (json.constructor === Array) {
        this.addressDtls = json.map(object => new NFAddressDetails(object));
      }

      // object - NFCitizenCaseInfoDetails
      if (json.nfCitizenCaseDtls) {
        this.nfCitizenCaseDtls = new NFCitizenCaseInfoDetails(json.nfCitizenCaseDtls);
      }

      // array - NFHouseholdMemberSummaryDetails
      if (json.houseHoldDtls) {
        this.houseHoldDtls = json.houseHoldDtls.map(
          object => new NFHouseholdMemberSummaryDetails(object)
        );
      } else if (json.constructor === Array) {
        this.houseHoldDtls = json.map(object => new NFHouseholdMemberSummaryDetails(object));
      }

      // array - NFIncomeSummaryDetails
      if (json.nfIncomeList) {
        this.nfIncomeList = json.nfIncomeList.map(object => new NFIncomeSummaryDetails(object));
      } else if (json.constructor === Array) {
        this.nfIncomeList = json.map(object => new NFIncomeSummaryDetails(object));
      }

      // array - NFPregnancyInfo
      if (json.nfPregnancyList) {
        this.nfPregnancyList = json.nfPregnancyList.map(object => new NFPregnancyInfo(object));
      } else if (json.constructor === Array) {
        this.nfPregnancyList = json.map(object => new NFPregnancyInfo(object));
      }
    }
  }
}

/**
 * Models the NFCitizenPortalEvidenceResponse
 *
 * @class NFCitizenPortalEvidenceResponse
 */
class NFCitizenPortalEvidenceResponse {
  /**
   * Creates an instance of NFCitizenPortalEvidenceResponse.
   * @param {*} json
   * @memberof NFCitizenPortalEvidenceResponse
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.isSuccess = json.isSuccess;

      // boolean
      this.isClosedPDC = json.isClosedPDC;

      // object - NFCitizenAccountSummaryDetails
      if (json.accountSummaryDtls) {
        this.accountSummaryDtls = new NFCitizenAccountSummaryDetails(json.accountSummaryDtls);
      }
    }
  }
}

/**
 * Models the NFEnableABD
 *
 * @class NFEnableABD
 */
class NFEnableABD {
  /**
   * Creates an instance of NFEnableABD.
   * @param {*} json
   * @memberof NFEnableABD
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfEnableABD = json.nfEnableABD;
    }
  }
}

/**
 * Models the NFEnableUnifiedMAGI
 *
 * @class NFEnableUnifiedMAGI
 */
class NFEnableUnifiedMAGI {
  /**
   * Creates an instance of NFEnableUnifiedMAGI.
   * @param {*} json
   * @memberof NFEnableUnifiedMAGI
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfEnableUnifiedMagi = json.nfEnableUnifiedMagi;
    }
  }
}

/**
 * Models the NewNFStartMotivationKey
 *
 * @class NewNFStartMotivationKey
 */
class NewNFStartMotivationKey {
  /**
   * Creates an instance of NewNFStartMotivationKey.
   * @param {*} json
   * @memberof NewNFStartMotivationKey
   */
  constructor(json = null) {
    if (json) {
      // boolean
      this.nfUXIsApplyingAsRepSelect = json.nfUXIsApplyingAsRepSelect;

      // string
      this.motivationTypeID = json.motivationTypeID;

      // string
      this.srcAppMotivationID = json.srcAppMotivationID;

      // boolean
      this.nfUXIsApplyingAsFNSSelect = json.nfUXIsApplyingAsFNSSelect;
    }
  }
}

/**
 * Models the NewUAMotivationIEGKeyResponse
 *
 * @class NewUAMotivationIEGKeyResponse
 */
class NewUAMotivationIEGKeyResponse {
  /**
   * Creates an instance of NewUAMotivationIEGKeyResponse.
   * @param {*} json
   * @memberof NewUAMotivationIEGKeyResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.iegExecutionID = json.iegExecutionID;
    }
  }
}

/**
 * Models the NFCOCConfigResponse
 *
 * @class NFCOCConfigResponse
 */
class NFCOCConfigResponse {
  /**
   * Creates an instance of NFCOCConfigResponse.
   * @param {*} json
   * @memberof NFCOCConfigResponse
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfCOCConfigID = json.nfCOCConfigID;

      // string
      this.cocTypeCode = json.cocTypeCode;

      // string
      this.processID = json.processID;
    }
  }
}

export default {
  NFLifeEventTypeResponse,

  CodeTableType,

  CoCUnsupportScenDetails,

  CoCUnsupportScenDetailsList,

  NFChangeOfCircumstanceResponse,

  NewUALifeEventContext,

  NewUALifeEventFormIdResponse,

  NewUAMotivationIEGKey,

  NewNFMotivationFinishResponseResponse,

  NewCESignAndSubmit,

  NewNFCESignAndSubmit,

  NewCESignAndSubmitResponseResponse,

  NewErrorResponse,

  NewNFCESignAndSubmitResponseResponse,

  CEReadESignatureDetails,

  NFCEReadSignatureDetailsResponse,

  CESignAndSubmitResponse,

  Error,

  NFCESignAndSubmitResponse,

  SubmittedLifeEventDetails,

  NFSubmittedLifeEventsDetails,

  NFSubmittedLifeEventsDetailsList,

  NFCitizenLifeEventsResponse,

  HouseholdMemberSummaryDetails,

  IncomeSummaryDetails,

  LivingArrangementsSummaryDetails,

  TaxFilingSummaryDetails,

  CitizenAccountSummaryDetails,

  NFAddressDetails,

  NFCitizenCaseInfoDetails,

  NFHouseholdMemberSummaryDetails,

  NFIncomeSummaryDetails,

  NFPregnancyInfo,

  NFCitizenAccountSummaryDetails,

  NFCitizenPortalEvidenceResponse,

  NFEnableABD,

  NFEnableUnifiedMAGI,

  NewNFStartMotivationKey,

  NewUAMotivationIEGKeyResponse,

  NFCOCConfigResponse,
};
