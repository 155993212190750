/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import { FETCH_NF_EXTERNAL_URL_CONFIG } from './actionTypes';

import { fetchNfExternalURLConfigUtil } from './utils';

/**
 * Contains the action creator functions that update the NFOrganisation slice of the store.
 *
 * @namespace NFOrganisationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_external_URL_config
   *
   * @param {any} callback function to call once this function has finished
   * @function
   * @memberof NFOrganisationActions
   */
  static fetchNfExternalURLConfig = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      fetchNfExternalURLConfigUtil,
      FETCH_NF_EXTERNAL_URL_CONFIG,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to fetchNfExternalURLConfig.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFOrganisationActions
   */
  static resetFetchNfExternalURLConfig = (dispatch, data) => {
    const json = {
      type: FETCH_NF_EXTERNAL_URL_CONFIG.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to fetchNfExternalURLConfig.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFOrganisationActions
   */
  static resetFetchNfExternalURLConfigError = dispatch => {
    ReduxUtils.resetErrorAction(dispatch, FETCH_NF_EXTERNAL_URL_CONFIG.failureAction);
  };
}
