/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { RESTService, ReduxUtils } from '@spm/core';
import models from './models';

/**
 * Post request to path:
 * /v1/ua/motivation/setup-and-start
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postMotivationSetupAndStartUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/motivation/setup-and-start`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewUAMotivationIEGKeyResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Gets NFMotivationTypeResponse from api:
 * /v1/ua/nf_motivation/apply_for_standalone_ridp
 *
 * @param {any} callback function to call once this function has finished
 */
const fetchNfMotivationApplyForStandaloneRidpUtil = callback => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/apply_for_standalone_ridp`,
    models.NFMotivationTypeResponse
  );
};

/**
 * Gets NFMotivationTypeResponse from api:
 * /v1/ua/nf_motivation/motivation_type/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const getNfMotivationMotivationTypeByIegExecutionIDUtil = (iegExecutionID, callback) => {
  ReduxUtils.getModelDataFromRestAPI(
    callback,
    `v1/ua/nf_motivation/motivation_type/${iegExecutionID}`,
    models.NFMotivationTypeResponse
  );
};

/**
 * Post request to path:
 * /v1/ua/nf_motivation/finish_standalone_ridp
 *
 * @param {any} data
 * @param {any} callback function to call once this function has finished
 */
const postNfMotivationFinishStandaloneRidpUtil = (data = {}, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/finish_standalone_ridp`;

  RESTService.post(url, data, (success, response) => {
    if (success) {
      const model = new models.NewNFMotivationFinishResponseResponse(response);
      callback(success, model);
    } else {
      callback(success, response);
    }
  });
};

/**
 * Deletes from api:
 * /v1/ua/nf_motivation/{iegExecutionID}
 *
 * @param {string} iegExecutionID.
 * @param {any} callback function to call once this function has finished
 */
const deleteNfMotivationByIegExecutionIDUtil = (iegExecutionID, callback) => {
  const url = `${process.env.REACT_APP_REST_URL}/v1/ua/nf_motivation/${iegExecutionID}`;
  RESTService.del(url, (success, response) => {
    callback(success, response);
  });
};

export {
  postMotivationSetupAndStartUtil,
  fetchNfMotivationApplyForStandaloneRidpUtil,
  getNfMotivationMotivationTypeByIegExecutionIDUtil,
  postNfMotivationFinishStandaloneRidpUtil,
  deleteNfMotivationByIegExecutionIDUtil,
};
