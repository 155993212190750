/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

/**
 * Models the CodeTableType
 *
 * @class CodeTableType
 */
class CodeTableType {
  /**
   * Creates an instance of CodeTableType.
   * @param {*} json
   * @memberof CodeTableType
   */
  constructor(json = null) {
    if (json) {
      // string
      this.tableName = json.tableName;

      // string
      this.value = json.value;

      // string
      this.description = json.description;

      // string
      this.parentCodeTable = json.parentCodeTable;
    }
  }
}

/**
 * Models the NFExternalURLConfigDetails
 *
 * @class NFExternalURLConfigDetails
 */
class NFExternalURLConfigDetails {
  /**
   * Creates an instance of NFExternalURLConfigDetails.
   * @param {*} json
   * @memberof NFExternalURLConfigDetails
   */
  constructor(json = null) {
    if (json) {
      // string
      this.nfExternalURLConfigID = json.nfExternalURLConfigID;

      // string
      this.groupCode = json.groupCode;

      // string
      this.linkTextValue = json.linkTextValue;

      // string
      this.linkURL = json.linkURL;

      // string
      this.staticText = json.staticText;

      // object - CodeTableType
      if (json.recordStatus) {
        this.recordStatus = new CodeTableType(json.recordStatus);
      }

      // string
      this.localeIdentifier = json.localeIdentifier;
    }
  }
}

/**
 * Models the NFExternalURLConfigDetailsList
 *
 * @class NFExternalURLConfigDetailsList
 */
class NFExternalURLConfigDetailsList {
  /**
   * Creates an instance of NFExternalURLConfigDetailsList.
   * @param {*} json
   * @memberof NFExternalURLConfigDetailsList
   */
  constructor(json = null) {
    if (json) {
      // array - NFExternalURLConfigDetails
      if (json.dtls) {
        this.dtls = json.dtls.map(object => new NFExternalURLConfigDetails(object));
      } else if (json.constructor === Array) {
        this.dtls = json.map(object => new NFExternalURLConfigDetails(object));
      }
    }
  }
}

export default {
  CodeTableType,

  NFExternalURLConfigDetails,

  NFExternalURLConfigDetailsList,
};
