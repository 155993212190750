/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */
import { combineReducers } from 'redux';
import { ReduxUtils } from '@spm/core';
import {
  FETCH_NF_LIFEEVENT_APPLY_FOR_COC,
  FETCH_NF_LIFEEVENT_APPLY_FOR_AR,
  FETCH_NF_COC_UNSUPPORTED_MESSAGE,
  POST_LIFE_EVENTS_FORM,
  POST_NF_MOTIVATION_FINISH_COC,
  POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC,
  GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION,
  GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID,
  FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS,
  FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS,
  GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID,
  GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID,
  POST_NF_MOTIVATION_SETUP_START_MOTIVATION,
  FETCH_NF_COC_APPLY_FOR_SNAP_COC,
  FETCH_NF_COC_APPLY_FOR_FNS_COC,
} from './actionTypes';

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfLifeeventApplyForCocReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_LIFEEVENT_APPLY_FOR_COC);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfLifeeventApplyForCocReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_LIFEEVENT_APPLY_FOR_COC);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfLifeeventApplyForCocErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_LIFEEVENT_APPLY_FOR_COC);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfLifeeventApplyForArReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_LIFEEVENT_APPLY_FOR_AR);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfLifeeventApplyForArReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_LIFEEVENT_APPLY_FOR_AR);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfLifeeventApplyForArErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_LIFEEVENT_APPLY_FOR_AR);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocUnsupportedMessageReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_UNSUPPORTED_MESSAGE);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocUnsupportedMessageReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_UNSUPPORTED_MESSAGE);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocUnsupportedMessageErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_UNSUPPORTED_MESSAGE);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postLifeEventsFormReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_LIFE_EVENTS_FORM);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostLifeEventsFormReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_LIFE_EVENTS_FORM);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postLifeEventsFormErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_LIFE_EVENTS_FORM);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationFinishCocReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_FINISH_COC);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationFinishCocReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_FINISH_COC);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationFinishCocErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_FINISH_COC);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationSignAndSubmitCocReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationSignAndSubmitCocReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationSignAndSubmitCocErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_SIGN_AND_SUBMIT_COC);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationEsignatureByMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationEsignatureByMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationEsignatureByMotivationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, GET_NF_MOTIVATION_ESIGNATURE_BY_MOTIVATION);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfMotivationCocConfirmationDetailsByMotivationIdReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfMotivationCocConfirmationDetailsByMotivationIdReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfMotivationCocConfirmationDetailsByMotivationIdErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_MOTIVATION_COC_CONFIRMATION_DETAILS_BY_MOTIVATION_ID
  );

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfLifeEventsListSubmittedLifeeventsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfLifeEventsListSubmittedLifeeventsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfLifeEventsListSubmittedLifeeventsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_LIFE_EVENTS_LIST_SUBMITTED_LIFEEVENTS);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS
  );

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    FETCH_NF_CITIZEN_PORTAL_EVIDENCE_READ_MY_INFO_TAB_DETAILS
  );

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_ABD_BY_NF_MOTIVATION_ID
  );

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateReducer(
    state,
    action,
    GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID
  );

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateFetchingReducer(
    state,
    action,
    GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID
  );

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDErrorReducer = (
  state = {},
  action
) =>
  ReduxUtils.generateErrorReducer(
    state,
    action,
    GET_NF_CITIZEN_PORTAL_EVIDENCE_GET_ENABLE_UNIFIED_MAGI_BY_NF_MOTIVATION_ID
  );

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const postNfMotivationSetupStartMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, POST_NF_MOTIVATION_SETUP_START_MOTIVATION);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingPostNfMotivationSetupStartMotivationReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, POST_NF_MOTIVATION_SETUP_START_MOTIVATION);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const postNfMotivationSetupStartMotivationErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, POST_NF_MOTIVATION_SETUP_START_MOTIVATION);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocApplyForSnapCocReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_APPLY_FOR_SNAP_COC);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocApplyForSnapCocReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_APPLY_FOR_SNAP_COC);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocApplyForSnapCocErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_APPLY_FOR_SNAP_COC);

/**
 * Updates the details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const fetchNfCocApplyForFnsCocReducer = (state = {}, action) =>
  ReduxUtils.generateReducer(state, action, FETCH_NF_COC_APPLY_FOR_FNS_COC);

/**
 * Updates the indicator of creation of details in the 'NFCocMotivation' store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 * @returns the new state
 */
const isFetchingFetchNfCocApplyForFnsCocReducer = (state = {}, action) =>
  ReduxUtils.generateFetchingReducer(state, action, FETCH_NF_COC_APPLY_FOR_FNS_COC);

/**
 * Updates the post data errors in the store object.
 *
 * @param {any} [state={}] the initial state for the reducer
 * @param {any} action the action creator
 */
const fetchNfCocApplyForFnsCocErrorReducer = (state = {}, action) =>
  ReduxUtils.generateErrorReducer(state, action, FETCH_NF_COC_APPLY_FOR_FNS_COC);

const reducers = combineReducers({
  fetchNfLifeeventApplyForCoc: fetchNfLifeeventApplyForCocReducer,

  isFetchingFetchNfLifeeventApplyForCoc: isFetchingFetchNfLifeeventApplyForCocReducer,
  fetchNfLifeeventApplyForCocError: fetchNfLifeeventApplyForCocErrorReducer,

  fetchNfLifeeventApplyForAr: fetchNfLifeeventApplyForArReducer,

  isFetchingFetchNfLifeeventApplyForAr: isFetchingFetchNfLifeeventApplyForArReducer,
  fetchNfLifeeventApplyForArError: fetchNfLifeeventApplyForArErrorReducer,

  fetchNfCocUnsupportedMessage: fetchNfCocUnsupportedMessageReducer,

  isFetchingFetchNfCocUnsupportedMessage: isFetchingFetchNfCocUnsupportedMessageReducer,
  fetchNfCocUnsupportedMessageError: fetchNfCocUnsupportedMessageErrorReducer,

  postLifeEventsForm: postLifeEventsFormReducer,

  isFetchingPostLifeEventsForm: isFetchingPostLifeEventsFormReducer,
  postLifeEventsFormError: postLifeEventsFormErrorReducer,

  postNfMotivationFinishCoc: postNfMotivationFinishCocReducer,

  isFetchingPostNfMotivationFinishCoc: isFetchingPostNfMotivationFinishCocReducer,
  postNfMotivationFinishCocError: postNfMotivationFinishCocErrorReducer,

  postNfMotivationSignAndSubmitCoc: postNfMotivationSignAndSubmitCocReducer,

  isFetchingPostNfMotivationSignAndSubmitCoc: isFetchingPostNfMotivationSignAndSubmitCocReducer,
  postNfMotivationSignAndSubmitCocError: postNfMotivationSignAndSubmitCocErrorReducer,

  getNfMotivationEsignatureByMotivation: getNfMotivationEsignatureByMotivationReducer,

  isFetchingGetNfMotivationEsignatureByMotivation: isFetchingGetNfMotivationEsignatureByMotivationReducer,
  getNfMotivationEsignatureByMotivationError: getNfMotivationEsignatureByMotivationErrorReducer,

  getNfMotivationCocConfirmationDetailsByMotivationId: getNfMotivationCocConfirmationDetailsByMotivationIdReducer,

  isFetchingGetNfMotivationCocConfirmationDetailsByMotivationId: isFetchingGetNfMotivationCocConfirmationDetailsByMotivationIdReducer,
  getNfMotivationCocConfirmationDetailsByMotivationIdError: getNfMotivationCocConfirmationDetailsByMotivationIdErrorReducer,

  fetchNfLifeEventsListSubmittedLifeevents: fetchNfLifeEventsListSubmittedLifeeventsReducer,

  isFetchingFetchNfLifeEventsListSubmittedLifeevents: isFetchingFetchNfLifeEventsListSubmittedLifeeventsReducer,
  fetchNfLifeEventsListSubmittedLifeeventsError: fetchNfLifeEventsListSubmittedLifeeventsErrorReducer,

  fetchNfCitizenPortalEvidenceReadMyInfoTabDetails: fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer,

  isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetails: isFetchingFetchNfCitizenPortalEvidenceReadMyInfoTabDetailsReducer,
  fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsError: fetchNfCitizenPortalEvidenceReadMyInfoTabDetailsErrorReducer,

  getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID: getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDReducer,

  isFetchingGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationID: isFetchingGetNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDReducer,
  getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDError: getNfCitizenPortalEvidenceGetEnableAbdByNfMotivationIDErrorReducer,

  getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID: getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDReducer,

  isFetchingGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationID: isFetchingGetNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDReducer,
  getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDError: getNfCitizenPortalEvidenceGetEnableUnifiedMagiByNfMotivationIDErrorReducer,

  postNfMotivationSetupStartMotivation: postNfMotivationSetupStartMotivationReducer,

  isFetchingPostNfMotivationSetupStartMotivation: isFetchingPostNfMotivationSetupStartMotivationReducer,
  postNfMotivationSetupStartMotivationError: postNfMotivationSetupStartMotivationErrorReducer,

  fetchNfCocApplyForSnapCoc: fetchNfCocApplyForSnapCocReducer,

  isFetchingFetchNfCocApplyForSnapCoc: isFetchingFetchNfCocApplyForSnapCocReducer,
  fetchNfCocApplyForSnapCocError: fetchNfCocApplyForSnapCocErrorReducer,

  fetchNfCocApplyForFnsCoc: fetchNfCocApplyForFnsCocReducer,

  isFetchingFetchNfCocApplyForFnsCoc: isFetchingFetchNfCocApplyForFnsCocReducer,
  fetchNfCocApplyForFnsCocError: fetchNfCocApplyForFnsCocErrorReducer,
});

export default { reducers };
