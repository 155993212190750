/*
 * Licensed Materials - Property of IBM
 *
 * PID 5725-H26
 *
 * Copyright IBM Corporation 2025. All Rights Reserved.
 *
 * US Government Users Restricted Rights - Use, duplication or disclosure
 * restricted by GSA ADP Schedule Contract with IBM Corp.
 */

import { ReduxUtils } from '@spm/core';

import {
  POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD,
  POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM,
  POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION,
  POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION,
} from './actionTypes';

import {
  postNfMassCommunicationViewPrefMethodUtil,
  postNfMassCommunicationCreateMassCommUtil,
  postNfMassCommunicationUpdateMassCommunicationUtil,
  postNfMassCommunicationCancelMassCommunicationUtil,
} from './utils';

/**
 * Contains the action creator functions that update the NFMassCommunication slice of the store.
 *
 * @namespace NFMassCommunicationActions
 */
export default class actions {
  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_mass_communication/view_pref_method
   *
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMassCommunicationActions
   */
  static postNfMassCommunicationViewPrefMethod = (dispatch, callback) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMassCommunicationViewPrefMethodUtil.bind(null, {}),
      POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMassCommunicationViewPrefMethod.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationViewPrefMethod = (dispatch, data) => {
    const json = {
      type: POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMassCommunicationViewPrefMethod.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationViewPrefMethodError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_MASS_COMMUNICATION_VIEW_PREF_METHOD.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_mass_communication/create_mass_comm
   *
   * @param {NewNFMassCommDetailsForePass} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMassCommunicationActions
   */
  static postNfMassCommunicationCreateMassComm = (
    dispatch,
    newNFMassCommDetailsForePass,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMassCommunicationCreateMassCommUtil.bind(null, newNFMassCommDetailsForePass),
      POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMassCommunicationCreateMassComm.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationCreateMassComm = (dispatch, data) => {
    const json = {
      type: POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMassCommunicationCreateMassComm.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationCreateMassCommError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_MASS_COMMUNICATION_CREATE_MASS_COMM.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_mass_communication/update_mass_communication
   *
   * @param {NewNFUserContactDetails} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMassCommunicationActions
   */
  static postNfMassCommunicationUpdateMassCommunication = (
    dispatch,
    newNFUserContactDetails,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMassCommunicationUpdateMassCommunicationUtil.bind(null, newNFUserContactDetails),
      POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMassCommunicationUpdateMassCommunication.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationUpdateMassCommunication = (dispatch, data) => {
    const json = {
      type: POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMassCommunicationUpdateMassCommunication.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationUpdateMassCommunicationError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_MASS_COMMUNICATION_UPDATE_MASS_COMMUNICATION.failureAction
    );
  };

  /**
   * Calls the api and dispatches the results to the store.
   * api: /v1/ua/nf_mass_communication/cancel_mass_communication
   *
   * @param {NewNFMassCommePassUserName} data object that will be posted to the api
   * @param {function} callback function to call once this function has finished
   * @function
   * @memberof NFMassCommunicationActions
   */
  static postNfMassCommunicationCancelMassCommunication = (
    dispatch,
    newNFMassCommePassUserName,
    callback
  ) => {
    ReduxUtils.generateAction(
      dispatch,
      postNfMassCommunicationCancelMassCommunicationUtil.bind(null, newNFMassCommePassUserName),
      POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION,
      callback
    );
  };

  /**
   * Dispatches the data to the store to reset the data cached from a call to postNfMassCommunicationCancelMassCommunication.
   *
   * @param {function} dispatch
   * @param {Object} data object to be set in the store.
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationCancelMassCommunication = (dispatch, data) => {
    const json = {
      type: POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION.successAction,
      payload: data,
    };
    dispatch(json);
  };

  /**
   * Dispatches the data to the store to reset the error cached from a call to postNfMassCommunicationCancelMassCommunication.
   *
   * @param {function} dispatch
   * @function
   * @memberof NFMassCommunicationActions
   */
  static resetPostNfMassCommunicationCancelMassCommunicationError = dispatch => {
    ReduxUtils.resetErrorAction(
      dispatch,
      POST_NF_MASS_COMMUNICATION_CANCEL_MASS_COMMUNICATION.failureAction
    );
  };
}
